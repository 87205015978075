import React, { useState, useEffect } from 'react';
import DrawerLayout from '../../components/layout/DrawerLayout';
import { Autocomplete, Button, IconButton, Stack, TextField } from '@mui/material';
import { apiCreateSchemeIncome, apiDeleteSchemeIncome, apiGetSchemeIncome, getDataDictionaryValues, getOpportunity } from '../../utils/api/api';
import { DD_TYPE_SCHEME_INCOME, SNACKBAR_ERROR, SNACKBAR_SUCCESS, SNACKBAR_WARNING } from "../../utils/constants/constants";
import { isSubAdmin, toLocalePrice } from '../../utils/StringUtil';
import { useSnackbar } from 'notistack';
import 'dayjs/locale/en-gb';
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AlertLayout from '../../components/layout/AlertLayout';


const SchemeIncome = ({ open, toggleDrawer }) => {
    const [schemeList, setSchemeList] = useState([]);
    const [oppRid, setOppRid] = useState(null);
    const [remark, setRemark] = useState('');
    const { enqueueSnackbar } = useSnackbar();
    const [editPatnerPayment, setEditPatnerPayment] = useState(null);
    const [openAlertDialog, setOpenAlertDialog] = useState(false);
    const [alertLoader, setAlertLoader] = useState(false);
    const [schemeIncomeAmount, setSchemeIncomeAmount] = useState(0.00);
    const [IncomeDdIndex, setIncomeDdIndex] = useState(null);
    const [IncomeDdIndexValue, SetIncomeDdIndexValue] = useState('');
    const [schemeIncomeLog, setSchemeIncomeLog] = useState([]);
    const [editSchemeIncome, setEditSchemeIncome] = useState(null);

    const [schemeIncomeList, setSchemeIncomeList] = useState([]);


    const getSchemeList = (param = {}) => {
        param.pageNumber = 0;
        getOpportunity(param)
            .then((data) => {
                setSchemeList(data.opportunityList);
            })
            .catch((error) => {
                enqueueSnackbar(error, SNACKBAR_ERROR)
            });
    }

    const toggleAlertDialog = () => {
        if (openAlertDialog) {
            setOpenAlertDialog(false);
        } else {
            setOpenAlertDialog(true);
        }
    }

    const handleAlertDialog = () => {
        setAlertLoader(true);
        apiDeleteSchemeIncome({
            "siRid": editSchemeIncome?.siRid
        })
            .then((data) => {
                setAlertLoader(false);
                enqueueSnackbar(data, SNACKBAR_SUCCESS);
                toggleAlertDialog();
                getSchemeIncome();
            })
            .catch((error) => {
                setAlertLoader(false);
                enqueueSnackbar(error, SNACKBAR_ERROR);
            });
    }

    const getSchemeIncome = (param = {}) => {
        param.oppRid = oppRid;
        apiGetSchemeIncome(param)
            .then((data) => {
                setSchemeIncomeLog(data);
            })
            .catch((error) => {
                enqueueSnackbar(error, SNACKBAR_ERROR);
            });
    };
    

    useEffect(() => {
        if (oppRid !== null) {
            getSchemeIncome();
        }
    }, [oppRid]);



    const handleSaveSchemeIncome = () => {
        if (!oppRid) {
            enqueueSnackbar("Please select Scheme", SNACKBAR_WARNING);
        }
        else if (parseFloat(schemeIncomeAmount) <= 0) {

            enqueueSnackbar("Invalid amount", SNACKBAR_WARNING);
        } else {
            apiCreateSchemeIncome({
                "oppRid": oppRid,
                "incomeDdIndex": IncomeDdIndex,
                "incomeDdIndexValue": IncomeDdIndexValue,
                "amount": schemeIncomeAmount,
                "remarks": remark
            }).then((data) => {
                enqueueSnackbar(data, SNACKBAR_SUCCESS);
                getSchemeList();
                getSchemeIncome();

                setIncomeDdIndex(null);
                SetIncomeDdIndexValue('');
                setSchemeIncomeAmount(0.00);
                setRemark('');
                getDataDictionary();

            }).catch((error) => {
                enqueueSnackbar(error, SNACKBAR_ERROR)
            });
        }
    }
    const handleAutoCompleteFS = (value, type) => {
        
        // value = value.trim();

        if (type === DD_TYPE_SCHEME_INCOME) {
            const ddData = schemeIncomeList.find((item) =>
                item.ddValue.trim() === value
            );
            if (ddData) {
                SetIncomeDdIndexValue(null);
                setIncomeDdIndex(ddData.ddIndex);
            } else {
                SetIncomeDdIndexValue(value);
                setIncomeDdIndex(null);
            }
            SetIncomeDdIndexValue(value);
        }
    }

    useEffect(() => {
        if (open) {
            setOppRid(null);
            setIncomeDdIndex(null);
            SetIncomeDdIndexValue('');
            setSchemeIncomeAmount(0.00);
            setRemark(null);
            getDataDictionary();
            getSchemeList();
        }

    }, [open])

    const getDataDictionary = () => {
        getDataDictionaryValues()
            .then((data) => {

                const SchemeIncomeType = data.filter((item) =>
                    item.ddType === DD_TYPE_SCHEME_INCOME
                )

                setSchemeIncomeList(SchemeIncomeType);
            })
            .catch((e) => {
                console.log(e);
            })
    }

    const handlePaymentDelete = (item) => {
        setEditSchemeIncome(item);
        toggleAlertDialog();
    }



    return (
        <DrawerLayout
            open={open}
            title="Scheme Income"
            closeAction={toggleDrawer}
            drawerWidth={"50%"}
            positiveAction={
                { label: 'Close', onClick: toggleDrawer }
            }
        >
            <AlertLayout
                open={openAlertDialog}
                title="Are You Sure ?"
                actionLoader={alertLoader}
                closeAction={toggleAlertDialog}
                positiveAction={
                    { label: 'Delete', onClick: handleAlertDialog }
                }>
                <p className="fs-large-medium p-0 m-0">This action cannot be undone. This will permanently delete.</p>
            </AlertLayout>
            {!isSubAdmin() && (
                <Stack sx={{ marginBottom: "2rem" }} direction="column" spacing={2} >
                    <Autocomplete
                        sx={{ flex: 1 }}
                        id="auto-complete"
                        name="oppRid"
                        variant="outlined"
                        options={schemeList}
                        getOptionLabel={(option) => option.oppTitle}
                        value={schemeList.find((option) => option.oppRid === oppRid) || null}
                        fullWidth
                        onChange={(e, v) => setOppRid(v ? v.oppRid : null)}
                        renderInput={(params) => <TextField {...params} label="Select Scheme" />}
                        renderOption={(props, option) => (
                            <li {...props} key={option.oppRid}>{option.oppTitle}</li>
                        )}
                        size="small"
                    />
                    <div className="card flex-grow-1 p-3">

                        <Stack sx={{ flex: 1 }} direction="column" spacing={2}>

                            <Stack sx={{ marginBottom: "2rem" }} direction="row" spacing={2}>

                                <Autocomplete
                                    freeSolo
                                    sx={{ flex: 1 }}
                                    id="clear-on-blur"
                                    
                                    name="schemeExpensesList"
                                    variant="outlined"
                                    options={schemeIncomeList}
                                    getOptionLabel={(option) => option.ddValue}
                                    value={schemeIncomeList.find((option) => option.ddIndex === IncomeDdIndex) || null}
                                    inputValue={IncomeDdIndexValue}
                                    onChange={(e, v) => {
                                        if (v) {
                                            setIncomeDdIndex(v.ddIndex);
                                        } else {
                                            setIncomeDdIndex(null);
                                        }
                                    }}
                                    fullWidth
                                    // onChange={(e,v)=>SetExpensesDdIndexValue(v?v.expensesDdIndexValue:' ')}
                                    onInputChange={(e, v) => {
                                        handleAutoCompleteFS(v, DD_TYPE_SCHEME_INCOME);
                                    }}
                                    renderInput={(params) => <TextField {...params} label="Income Type"/>}
                                    size="small"
                                />
                                <TextField
                                    sx={{ flex: 1 }}
                                    id="outlined-basic"
                                    name="schemexpesnsesAmount"
                                    type="number"
                                    label="Amount"
                                    variant="outlined"
                                    value={schemeIncomeAmount ?? ""}
                                    onChange={(e) => setSchemeIncomeAmount(e.target.value)}

                                    size="small"
                                />
                            </Stack>
                            <Stack sx={{ flex: 1 }} direction="row" spacing={2}>
                                <Stack sx={{ flex: 1 }} direction="column" spacing={2}>

                                    <TextField
                                        multiline
                                        sx={{ flex: 1 }}
                                        id="outlined-basic"
                                        name="remark"

                                        label="Remark"
                                        variant="outlined"
                                        value={remark}
                                        onChange={(e) => setRemark(e.target.value)}
                                        rows={2}
                                        size="small"
                                    />

                                    <div className="d-flex align-items-end">
                                        <Button variant="contained" onClick={handleSaveSchemeIncome} disableElevation>Save</Button>
                                    </div>

                                </Stack>

                            </Stack>
                        </Stack>
                    </div>

                </Stack>
            )}
            <Stack sx={{ marginBottom: "2rem" }} direction="column" spacing={2} className={oppRid === null ? 'd-none' : ''}>
                <p className="section-label p-0 m-0">Logs</p>
                <table className="table table-striped table-borderless w-100">
                    <thead>
                        <tr className="fs-normal-semibold text-center">
                            <th scope="col">
                                <p className="mb-2 ">Scheme Name</p>
                            </th>
                            <th scope="col">
                                <p className="mb-2">Income Type</p>
                            </th>
                            <th scope="col">
                                <p className="mb-2 text-end">Amount</p>
                            </th>
                            <th scope="col">
                                <p className="mb-2">Remarks</p>
                            </th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {schemeIncomeLog.length > 0 ? (
                            <>
                                {schemeIncomeLog.map((data, key) => {
                                    return (
                                        <tr className="fs-normal-medium text-center" key={key}>
                                            <td className="pt-3">{data.oppTitle}</td>
                                            <td className="pt-3 ">{data.incomeDdIndexValue}</td>
                                            <td className="pt-3 text-end">{toLocalePrice(data.amount)}</td>
                                            <td className="pt-3">{data.remarks}</td>
                                            {!isSubAdmin() && (
                                                <td className="p-0">
                                                    <IconButton color="error" onClick={() => handlePaymentDelete(data)}  >
                                                        <DeleteOutlineIcon />
                                                    </IconButton>
                                                </td>
                                            )}

                                        </tr>
                                    );
                                })}
                            </>

                        ) : (
                            <p className="fs-med-regular p-0 m-0">No Records</p>
                        )}

                    </tbody>
                </table>
            </Stack>
        </DrawerLayout>
    );
}

export default SchemeIncome;