import React, { useEffect,useState } from "react";
import DrawerLayout from '../../components/layout/DrawerLayout';
import { Autocomplete, Box, Button, Checkbox, Chip, CircularProgress, Divider, FormControlLabel, Stack, TextField, Tooltip } from "@mui/material";
import { apiGetSchemeLaunch, apiSaveFunds, apiSchemeLaunch, getDataDictionaryValues, getInvestors, getPartners } from "../../utils/api/api";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { DD_TYPE_SOURCE_TYPE, SNACKBAR_ERROR, SNACKBAR_SUCCESS, SNACKBAR_WARNING } from "../../utils/constants/constants";
import { useSnackbar } from 'notistack';
import { formatDateTime } from "../../utils/StringUtil";


const EmailSchemeLaunch = ({ open, toggleDrawer, scheme }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [title, setTitle] = useState('');
    const [isTableLoading, setIsTableLoading] = useState(false);
    const [selectedpartnerList, setSelectedpartnerList] = useState([]);
    const [investorList, setInvestorList] = useState([]);
    const [selectedInvestorList, setSelectedInvestorList] = useState([]);
    const [addInvestorList, setAddInvestorList] = useState([]);
    const [selectedAddInvestorList, setSelectedAddInvestorList] = useState([]);
    const [sendAll, setSendAll] = useState(false);
    const [logList, setLogList] = useState([]);
    const { enqueueSnackbar } = useSnackbar();
    const [partnerRid, setPartnerRid] = useState(null);
    const [partnerList, setPartnerList] = useState([]);

    const [externalEmails, setExternalEmails] = useState('');

    // const getDataDictionary = () => {
    //     getDataDictionaryValues()
    //       .then((data) => {
    //         const sourceTypes = data.filter((item) =>
    //           item.ddType === DD_TYPE_SOURCE_TYPE
    //         )
    //         setpartnerList(sourceTypes);
    //       })
    //       .catch((e) => {
    //         console.log(e);
    //       })
    // }

    const getInvestorList = (param = {}) => {
        param.partnerRid = selectedpartnerList.map(item => item.partnerRid).join(',');
        getInvestors(param)
        .then((data) => {
            setInvestorList(data.investorList);
            setSelectedInvestorList(data.investorList);
        })
        .catch((error) => {
            console.log(error);
        });
    }
    const getAdditionalInvestorList = (param = {}) => {
        getInvestors(param)
        .then((data) => {
            setAddInvestorList(data.investorList);
        })
        .catch((error) => {
            console.log(error);
        });
    }

    useEffect(() => {
        if(selectedpartnerList.length > 0){
            getInvestorList();
        } else {
            setInvestorList([]);
            setSelectedInvestorList([]);
        }
    }, [selectedpartnerList]);

    useEffect(() => {
        if(open){
            getPartnersList();
            getAdditionalInvestorList();
            getLogs();
            setTitle('');
            setSendAll(false);
            setSelectedpartnerList([]);
            setSelectedInvestorList([]);
            setSelectedAddInvestorList([]);
            setExternalEmails('');
        }
    }, [open]);

    const getLogs = (param = {}) => {
        setIsTableLoading(true);
        param.oppRid = scheme?.oppRid;
        apiGetSchemeLaunch(param)
            .then((data) => {
                setIsTableLoading(false);
              setLogList(data);
            })
            .catch((error) => {
                setIsTableLoading(false);
              enqueueSnackbar(error,SNACKBAR_ERROR);
            });
    }

    const handleLaunchEmail = () => {
        const EMAIL_REGX = /^\S+@\S+\.\S+$/;
        const reqBody = {
            "subject": title,
            "invSource": sendAll ? [] : selectedpartnerList.map((item) => ({
                fullName: item.fullName,
                partnerRid: item.partnerRid,
            })),
            "invEmail": sendAll
                ?   [
                        ...(externalEmails !== ''
                            ? externalEmails.split(',').map((emailID) => ({
                                fullName: 'Investor',
                                email: emailID.trim(),
                                partnerRid: 0,
                                }))
                            : []),
                    ]
                : [
                    ...selectedInvestorList.map((item) => ({
                        fullName: item.fullName,
                        email: item.email,
                        partnerRid: item.partnerRid ? item.partnerRid : 0,
                    })),
                    ...selectedAddInvestorList.map((item) => ({
                        fullName: item.fullName,
                        email: item.email,
                        partnerRid: item.partnerRid ? item.partnerRid : 0,
                    })),
                    ...(externalEmails !== ''
                        ? externalEmails.split(',').map((emailID) => ({
                            fullName: 'Investor',
                            email: emailID.trim(),
                            partnerRid: 0,
                            }))
                        : []),
                ],
            "oppRid": scheme?.oppRid,
            "sendAll": sendAll ? 1 : 0
        }

        if(title.trim() === ''){
            enqueueSnackbar('Email Title Required',SNACKBAR_WARNING);
        } else if(!sendAll && (selectedInvestorList.length === 0 && selectedAddInvestorList.length === 0 && externalEmails === '')){
            enqueueSnackbar('Please Select Investor',SNACKBAR_WARNING);
        } else if(externalEmails !== '' && (!externalEmails.split(',').every(email => EMAIL_REGX.test(email.trim())))){
            enqueueSnackbar('Invalid EMail found in External Emails',SNACKBAR_WARNING);
        } else {
            setIsLoading(true);
            apiSchemeLaunch(reqBody)
            .then((data) => {
                setIsLoading(false);
                getLogs();
                setTitle('');
                setSendAll(false);
                setSelectedpartnerList([]);
                setSelectedInvestorList([]);
                setSelectedAddInvestorList([]);
                setExternalEmails('');
                enqueueSnackbar(data,SNACKBAR_SUCCESS);
            })
            .catch((error) => {
                setIsLoading(false);
                enqueueSnackbar(error,SNACKBAR_ERROR);
            });
        }
    }

    const getPartnersList = () => {
        setPartnerList([]);
        getPartners()
        .then((res) => {
            setPartnerList(res.partnerMasterList);
        })
        .catch((e) => {
            enqueueSnackbar(e, SNACKBAR_ERROR);
        });
    }

  return (
    <DrawerLayout
      open={open}
      title="Email Scheme Launch"
      subtitle={scheme?.oppTitle}
      drawerWidth="60%"
      closeAction={toggleDrawer}
      positiveAction={
        {label: 'Close', onClick : toggleDrawer}
      }
    >
        <Stack sx={{ marginBottom: "1rem" }} direction="row" spacing={2}>
            <TextField
              sx={{ flex: 1 }}
              id="outlined-basic"
              name="title"
              label="Email Title"
              variant="outlined"
              value={title}
              fullWidth
              onChange={(e) => setTitle(e.target.value)}
              size="small"
            />
        </Stack>
        <Stack sx={{ marginBottom: "1rem" }} direction="row" spacing={2}>
            <FormControlLabel control={<Checkbox size="small" checked={sendAll} onChange={(e)=>setSendAll(e.target.checked)} />} label="Send All" />
        </Stack>
        <Stack sx={{ marginBottom: "1rem" }} direction="column" spacing={2}>
            <Autocomplete
                multiple
                sx={{ flex: 1 }}
                id="outlined-basic"
                variant="standard"
                options={partnerList}
                getOptionLabel={(option) => option.fullName}
                value={selectedpartnerList}
                onChange={(e, v) => {
                    setSelectedpartnerList(v);
                }}
                renderInput={(params) => <TextField {...params} variant="standard" label="Partner" />}
                disabled={sendAll}
                size="small"
            />
            
            {/* <Autocomplete
                multiple
                sx={{ flex: 1 }}
                id="outlined-basic"
                variant="standard"
                options={investorList}
                getOptionLabel={(option) => option.fullName}
                value={selectedInvestorList}
                onChange={(e, v) => {
                    setSelectedInvestorList(v);
                }}
                renderInput={(params) => <TextField {...params} variant="standard" label="Investor Email" />}
                renderOption={(props, option) => (
                    <div className="d-flex align-items-center" {...props} key={option.invRid}>
                      <AccountCircleIcon color="primary" fontSize="large" />
                      <div className="flex-grow-1 ms-2">
                        <p className="fs-med-medium p-0 m-0">{option.fullName}</p>
                        <p className="fs-small-regular text-muted p-0 m-0">{option.email}</p>
                      </div>
                    </div>
                    // <li {...props} key={option.invRid}>{option.fullName}</li>
                )}
                disabled={sendAll}
                size="small"
            /> */}
            <Autocomplete
                multiple
                sx={{ flex: 1 }}
                id="outlined-basic"
                variant="standard"
                options={addInvestorList}
                getOptionLabel={(option) => option.fullName}
                value={selectedAddInvestorList}
                onChange={(e, v) => {
                    setSelectedAddInvestorList(v);
                }}
                renderInput={(params) => <TextField {...params} variant="standard" label="Additional Investor Email" />}
                renderOption={(props, option) => (
                    <div className="d-flex align-items-center" {...props} key={option.invRid}>
                      <AccountCircleIcon color="primary" fontSize="large" />
                      <div className="flex-grow-1 ms-2">
                        <p className="fs-med-medium p-0 m-0">{option.fullName}</p>
                        <p className="fs-small-regular text-muted p-0 m-0">{option.email}</p>
                      </div>
                    </div>
                )}
                disabled={sendAll}
                size="small"
            />
        </Stack>

        <Divider sx={{ marginBottom: "1rem" }} />
        <Stack sx={{ marginBottom: "2rem" }} direction="column">
            <p className="fs-med-medium mb-3">External/ Other Emails</p>

            <Stack sx={{ marginBottom: "0rem" }} direction="row" spacing={2}>
                <TextField
                    multiline
                    sx={{ flex: 1 }}
                    id="outlined-basic"
                    name="emailID"
                    placeholder="Enter emails separated by commas (e.g., john@example.com, jane@example.com)"
                    variant="outlined"
                    value={externalEmails}
                    rows={3}
                    fullWidth
                    onChange={(e) => setExternalEmails(e.target.value)}
                    size="small"
                />
            </Stack>
        </Stack>
        <Stack sx={{ marginBottom: "1rem" }} direction="row" spacing={2}>
            <Button variant="contained" onClick={handleLaunchEmail} disabled={isLoading} disableElevation>Send Email</Button>
        </Stack>
        <Divider sx={{ marginBottom: "1rem" }} />
        <Stack sx={{ marginBottom: "2rem" }} direction="column">
            <p className="section-label mb-2">Logs</p>

            {isTableLoading ? <CircularProgress /> : 
            <Stack direction="column" spacing={2}>
            <table className="table table-striped table-borderless w-100 p-0 m-0">
                <thead>
                <tr className="fs-normal-semibold text-center">
                    <th scope="col">
                        <p className="mb-2">Date</p>
                    </th>
                    <th scope="col">
                        <p className="mb-2">Title/ Subject</p>
                    </th>
                    <th scope="col">
                        <p className="mb-2 text-end">Status</p>
                    </th>
                </tr>
                </thead>
                <tbody>
                { logList.map((data, key) => {
                    return(
                    <tr className="fs-normal-medium text-center" key={key}>
                        <td className="pt-3">{formatDateTime(data.createdAt, false)}</td>
                        <td className="pt-3">{data.subject}</td>
                        <td className="pt-3 text-end">
                            {data.status ? <span className="fs-normal-med text-success">Sent</span> : <span className="fs-normal-med text-warning">Pending</span>}
                        </td>
                    </tr>
                    );
                })}
                </tbody>
            </table>
            </Stack> }
        </Stack>
    </DrawerLayout>
  );
}

export default EmailSchemeLaunch;