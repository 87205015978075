import { Box, Button, Divider, Drawer, IconButton } from '@mui/material';
import React from 'react';
import { makeStyles } from '@mui/styles';
import CancelIcon from '@mui/icons-material/Cancel';

export const ScoreBoard = ({label, value, flex}) => {
  return(
    <Box sx={{flex: flex ?? 1}}>
      <p className="section-label mb-1" style={{fontSize: '12px'}}>{label}</p>
      <p className="fs-med-medium p-0 m-0">{value}</p>
    </Box>
  );
}

const drawerStyles = makeStyles((theme)=> ({
    drawer: {
      width: '70%',
      flexShrink: 0,
      '& .MuiDrawer-paper': {
        width: '70%',
        boxSizing: 'border-box',
        // paddingTop: '60px',
        // paddingBottom: '60px',
      },
    },
    header: {
      position: 'sticky',
      top: 0,
      backgroundColor: theme.palette.background.default,
      zIndex: theme.zIndex.drawer + 1,
    },
    footer: {
      position: 'sticky',
      bottom: 0,
      backgroundColor: theme.palette.background.default,
      zIndex: theme.zIndex.drawer + 1,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
}));
const userType = process.env.REACT_APP_USER_TYPE;

const DrawerLayout = ({open, disableEnforceFocus = false, drawerWidth, title, subtitle, actionLoader, closeAction, negativeAction, positiveAction, children}) => {
    const drawerClasses = drawerStyles();

    const handleNegativeAction = () => {
      negativeAction.onClick();
    }

    const handlePositiveAction = () => {
      positiveAction.onClick();
    }

  return (
    <Drawer
        anchor="right"
        open={open}
        disableEnforceFocus={disableEnforceFocus}
        sx={{
          width: drawerWidth ?? '70%',
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth ?? '70%',
            boxSizing: 'border-box',
          },
        }}>
        <div className={drawerClasses.header}>
            <div className="d-flex justfy-content-between align-items-center py-3 px-4">
                <div className="flex-grow-1">
                  <h3 className="text-primary fs-x-large-semibold p-0 m-0">
                    {title}
                  </h3>
                  {subtitle ? <p className="text-muted fs-normal-medium p-0 m-0">{subtitle}</p> : ''}
                </div>
                <IconButton onClick={() => closeAction()}>
                    <CancelIcon fontSize="medium" color="secondary" />
                </IconButton>
            </div>
            <Divider />
        </div>
        
        <div className={drawerClasses.content}>{children}</div>
        
        <div className={drawerClasses.footer}>
            <Divider />
            <div className="d-flex gap-3 justify-content-end py-3 px-4">
              {negativeAction ?
              <Button variant="text" color="secondary" onClick={handleNegativeAction}>
                {negativeAction.label}
              </Button> : '' }
              { userType === 'ADMIN' &&
              (positiveAction ?
              <Button sx={{minWidth: 120}} variant="contained" color="secondary" onClick={handlePositiveAction} disabled={actionLoader}>
                {positiveAction.label}
              </Button> : '')}
            </div>
        </div>
    </Drawer>
  );
}

export default DrawerLayout;