import React, {useState,useEffect} from 'react';
import DrawerLayout, { ScoreBoard } from '../../components/layout/DrawerLayout';
import { Autocomplete, Stack, Switch, TextField } from '@mui/material';
import { apiInvestorOpportunity, apiSaveFunds, apiWallet, getInvestors, getTransactions } from '../../utils/api/api';
import { formatDateTime, toLocalePrice } from '../../utils/StringUtil';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import 'dayjs/locale/en-gb';
import { DatePicker } from "@mui/x-date-pickers";
import { SNACKBAR_ERROR, SNACKBAR_SUCCESS, SNACKBAR_WARNING } from "../../utils/constants/constants";
import { useSnackbar } from 'notistack';

const FundWithdraw = ({ open, toggleDrawer, investor, handleSave }) => {
  const [investorList, setInvestorList] = useState([]);
  const [invRid, setInvRid] = useState(null);
  const [investorFullName, setInvestorFullName] = useState('');
  const [invCode, setInvCode] = useState(null);
  const [walletAmount, setWalletAmount] = useState(0.00);
  const [balance, setBalance] = useState(0.00);
  const [amountReceived, setAmountReceived] = useState(0.00);
  const [receivedDate, setReceivedDate] = useState(
    new Date().toISOString().split('T')[0]
  );
  const [transactionList, setTransactionList] = useState([]);
  const [remark, setRemark] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const [actionLoader, setActionLoader] = useState(false);

  const handleReceivedDate = (selectedDate) => {
    const date = new Date(selectedDate?.$d);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const formattedDate = `${year}-${month}-${day}`;
    // const formattedDate = date.toISOString().split('T')[0];
    setReceivedDate(formattedDate);
  }

  const getInvestorList = (param = {}) => {
    param.mcaStatus = 1;
    getInvestors(param)
      .then((data) => {
        setInvestorList(data.investorList);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const getWallet = (param = {}) => {
    param.invRid = invRid;
    apiWallet(param)
      .then((data) => {
        setWalletAmount(data.balanceAmt);
        setBalance(data.balanceAmt);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const getTransactionList = (param = {}) => {
    param.invRid = invRid;
    getTransactions(param)
        .then((data) => {
            setTransactionList(data.transactions);
        })
        .catch((error) => {
            enqueueSnackbar(error,SNACKBAR_ERROR);
        });
  }

  useEffect(() => {
    const totalBalance = parseFloat(walletAmount) - parseFloat(amountReceived);
    setBalance(totalBalance);
  }, [amountReceived]);

  useEffect(() => {
    const investorDetails = investorList.find((option) => option.invRid === invRid) || null;
    if(investorDetails){
      setInvCode(investorDetails.invCode);
      setInvestorFullName(investorDetails.fullName);
      getWallet();
      getTransactionList();
    }
  }, [invRid]);

  useEffect(() => {
    getInvestorList();
  }, []);

  useEffect(() => {
    setInvRid(investor ? investor.invRid : null);
    setWalletAmount(walletAmount);
    // setInvCode(isEdit ? investor.invCode : 0.00);
    // setInvestorFullName(isEdit ? investor.fullName : '');
    setAmountReceived(0.00);
    setRemark('');
    setTransactionList([]);
  }, [open]);

  const saveFund = () => {
    const reqBody = {
      "invRid": invRid,
      "amt": -amountReceived,
      "tranDatetime": receivedDate,
      "remark": remark,
      "investorOpportunityList": [],
    }
    setActionLoader(true);
    apiSaveFunds(reqBody)
      .then((data) => {
        setActionLoader(false);
          enqueueSnackbar(data,SNACKBAR_SUCCESS);
          handleSave();
      })
      .catch((error) => {
        setActionLoader(false);
        enqueueSnackbar(error,SNACKBAR_ERROR);
      });
  }

  const onSubmit = () => {
    if(!invRid){
      enqueueSnackbar("Please select investor", SNACKBAR_WARNING);
    } else if(parseFloat(amountReceived) <= 0){
      enqueueSnackbar("Invalid amount", SNACKBAR_WARNING);
    } else if(parseFloat(balance) < 0){
      enqueueSnackbar("Wallet Amount Exceeded", SNACKBAR_WARNING);
    } else {
      saveFund();
    }
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
    <DrawerLayout
      open={open}
      title="Fund Withdraw"
      actionLoader={actionLoader}
      closeAction={toggleDrawer}
      negativeAction={
        {label: 'Cancel', onClick : toggleDrawer}
      }
      positiveAction={
        {label: 'Save', onClick : onSubmit}
      }
    >
      <Stack sx={{ marginBottom: "2rem" }} direction="row" spacing={2}>
        <ScoreBoard
          label="Investor"
          value={investorFullName}
          flex={1}
        />
        <Stack sx={{ flex: 1 }} direction="row" spacing={2}>
          <ScoreBoard
            label="Investor Code"
            value={invCode}
            flex={1}
          />
          <ScoreBoard
            label="Wallet Amount"
            value={toLocalePrice(walletAmount)}
            flex={1}
          />
          {/* <ScoreBoard
            label="Balance"
            value={toLocalePrice(balance)}
            flex={1}
          /> */}
        </Stack>
      </Stack>
      <Stack sx={{ marginBottom: "2rem" }} direction="row" spacing={2}>
        <Stack sx={{ flex: 1 }} direction="column" spacing={2}>
          <TextField
            sx={{ flex: 1 }}
            id="outlined-basic"
            name="amountReceived"
            type="number"
            label="Amount"
            variant="outlined"
            value={amountReceived}
            onChange={(e) => setAmountReceived(e.target.value)}
            fullWidth
          />
          <DatePicker
            sx={{ flex: 1 }}
            id="outlined-basic"
            name="receivedDate"
            label="Date"
            variant="outlined"
            value={receivedDate}
            fullWidth
            onChange={(v) => handleReceivedDate(v)}
            renderInput={(params) => <TextField {...params} />}
          />
        </Stack>
        <TextField
          sx={{ flex: 1 }}
          id="outlined-multiline-static"
          label="Remark"
          multiline
          rows={4}
          value={remark}
          onChange={(e)=>setRemark(e.target.value)}
          fullWidth
        />
      </Stack>
      <Stack sx={{ marginBottom: "2rem" }} direction="column" className={transactionList.length === 0 ? 'd-none' : ''}>
        <p className="section-label mb-1">Payment logs</p>
        <table className="table table-striped table-borderless w-100">
          <thead>
            <tr className="fs-normal-semibold text-center">
              <th scope="col">
                <p className="mb-2">Date</p>
              </th>
              <th scope="col">
                <p className="mb-2">Amount</p>
              </th>
              <th scope="col">
                <p className="mb-2">Remarks</p>
              </th>
            </tr>
          </thead>
          <tbody>
            {transactionList.map((data, key)=>{
                return(
                    <tr className="fs-normal-medium text-center" key={key}>
                        <td className="pt-3">{formatDateTime(data.tranDatetime, false)}</td>
                        <td className="pt-3">{toLocalePrice(data.amt)}</td>
                        <td className="pt-3">{data.remark}</td>
                    </tr>
                );
            })}
          </tbody>
        </table>
      </Stack>
    </DrawerLayout>
    </LocalizationProvider>
  );
}

export default FundWithdraw;