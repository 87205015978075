import React, {useState, useEffect} from 'react';
import DialogLayout from '../../components/layout/DialogLayout';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import 'dayjs/locale/en-gb';
import { DatePicker } from "@mui/x-date-pickers";
import { useSnackbar } from 'notistack';
import { Stack, TextField } from '@mui/material';
import { apiSaveValuationUpdate } from '../../utils/api/api';
import { SNACKBAR_ERROR, SNACKBAR_SUCCESS, SNACKBAR_WARNING } from "../../utils/constants/constants";

const ValuationUpdate = ({ open, toggleDialog, scheme, handleSave }) => {
  const [valuationAmount, setValuationAmount] = useState(0.00);
  const [remark, setRemark] = useState('');
  const [valuationDate, setValuationDate] = useState(
    new Date().toISOString().split('T')[0]
  );
  const { enqueueSnackbar } = useSnackbar();
  const [actionLoader, setActionLoader] = useState(false);

  const handleValuationDate = (selectedDate) => {
    const date = new Date(selectedDate?.$d);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const formattedDate = `${year}-${month}-${day}`;
    setValuationDate(formattedDate);
  }

  const saveValuation = (reqBody) => {
    setActionLoader(true);
    apiSaveValuationUpdate(reqBody)
      .then((data) => {
        setActionLoader(false);
        enqueueSnackbar(data,SNACKBAR_SUCCESS);
        handleSave();
      })
      .catch((error) => {
        setActionLoader(false);
        enqueueSnackbar(error,SNACKBAR_ERROR);
      });
  }

  const onSubmit = () => {
    const reqBody = {
      "oppRid": scheme.oppRid,
      "amount": parseFloat(valuationAmount),
      "valuationDate": valuationDate,
      "remark": remark
    }

    if(parseFloat(valuationAmount) <= 0){
      enqueueSnackbar("Invalid amount", SNACKBAR_WARNING);
    } else {
      saveValuation(reqBody);
    }
  }

  useEffect(() => {
    setRemark('');
    setValuationAmount(0.00);
  }, [open]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
      <DialogLayout
        open={open}
        title="Valuation Update"
        subtitle={scheme?.oppTitle}
        maxWidth="sm"
        actionLoader={actionLoader}
        closeAction={toggleDialog}
        negativeAction={
          {label: 'Cancel', onClick : toggleDialog}
        }
        positiveAction={{label: 'Save', onClick : onSubmit}}
      >
        <Stack direction="column" spacing={2}>
          <Stack direction="row" spacing={2}>
            <DatePicker
              sx={{ flex: 1 }}
              id="outlined-basic"
              name="valuationDate"
              label="Valuation Date"
              variant="outlined"
              value={valuationDate}
              fullWidth
              onChange={(v) => handleValuationDate(v)}
              renderInput={(params) => <TextField {...params} />}
            />
            <TextField
              sx={{ flex: 1 }}
              id="outlined-basic"
              name="valuationAmount"
              type="number"
              label="Valuation Amount"
              variant="outlined"
              value={valuationAmount}
              onChange={(e) => setValuationAmount(e.target.value)}
              fullWidth
            />
          </Stack>
          <TextField
            sx={{ flex: 1 }}
            id="outlined-multiline-static"
            label="Remark"
            multiline
            rows={4}
            value={remark}
            onChange={(e)=>setRemark(e.target.value)}
            fullWidth
          />
        </Stack>
      </DialogLayout>
    </LocalizationProvider>
  );
}

export default ValuationUpdate;