import React, { useEffect, useRef, useState } from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import 'dayjs/locale/en-gb';
import { Autocomplete, Badge, CircularProgress, Divider, FormControl, IconButton, InputLabel, MenuItem, Popper, Select, Switch, Tooltip } from "@mui/material";
import { useSnackbar } from 'notistack';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Info, RemoveCircle } from "@mui/icons-material";
import AddIcon from '@mui/icons-material/Add';
import { apiCreateInvestor, apiSearchCKYC, apiUpdateInvestor, getApiCityByRid, getApiCountryByRid, getCityNames, getCountryNames, getDataDictionaryValues, getMasterClass, getPartners, uploadFile } from "../../utils/api/api";
import { BASE_URL, DD_TYPE_BANK_TYPE, DD_TYPE_DP_NAMES, DD_TYPE_INVESTOR_TYPE, SNACKBAR_ERROR, SNACKBAR_SUCCESS, SNACKBAR_WARNING, DEMAT_CHARGES } from "../../utils/constants/constants";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ReplayIcon from '@mui/icons-material/Replay';
import { isValidCdsldpId, isValidClientId, isValidDate, isValidNsdldpId, toLocalePriceNoDecimal, trimSalutationInPan, trimSpace } from "../../utils/StringUtil";
import { isValidPAN } from "../../utils/Validator";
import DrawerLayout from "../../components/layout/DrawerLayout";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import DialogLayout from "../../components/layout/DialogLayout";

const InvestorModal = ({ open, onClose, isEdit, investor, handleSave }) => {

    const [isFileLoading, setIsFileLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [actionLoader, setActionLoader] = useState(false);
    const [invRid, setInvRid] = useState(null);
    const [userRid, setUserRid] = useState(null);
    const [intlPhoneNumber, setIntlPhoneNumber] = useState((investor?.countryCode + investor?.mobile) || null);
    const [intlCountryCode, setIntlCountryCode] = useState('');
    const [investorCode, setInvestorCode] = useState('');
    const [invUpFront, setInvUpFront] = useState(null);
    const [partnerRid, setPartnerRid] = useState(null);
    const [partnerList, setPartnerList] = useState([]);

    const [investorPan, setInvestorPan] = useState('');
    const [kycStatus, setKycStatus] = useState(0);
    const [manualCkyc, setManualCkyc] = useState(0);
    const [showKraToggle, setShowKraToggle] = useState(true);
    const [ckycStatus, setCkycStatus] = useState(0);
    const [mcaStatus, setMcaStatus] = useState(0);

    const [ckycStatusFromServer, setCkycStatusFromServer] = useState(0);
    const [mcaStatusFromServer, setMcaStatusFromServer] = useState(0);
    const [invPreference, setInvPreference] = useState(0);
    const [masterClassList, setMasterClassList] = useState([]);
    const [cmRid, setCmRid] = useState(null);
    const [carry, setCarry] = useState(0.00);
    const [hurdle, setHurdle] = useState(0.00);

    const [address, setAddress] = useState('');
    const [bankName, setBankName] = useState(null);
    const [otherBankName, setOtherBankName] = useState(null);
    const [otherDematName, setOtherDematName] = useState(null);
    const [bankNameIndex, setBankNameIndex] = useState(null);
    const [bankNameList, setBankNameList] = useState([]);
    const [accountNo, setAccountNo] = useState('');
    const [ifsc, setIFSC] = useState('');
    const [bankAddress, setBankAddress] = useState('');
    const [investorType, setInvestorType] = useState(null);
    const [investorTypeList, setInvestorTypeList] = useState([]);
    const [stampPaper, setStampPaper] = useState(0);
    const [welcomeEmail, setWelcomeEmail] = useState(false);
    const [dob, setDOB] = useState();

    const [kycDetails, setKycDetails] = useState(null);
    const [showKycDetails, setShowKycDetails] = useState(null);
    const [kycImageData, setKycImageData] = useState(null);

    const [dpNameList, setDpNameList] = useState([]);
    const [newDetails, setNewDetails] = useState({});

    const [includeDematCharge, setIncludeDematCharge] = useState(0);
    const [showAccountNo, setShowAccountNo] = useState(false);
    const [hasMoreCity, setHasMoreCity] = useState("");
    const [isKraSaved, setIsKraSaved] = useState(false);

    const [formData, setFormData] = useState({
        investorFullName: '',
        investorEmail: '',
    });

    const [errors, setErrors] = useState({
        investorFullName: '',
        investorEmail: '',
        dpName: '',
        dematAccNo: '',
        nsdlCdsl: '',
        dpId: '',
        dpClientId: '',
        firstHolderName: '',
        dob: ''
    });

    useEffect(() => {
        if (!open) {
            setCityList([]);
            setInputValue("");
            setSearchQuery("")
        }
    }, [open])

    const [invalidPhoneNumber, setInvalidPhoneNumber] = useState("");
    const [countryList, setCountryList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [initialCityList, setInitialCityList] = useState([]);
    const [cityRid, setCityRid] = useState(0);
    const [stateRid, setStateRid] = useState(0);
    const [cityName, setCityName] = useState("");
    const [countryRid, setCountryRid] = useState(0);
    const [countryName, setCountryName] = useState("");
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [manualAccreditation, setManualAccreditation] = useState(0);
    const [inputValue, setInputValue] = useState("");
    const listboxRef = React.useRef();
    const [isBottom, setIsBottom] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));

        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: '',
        }));
    };

    const handleNewDetailsChange = (event) => {
        const { name, value } = event.target;

        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: '',
        }));

        setNewDetails((prevDetails) => ({
            ...prevDetails,
            [name]: value,
        }));


        // Account number logic
        if ((name === 'dpId' || name === 'dpClientId') && value.length === 8) {
            const newDpId = name === 'dpId' ? value : newDetails?.dpId;
            const newDpClientId = name === 'dpClientId' ? value : newDetails?.dpClientId;


            if (newDpId?.length === 8 && newDpClientId?.length === 8) {
                setShowAccountNo(true);
                setNewDetails((prevDetails) => ({
                    ...prevDetails,
                    dematAccNo: newDpId + newDpClientId,
                }));
            }
        }
    };

    const toggleKycImage = (imageData) => {
        if (kycImageData) {
            setKycImageData(null);
        } else {
            setKycImageData(imageData);
        }
    }

    const handleInvDOB = (selectedDate) => {
        if (selectedDate === "" || !selectedDate) {
            setDOB('');
        } else {
            setDOB(selectedDate);
        }
    }

    const handleAutoCompleteFS = (value, type) => {
        value = value.trim();

        if (type === DD_TYPE_BANK_TYPE) {
            const ddData = bankNameList.find((item) =>
                item.ddValue.trim() === value
            );
            if (ddData) {
                setBankName(null);
                setBankNameIndex(ddData.ddIndex);
            } else {
                setBankName(value);
                setBankNameIndex(null);
            }
        }
        if (type === DD_TYPE_DP_NAMES) {
            const ddData = dpNameList.find((item) =>
                item.ddValue.trim() === value
            );
            setNewDetails(prevState => ({
                ...prevState,
                dpName: ddData ? null : value,
                dpDdIndex: ddData ? ddData.ddIndex : null,
            }));
        }
    }

    const getPartnersList = () => {
        setPartnerList([]);
        getPartners()
            .then((res) => {
                setPartnerList(res.partnerMasterList);
            })
            .catch((e) => {
                enqueueSnackbar(e, SNACKBAR_ERROR);
            });
    }

    const uploadDocument = (e) => {
        setIsFileLoading(true);
        uploadFile(e.target.files[0])
            .then((res) => {
                setIsFileLoading(false);
                setNewDetails(prevState => ({
                    ...prevState,
                    [e.target.name]: res.data,
                }));
            })
            .catch((e) => {
                setIsFileLoading(false);
                enqueueSnackbar(e, SNACKBAR_ERROR);
            });
    }
    const deleteDocument = (key) => {
        setNewDetails(prevState => ({
            ...prevState,
            [key]: null,
        }));
    }

    const getMasterClassList = (param = {}) => {
        param.status = 1;
        getMasterClass(param)
            .then((data) => {
                setMasterClassList(data.classMasters);
            })
            .catch((error) => {
                console.log(error);
            });
    }
    const saveInvestor = (reqBody) => {
        if (isEdit) {
            setActionLoader(true);
            apiUpdateInvestor(reqBody)
                .then((data) => {
                    setActionLoader(false);
                    setIsKraSaved(true);
                    enqueueSnackbar(data, SNACKBAR_SUCCESS);
                    handleSave();
                })
                .catch((error) => {
                    setActionLoader(false);
                    enqueueSnackbar(error, SNACKBAR_ERROR);
                });
        } else {
            setActionLoader(true);
            apiCreateInvestor(reqBody)
                .then((data) => {
                    setActionLoader(false);
                    setIsKraSaved(true);
                    enqueueSnackbar(data, SNACKBAR_SUCCESS);
                    handleSave();
                })
                .catch((error) => {
                    setActionLoader(false);
                    enqueueSnackbar(error, SNACKBAR_ERROR);
                });
        }
    }

    const getDataDictionary = () => {
        getDataDictionaryValues()
            .then((data) => {
                const invTypes = data.filter((item) =>
                    item.ddType === DD_TYPE_INVESTOR_TYPE
                )
                const bankTypes = data.filter((item) =>
                    item.ddType === DD_TYPE_BANK_TYPE
                )
                const dpNames = data.filter((item) =>
                    item.ddType === DD_TYPE_DP_NAMES
                )
                setInvestorTypeList(invTypes);
                setBankNameList(bankTypes);
                setDpNameList(dpNames);
            })
            .catch((e) => {
                console.log(e);
            })
    }

    useEffect(() => {
        setShowKycDetails(false);
        setKycImageData(null);
        setIntlPhoneNumber(isEdit ? investor.countryCode + investor.mobile : '+91'); // Set default phone number
        setIntlCountryCode(isEdit ? investor.countryCode : '+91');
        setUserRid(isEdit ? investor.userRid : null);
        setInvUpFront(isEdit ? investor.upfront : null);
        setCmRid(isEdit ? (investor.cmRid || null) : null);
        setCarry(isEdit ? carry : 0.00);
        setHurdle(isEdit ? hurdle : 0.00);
        setPartnerRid(isEdit ? (investor.partnerRid ?? null) : null);
        setAddress(isEdit ? (investor.address ?? '') : '');
        setBankName(null);
        setOtherBankName(isEdit ? (investor.otherBankName ?? '') : '');
        setOtherDematName(isEdit ? (investor.otherDematName ?? '') : '')
        setBankNameIndex(isEdit ? (investor.bankIndex ?? null) : null);
        setAccountNo(isEdit ? (investor.accountNo ?? '') : '');
        setIFSC(isEdit ? (investor.ifscSwift ?? '') : '');
        setBankAddress(isEdit ? (investor.bankAddress ?? '') : '');
        setInvestorType(isEdit ? (investor.investorType ?? null) : null);
        setStampPaper(isEdit ? (investor.stampPaper ?? 0) : 0);
        setDOB(isEdit ? (investor?.dobDoi ? investor.dobDoi.split(" ")[0] : '') : '');
        setWelcomeEmail(false);
        setKycDetails(isEdit ? investor.ckycDataObject : null);
        setIncludeDematCharge(isEdit ? (investor.includeDematCharge ?? 0) : 0);
        setShowAccountNo(false);
        setInvalidPhoneNumber("");
        setErrors({});
        setCityRid(isEdit ? (investor.cityRid ?? 0) : 0);
        setStateRid(isEdit ? (investor.stateRid ?? 0) : 0);
        setCountryRid(isEdit ? (investor?.countryRid ?? 0) : 0);
        setCityName(isEdit ? (investor.cityName ?? "") : "");
        setCountryName(isEdit ? (investor.countryName ?? "") : "");
        setSearchQuery(isEdit ? (investor?.cityName ?? "") : "");
        setManualAccreditation(investor?.isAccredited ?? 0);
        setInputValue(isEdit ? (investor?.cityName ?? "") : "");
        setNewDetails(
            isEdit
                ? {
                    gstNo: investor?.gstNo,
                    dpDdIndex: investor?.dpDdIndex,
                    dpName: investor?.dpName,
                    dematAccNo: investor?.dematAccNo,
                    dpId: investor?.dpId,
                    dpClientId: investor?.dpClientId,
                    firstHolderName: investor?.firstHolderName,
                    cmlCopyObject: investor?.cmlCopyObject,
                    nsdlCdsl: investor?.nsdlCdsl
                }
                : {}
        );
        if (isEdit) {
            setInvRid(investor.invRid);
            setFormData((prevData) => ({
                ...prevData,
                investorFullName: investor.fullName ? investor.fullName : '',
                investorEmail: investor.email ? investor.email : '',
            }));
            setIntlPhoneNumber(investor.countryCode + investor.mobile);
            setIntlCountryCode(investor.countryCode);
            setInvestorCode(investor.invCode ? investor.invCode : '');
            //  setInvestorEmail(investor.email ? investor.email : '');
            setInvestorPan(investor.pan ? investor.pan : '');
            setKycStatus(investor.kycStatus);
            setManualCkyc(investor.kraStatus);
            setMcaStatus(investor.mcaStatus);
            setCkycStatusFromServer(investor.ckycStatus);
            setMcaStatusFromServer(investor.mcaStatus);
            setInvPreference(investor.invPref);
            setPartnerRid(investor?.partnerRid);
            setCityRid(investor?.cityRid);
            setStateRid(investor?.stateRid);
            setCountryRid(investor?.countryRid);
            setCityName(investor?.cityName);
            setCountryName(investor?.countryName);
            setManualAccreditation(investor?.isAccredited ?? 0);
            setIsKraSaved(investor?.kraStatus === 1);
            if (investor.invPref !== 0) {
                // getMcaDetails(investor.invRid);
            }
        } else {
            setInvRid(null);
            setFormData((prevData) => ({
                ...prevData,
                investorFullName: investor?.fullName ? investor?.fullName : '',
                investorEmail: investor?.email ? investor?.email : '',
            }));
            setInvestorCode('');
            // setInvestorEmail('');
            setInvestorPan('');
            setKycStatus(0);
            setManualCkyc(0);
            setMcaStatus(0);
            setCkycStatusFromServer(0);
            setMcaStatusFromServer(0);
            setInvPreference(0);
            setShowKraToggle(true);
            setManualAccreditation(0);
            setIsKraSaved(false);
        }
        setShowAccountNo(newDetails?.dematAccNo ? true : false);
        setShowAccountNo(newDetails?.dematAccNo ? true : false);
    }, [open]);

    const handleIntlPhoneInput = (value, data) => {
        let newCountryCode = "+" + (data ? data.dialCode : "");
        if (newCountryCode !== intlCountryCode) {
            setCityList([]);
            setIntlCountryCode(newCountryCode);
            setCountryRid(0)
            getCountries(newCountryCode);
            setInputValue("")
            setSearchQuery("")
        } else if (!data || !data.dialCode) {
            setCountryList([]);
        }
        setIntlPhoneNumber("+" + value);
    };

    useEffect(() => {
        const masterClass = masterClassList.find((option) => option.cmRid === cmRid) || null;
        if (masterClass) {
            setCarry(masterClass.carry);
            setHurdle(masterClass.hurdleRate);
        }
    }, [cmRid]);

    useEffect(() => {
        getMasterClassList();
        getPartnersList();
        getDataDictionary();
        setInputValue("")
    }, []);

    const onSubmit = () => {
        const countryCode = intlCountryCode;
        const phoneNumberDigits = intlPhoneNumber.replace(intlCountryCode, '');
        const reqBody = {
            "investor": {
                "invRid": invRid,
                "userRid": userRid,
                "fullName": trimSpace(formData.investorFullName),
                "invCode": investorCode,
                "mobile": phoneNumberDigits,
                "email": formData.investorEmail,
                "pan": investorPan,
                "partnerRid": partnerRid,
                "mcaStatus": mcaStatus,
                "kycStatus": kycStatus,
                "kraStatus": manualCkyc,
                "upfront": invUpFront,
                "cmRid": cmRid,
                "countryCode": countryCode,
                "address": address,
                "bankName": bankName,
                "bankIndex": bankNameIndex,
                "accountNo": accountNo,
                "ifscSwift": ifsc,
                "bankAddress": bankAddress,
                "investorType": investorType,
                "stampPaper": stampPaper,
                "invPref": kycStatus || manualCkyc ? invPreference : 0,
                "dobDoi": dob ? dob : null,
                "status": 1,
                "ckycDataObject": kycDetails,
                "includeDematCharge": includeDematCharge,
                "otherBankName": otherBankName,
                "otherDematName": otherDematName,
                "countryRid": countryRid,
                "cityRid": cityRid,
                "stateRid": stateRid,
                isAccredited: manualAccreditation,
                ...newDetails
            },

            "welcomeEmail": welcomeEmail
        }

        // Validate Investor Full Name
        if (formData.investorFullName.trim() === '') {
            setErrors(prevErrors => ({
                ...prevErrors,
                investorFullName: "Please fill in the Investor Name"
            }));
            return
        }

        // Validate Phone Number
        if (phoneNumberDigits.trim() === '') {
            setInvalidPhoneNumber("Invalid Mobile Number");
            return
        }

        // Validate Investor Email
        if (formData.investorEmail.trim() === '') {
            setErrors(prevErrors => ({
                ...prevErrors,
                investorEmail: "Please fill in the Investor Email"
            }));
            return
        }

        if (dob !== "" && dob !== null) {
            if (isValidDate(dob) === false) {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    dob: "Please enter valid DOB"
                }));
                enqueueSnackbar("Invalid DOB", SNACKBAR_ERROR);
                return;
            }
        }

        // Validate DP Details
        if (Object.keys(newDetails).length) {
            const hasAnyValue =
                (newDetails.dpName && newDetails.dpName.trim() !== "") ||
                (newDetails.dpDdIndex != null && newDetails.dpDdIndex !== 0) ||
                (newDetails.nsdlCdsl != null) ||
                (newDetails.dpClientId && newDetails.dpClientId.trim() !== "") ||
                (newDetails.dpId && newDetails.dpId.trim() !== "") ||
                (newDetails.firstHolderName && newDetails.firstHolderName.trim() !== "");
            if (hasAnyValue) {
                // Perform validations only if at least one field has a value
                if ((newDetails.dpName?.trim() === "" || !newDetails.dpName) && (newDetails.dpDdIndex === null || !newDetails.dpDdIndex)) {
                    setErrors(prevErrors => ({
                        ...prevErrors,
                        dpName: "Please fill in the DP Name"
                    }));
                    return;
                }
                if (!newDetails.nsdlCdsl) {
                    setErrors(prevErrors => ({
                        ...prevErrors,
                        nsdlCdsl: "Please select DP type"
                    }));
                    return;
                }
                if (!newDetails.dpId) {
                    setErrors(prevErrors => ({
                        ...prevErrors,
                        dpId: "Please fill in the DP ID"
                    }));
                    return;
                }
                if (newDetails.nsdlCdsl === 'NSDL' && !isValidNsdldpId(newDetails.dpId)) {
                    setErrors(prevErrors => ({
                        ...prevErrors,
                        dpId: "Invalid DP ID"
                    }));
                    return;
                }
                if (newDetails.nsdlCdsl === 'CDSL' && !isValidCdsldpId(newDetails.dpId)) {
                    setErrors(prevErrors => ({
                        ...prevErrors,
                        dpId: "Invalid DP ID"
                    }));
                    return;
                }
                if (!newDetails.dpClientId) {
                    setErrors(prevErrors => ({
                        ...prevErrors,
                        dpClientId: "Please fill in the Client ID"
                    }));
                    return;
                } else if (!isValidClientId(newDetails.dpClientId)) {
                    setErrors(prevErrors => ({
                        ...prevErrors,
                        dpClientId: "Invalid Client ID"
                    }));
                    return;
                }
                if (!newDetails.firstHolderName) {
                    setErrors(prevErrors => ({
                        ...prevErrors,
                        firstHolderName: "Please fill in the first holder name"
                    }));
                    return;
                }
            }
        }

        if (investorPan && !isValidPAN(investorPan)) {
            enqueueSnackbar("Invalid PAN number", SNACKBAR_WARNING);
            return
        }
        saveInvestor(reqBody);
    }

    const getCountries = (countryCode) => {
        if (!countryCode) {
            setCountryList([]);
            return;
        }
        getCountryNames(countryCode)
            .then((data) => {
                setHasMoreCity("")
                setCountryList(data || []);
            })
            .catch(() => {
                setCountryList([]);
            });
    };

    const getCountriesByRid = (countryRid) => {
        getApiCountryByRid(countryRid)
            .then((data) => {
                setCountryName(data?.name);
                setCountryRid(data?.countryRid)
                if (countryRid !== 0) {
                    if (isEdit && cityName !== "") {
                        getCityByRid(cityRid)
                    } else {
                        fetchCities(countryRid, 1, 20, "", false)
                    }
                }
            })
            .catch((error) => {
                setCountryList([]);
            });
    };

    const getCityByRid = (cityRid) => {
        getApiCityByRid(cityRid)
            .then((data) => {
                if (data) {
                    setCityList([])
                    setCityName(data.name);
                    setCityRid(data?.cityRid)
                    setInputValue(data?.name)
                    setCityList((prevList) => {
                        const isCityPresent = prevList.some((city) => city.cityRid === data.cityRid);
                        if (!isCityPresent) {
                            return [data, ...prevList];
                        }
                        return prevList;
                    });
                }
            })
            .catch((error) => {
                console.error('Error fetching city :', error);
            });
    };


    useEffect(() => {
        if (open) {
            if (isEdit) {
                if (countryRid !== 0) {
                    getCountries(intlCountryCode)
                    //  getCountriesByRid(countryRid)
                } else {
                    return;
                }
            }

        }
    }, [open, countryRid])

    useEffect(() => {
        if (open) {
            if (cityRid) {
                getCityByRid(cityRid); // Fetch the exact city details
            }
        }

    }, [open, cityRid]);

    const fetchCities = (rid, page, size, query = "", loadMore = false) => {
        if (countryRid !== 0) {
            setLoading(true);
            getCityNames(countryRid, page, 20, query)
                .then((newCities) => {
                    if (newCities && newCities.length > 0) {
                        setHasMoreCity("")
                        if (loadMore) {
                            setCityList((prevList) => {
                                const uniqueCities = Array.from(
                                    new Set([...prevList, ...newCities].map((city) => city.cityRid))
                                ).map((cityRid) => [...prevList, ...newCities].find((city) => city.cityRid === cityRid));
                                return uniqueCities;
                            });
                            // Assuming each list item has a fixed height (for example, 40px).
                            const itemHeight = 30; // Adjust this value to match your list item height.
                            setTimeout(() => {
                                const newScrollHeight = listboxRef.current.scrollHeight;
                                const previousScrollHeight = listboxRef.current.scrollHeight - newScrollHeight;

                                const itemCount = cityList.length;
                                const scrollOffset = itemCount * itemHeight;

                                listboxRef.current.scrollTop = scrollOffset;
                            }, 0.5);
                        } else {
                            setCityList(newCities)
                        }
                    } else {
                        setHasMoreCity("true")
                    }
                })
                .catch((e) => {
                    console.error('Error fetching cities:', e);
                })
                .finally(() => {
                    setLoading(false);
                    setIsBottom(false)
                });

        }
    };

    const loadMoreCities = (e) => {
        const listBox = listboxRef.current;
        const isAtBottom = listBox.scrollHeight - listBox.scrollTop <= listBox.clientHeight + 10;
        if (isAtBottom && !loading) {
            setIsBottom(true);
            const nextPage = currentPage + 1;
            setCurrentPage(nextPage);
            fetchCities(countryRid, currentPage + 1, 20, searchQuery, true);
        }
    };

    useEffect(() => {
        console.log(isBottom)
        // After loading new cities, adjust the scroll position 0.5%  up from the bottom to avoid scroll reset to top
        if (listboxRef?.current) {
            if (isBottom) {
                const newItemsHeight = listboxRef?.current?.scrollHeight;
                const offset = newItemsHeight * 0.05;
                listboxRef.current.scrollTop = newItemsHeight - offset;
            }
        }
    }, [isBottom]);

    useEffect(() => {
        if (!isEdit) {
            if (countryList.length === 1) {
                setCountryRid(countryList[0].countryRid || 0);
                setCountryName(countryList[0].name);
                setSearchQuery("")
            }
        }
    }, [countryList]);

    useEffect(() => {
        if (open) {
            if (investor?.countryRid) {
                return;
            } else {
                getCountries(intlCountryCode)
            }
        }
    }, [open, intlCountryCode])

    useEffect(() => {
        if (open) {
            if (countryRid !== 0) {
                if (searchQuery.length < 3) {
                    fetchCities(countryRid, 1, 20, "", false)
                }
            }
        }
    }, [open, countryRid, searchQuery])

    const verifyPAN = () => {
        if (!isValidPAN(investorPan)) {
            enqueueSnackbar("Invalid PAN number", SNACKBAR_WARNING);
        } else {
            setActionLoader(true);
            apiSearchCKYC({
                "type": "C",
                "id": investorPan
            })
                .then((res) => {

                    const data = res.data;                  

                    setActionLoader(false);
                    setKycDetails(data);
                    setKycStatus(data.ckycAvailable ? 1 : 0)
                    data.ckycAvailable
                        ? enqueueSnackbar('CKYC - Verified', SNACKBAR_SUCCESS)
                        : enqueueSnackbar('CKYC - Not Verified', SNACKBAR_ERROR)
                    setTimeout(() => {
                        if (data.ckycAvailable) {
                            setShowKycDetails(true);
                            if (trimSalutationInPan(trimSpace(data?.dataResponse.name)) != trimSalutationInPan(trimSpace(formData.investorFullName))) {
                                setFormData((prevData) => ({
                                    ...prevData,
                                    investorFullName: trimSalutationInPan(trimSpace(data?.dataResponse.name)),
                                }));
                                enqueueSnackbar("The investor's name is different from the CKYC name, hence the investor name will be auto-corrected", SNACKBAR_ERROR);
                            }
                        }
                    }, 1000);
                    if (data.ckycAvailable) {
                        setShowKycDetails(true);
                        // setShowKraToggle(false);
                        setManualCkyc(0)
                    }
                    // else {
                    //     setManualCkyc(0);
                    //     setShowKraToggle(true);
                    // }
                })
                .catch((error) => {
                    setActionLoader(false);
                    enqueueSnackbar(error, SNACKBAR_ERROR);
                });
        }
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
            <DrawerLayout
                open={open}
                title={isEdit ? 'Edit Investor' : 'Add Investor'}
                actionLoader={actionLoader}
                closeAction={onClose}
                negativeAction={
                    { label: 'Cancel', onClick: onClose }
                }
                positiveAction={
                    { label: 'Save', onClick: onSubmit }
                }
            >
                <DialogLayout
                    title={''}
                    open={kycImageData !== null}
                    maxWidth={'xs'}
                    closeAction={() => toggleKycImage()}
                >
                    <div className="d-flex justify-content-center">
                        <img src={`data:image/png;base64, ${kycImageData}`} alt="..." />
                    </div>
                </DialogLayout>

                <Stack sx={{ marginBottom: "1rem" }} direction="column" spacing={2}>
                    <p className="section-label p-0 m-0">PERSONAL DETAILS</p>
                    <Stack direction="row" spacing={2}>
                        <TextField
                            sx={{ flex: 1 }}
                            id="outlined-basic"
                            name="investorFullName"
                            label="Investor Name"
                            variant="outlined"
                            value={formData.investorFullName}
                            error={!!errors.investorFullName}
                            helperText={errors.investorFullName}
                            fullWidth
                            onChange={handleChange}
                        />

                        <TextField
                            sx={{ flex: 1 }}
                            id="outlined-basic"
                            name="investorCode"
                            label="Investor Code"
                            variant="outlined"
                            value={investorCode}
                            fullWidth
                            onChange={(e) => setInvestorCode(e.target.value)}
                            disabled
                        />
                    </Stack>
                    <Stack direction="row" spacing={2}>
                        <Box sx={{ flex: 1 }}>
                            <div>
                                <PhoneInput
                                    specialLabel="Phone"
                                    inputStyle={{ width: '100%', height: '100%' }}
                                    //  country={intlCountryCode.replace('+', '').toLowerCase()} // Use country code without '+'
                                    value={intlPhoneNumber}
                                    onChange={(value, data) => handleIntlPhoneInput(value, data)} // Call handler on change
                                    enableSearch={true}
                                    placeholder="Enter phone number"
                                />
                                {/* Manually display the error message */}
                                {invalidPhoneNumber && (
                                    <span style={{ color: 'red', fontSize: 'small' }}>{invalidPhoneNumber}</span>
                                )}
                            </div>
                        </Box>

                        <TextField
                            sx={{ flex: 1 }}
                            id="outlined-basic"
                            name="investorEmail"
                            label="Email"
                            variant="outlined"
                            value={formData.investorEmail}
                            error={!!errors.investorEmail}
                            helperText={errors.investorEmail}
                            fullWidth
                            onChange={handleChange}
                        />
                    </Stack>
                    <Stack direction="row" spacing={2}>
                        <TextField
                            sx={{ flex: 1 }}
                            id="outlined-basic"
                            type="date"
                            name="dob"
                            label="DOB/DOI"
                            variant="outlined"
                            value={dob || ''}
                            onChange={(e) => handleInvDOB(e.target.value)}
                            error={!!errors.dob}
                            helperText={errors.dob}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                        />
                        <Autocomplete
                            freeSolo
                            sx={{ flex: 1 }}
                            id="outlined-basic"
                            variant="outlined"
                            options={investorTypeList}
                            getOptionLabel={(option) => option.ddValue}
                            value={investorTypeList.find((option) => option.ddIndex === investorType) || null}
                            fullWidth
                            onChange={(e, v) => setInvestorType(v.ddIndex)}
                            renderInput={(params) => <TextField {...params} label="Investor Type" />}
                        />
                    </Stack>
                    <Stack direction="row" spacing={2}>
                        <Stack sx={{ flex: 1 }} direction="column" spacing={2}>
                            <Autocomplete
                                options={countryList || []}
                                getOptionLabel={(option) => option.name || ''}
                                isOptionEqualToValue={(option, value) => option.countryRid === value?.countryRid}
                                value={
                                    countryList.find(
                                        (country) => country.countryRid === countryRid
                                    ) || null
                                }
                                onChange={(event, value) => {
                                    setCurrentPage(1)
                                    setCityList([]);
                                    setCityName('');
                                    setCityRid(0)
                                    setHasMoreCity("")
                                    setInputValue("")
                                    setSearchQuery("")
                                    if (value) {
                                        setCountryName(value.name);
                                        setCountryRid(value.countryRid);
                                    } else {
                                        setCountryName('');
                                        setCountryRid(0);
                                        setCityName('');
                                        setCityRid(0)
                                    }
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Country"
                                        placeholder="Select your country"
                                        variant="outlined"
                                    />
                                )}
                                disablePortal
                                fullWidth
                            />
                            <Autocomplete
                                options={
                                    cityRid && cityList.find((city) => city.cityRid === cityRid)
                                        ? cityList.filter((city) => city.cityRid === cityRid)
                                        : cityList
                                }
                                getOptionLabel={(option) => option.name || ""}
                                isOptionEqualToValue={(option, value) => option.cityRid === value?.cityRid}
                                value={
                                    cityList.find((city) => city.cityRid === cityRid) ||
                                    (inputValue && { name: inputValue }) ||
                                    null
                                }
                                inputValue={inputValue}
                                onInputChange={(event, value, reason) => {
                                    if (reason === "input") {
                                        setInputValue(value);
                                        setCurrentPage(1);
                                        setSearchQuery(value);
                                        setCityRid(0);
                                        setCityList([]);
                                        if (value?.length >= 3) {
                                            setTimeout(() => {
                                                fetchCities(countryRid, 1, 20, value, false);
                                            }, 500);
                                        }
                                    } else if (reason === "clear") {
                                        setInputValue("");
                                        setCityRid(0);
                                        setSearchQuery("");
                                    }
                                }}
                                onChange={(event, value) => {
                                    if (!value) {
                                        setCityRid(0);
                                        setStateRid(0);
                                        setInputValue("");
                                    } else {
                                        setCityRid(value.cityRid);
                                        setStateRid(value.stateRid);
                                        setCityName(value.name);
                                        setSearchQuery(value.name);
                                        setInputValue(value.name);
                                    }
                                }}
                                renderOption={(props, option) => (
                                    <li {...props} key={option.cityRid}>
                                        {option.name} {option.stateName && ` [${option.stateName}]`}
                                    </li>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="City"
                                        placeholder="Search your city"
                                        variant="outlined"
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <>
                                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                    {params.InputProps.endAdornment}
                                                </>
                                            ),
                                        }}
                                    />
                                )}
                                disablePortal
                                fullWidth
                                PopperComponent={(props) => (
                                    <Popper
                                        {...props}
                                        style={{
                                            ...props.style,
                                            zIndex: 1.5,
                                        }}
                                    />
                                )}
                                ListboxComponent={(props) => (
                                    <ul
                                        {...props}
                                        ref={listboxRef}
                                        onScroll={hasMoreCity === "" ? loadMoreCities : undefined}
                                        style={{
                                            overflowY: "auto",
                                            maxHeight: 320,
                                            zIndex: 2,
                                        }}
                                    />
                                )}
                            />


                        </Stack>
                        <TextField
                            sx={{ flex: 1 }}
                            id="outlined-multiline-static"
                            label="Address"
                            multiline
                            rows={4}
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                            fullWidth
                        />
                    </Stack>
                    <Stack direction="row" spacing={2}>
                        <TextField
                            sx={{ flex: 1 }}
                            type="number"
                            id="outlined-basic"
                            name="invUpFront"
                            label="Upfront % (optional)"
                            variant="outlined"
                            value={invUpFront}
                            onChange={(e) => setInvUpFront(e.target.value)}
                            fullWidth
                        />
                        <Autocomplete
                            sx={{ flex: 1 }}
                            id="outlined-basic"
                            variant="outlined"
                            options={masterClassList}
                            getOptionLabel={(option) => option.name}
                            value={masterClassList.find((option) => option.cmRid === cmRid) || null}
                            fullWidth
                            onChange={(e, v) => setCmRid(v.cmRid)}
                            renderInput={(params) => <TextField {...params} label="Class (optional)" />}
                        />
                    </Stack>
                    <Stack direction="row" spacing={2}>
                        <Autocomplete
                            sx={{ flex: 1 }}
                            id="outlined-basic"
                            variant="outlined"
                            options={partnerList}
                            getOptionLabel={(option) => option.fullName}
                            value={partnerList.find((option) => option.partnerRid === partnerRid) || null}
                            fullWidth
                            onChange={(e, v) => {
                                setPartnerRid(v.partnerRid);
                            }}
                            renderInput={(params) => <TextField {...params} label="Partner (optional)" />}
                        />
                        <Stack sx={{ flex: 1 }} direction="row" spacing={2}>
                            <Box sx={{}}>
                                <p className="section-label mb-0" style={{ fontSize: '12px' }}>STAMP PAPER?</p>
                                <Switch color="secondary" checked={stampPaper} onChange={(e) => setStampPaper(e.target.checked ? 1 : 0)} />
                            </Box>
                            <Box sx={{}}>
                                <p className="section-label mb-0" style={{ fontSize: '12px' }}>Welcome Email?</p>
                                <Switch color="secondary" checked={welcomeEmail} onChange={(e) => setWelcomeEmail(e.target.checked)} />
                            </Box>

                            <Box sx={{}}>
                                <p className="section-label mb-0" style={{ fontSize: "12px" }}>Incl Demat Charges?</p>
                                <FormControlLabel color="secondary" control={<Switch checked={includeDematCharge} onChange={(e) => setIncludeDematCharge(e.target.checked ? 1 : 0)} />} label={`+ ${toLocalePriceNoDecimal(DEMAT_CHARGES)}`} />
                            </Box>

                        </Stack>
                    </Stack>
                </Stack>
                <Divider />
                <Stack sx={{ marginBottom: "1rem", marginTop: "1rem" }} direction="column" spacing={2}>
                    <p className="section-label p-0 m-0">BANK DETAILS</p>
                    <Stack direction="row" spacing={2}>
                        <Box sx={{ flex: 1 }}>
                            <Autocomplete
                                freeSolo
                                className="pb-2"
                                sx={{ flex: 1 }}
                                id="outlined-basic"
                                variant="outlined"
                                options={bankNameList}
                                getOptionLabel={(option) => option.ddValue}
                                value={bankNameList.find((option) => option.ddIndex === bankNameIndex) || null}
                                fullWidth
                                onInputChange={(e, v) => {
                                    handleAutoCompleteFS(v, DD_TYPE_BANK_TYPE);
                                }}
                                renderInput={(params) => <TextField {...params} label="Bank" />}
                            />
                            {isEdit && investor.bankIndex === 0 && investor.otherBankName && (
                                <div className="d-flex">
                                    <h6 className="text-danger">Other Bank :</h6>
                                    <h6 className="text-danger">{investor.otherBankName}</h6>
                                </div>
                            )}
                        </Box>

                        <TextField
                            sx={{ flex: 1 }}
                            id="outlined-basic"
                            name="bankAddress"
                            label="Bank Address"
                            variant="outlined"
                            value={bankAddress}
                            onChange={(e) => setBankAddress(e.target.value)}
                            fullWidth
                        />
                    </Stack>
                    <Stack direction="row" spacing={2}>
                        <TextField
                            sx={{ flex: 1 }}
                            id="outlined-basic"
                            name="accountNo"
                            label="Account Number"
                            variant="outlined"
                            value={accountNo}
                            onChange={(e) => setAccountNo(e.target.value)}
                            fullWidth
                        />
                        <TextField
                            sx={{ flex: 1 }}
                            id="outlined-basic"
                            name="ifsc"
                            label="IFSC/SWIFT"
                            variant="outlined"
                            value={ifsc}
                            onChange={(e) => setIFSC(e.target.value)}
                            fullWidth
                        />
                    </Stack>
                </Stack>
                <Divider />

                <Stack
                    sx={{ marginBottom: "1rem", marginTop: "1rem" }}
                    direction="column"
                    spacing={2}
                >
                    <p className="section-label p-0 m-0">DEMAT DETAILS</p>

                    <Stack direction="row" spacing={2}>

                        <Box sx={{ flex: 1 }}>
                            <Autocomplete
                                freeSolo
                                sx={{ flex: 1 }}
                                id="outlined-basic"
                                variant="outlined"
                                options={dpNameList}
                                getOptionLabel={(option) => option.ddValue}
                                value={dpNameList.find((option) => option.ddIndex === newDetails?.dpDdIndex) || null}
                                fullWidth
                                onInputChange={(e, v) => {
                                    handleAutoCompleteFS(v, DD_TYPE_DP_NAMES);
                                    // Reset error when the user types something
                                    if (errors.dpName) {
                                        setErrors((prevErrors) => ({ ...prevErrors, dpName: "" }));
                                    }
                                }}
                                onChange={(e, newValue) => {
                                    // Handle selection from the dropdown
                                    if (newValue) {
                                        handleAutoCompleteFS(newValue.ddValue, DD_TYPE_DP_NAMES);
                                        // Reset error if a valid value is selected
                                        if (errors.dpName) {
                                            setErrors((prevErrors) => ({ ...prevErrors, dpName: "" }));
                                        }
                                    }
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} label="Depository Participant (DP) Name" />
                                )}
                            />
                            {isEdit && investor?.dpDdIndex === 0 && investor?.otherDematName && (
                                <div className="d-flex">
                                    <h6 className="text-danger">Other DP Name :</h6>
                                    <h6 className="text-danger">{investor?.otherDematName}</h6>
                                </div>
                            )}
                            {errors.dpName && (
                                <span style={{ color: 'red', fontSize: 'small' }}>{errors.dpName}</span>
                            )}
                        </Box>

                        <FormControl sx={{ flex: 1 }} error={!!errors.nsdlCdsl}>
                            <InputLabel id="demo-simple-select-label">NSDL / CDSL</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                name="nsdlCdsl"
                                value={newDetails?.nsdlCdsl || ''}
                                label="NSDL / CDSL"
                                onChange={handleNewDetailsChange}
                            >
                                <MenuItem value={null}>---</MenuItem>
                                <MenuItem value="NSDL">NSDL</MenuItem>
                                <MenuItem value="CDSL">CDSL</MenuItem>
                            </Select>
                            {errors.nsdlCdsl && (
                                <span style={{ color: 'red', fontSize: 'small' }}>{errors.nsdlCdsl}</span>
                            )}
                        </FormControl>

                    </Stack>
                    <Stack direction="row" spacing={2}>
                        <TextField
                            sx={{ flex: 1 }}
                            id="outlined-basic"
                            name="dpId"
                            label="DP ID"
                            variant="outlined"
                            value={newDetails?.dpId ?? ''}
                            onChange={(e) => handleNewDetailsChange(e)}
                            error={!!errors.dpId}
                            helperText={errors.dpId}
                            inputProps={{ maxLength: 8 }}
                            fullWidth
                        />
                        <TextField
                            sx={{ flex: 1 }}
                            id="outlined-basic"
                            name="dpClientId"
                            label="Client ID"
                            variant="outlined"
                            value={newDetails?.dpClientId ?? ''}
                            onChange={(e) => handleNewDetailsChange(e)}
                            error={!!errors.dpClientId}
                            helperText={errors.dpClientId}
                            inputProps={{ maxLength: 8 }}
                            fullWidth
                        />
                    </Stack>
                    <Stack direction="row" spacing={2}>
                        {(showAccountNo || newDetails?.dematAccNo) && (
                            <TextField
                                sx={{ flex: 1 }}
                                id="outlined-basic"
                                name="dematAccNo"
                                label="Demat Account Number"
                                variant="outlined"
                                value={newDetails?.dematAccNo ?? ''}
                                onChange={(e) => handleNewDetailsChange(e)}
                                inputProps={{ maxLength: 16 }}
                                fullWidth
                            />

                        )}
                        <TextField
                            sx={{ flex: 1 }}
                            id="outlined-basic"
                            name="firstHolderName"
                            label="First Holder Name"
                            variant="outlined"
                            value={newDetails?.firstHolderName ?? ''}
                            onChange={(e) => handleNewDetailsChange(e)}
                            error={!!errors.firstHolderName}
                            helperText={errors.firstHolderName ? errors.firstHolderName : "Must be same as Investor Name"}
                            fullWidth
                        />
                    </Stack>
                    <Stack direction="row" spacing={2}>
                        <Box sx={{ flex: 1 }}>
                            <Stack sx={{ flexWrap: 'wrap' }} direction="row">
                                <input
                                    className="d-none"
                                    id={`input-file`}
                                    type="file"
                                    // accept=".pdf"
                                    name="cmlCopyObject"
                                    onClick={(e) => {
                                        e.target.value = null;
                                    }}
                                    onChange={(e) => {
                                        uploadDocument(e);
                                    }}
                                />
                            </Stack>

                            <Stack direction="column">
                                <p className="section-label mb-2" style={{ fontSize: '12px' }}>CML COPY</p>

                                <Stack sx={{ alignItems: 'center' }} direction="row" spacing={2}>
                                    {
                                        newDetails?.cmlCopyObject
                                            ? <Tooltip title={newDetails?.cmlCopyObject?.actualFileName}>
                                                <Badge
                                                    badgeContent={<RemoveCircle fontSize="10" onClick={() => deleteDocument('cmlCopyObject')} />}
                                                    color="error">
                                                    <AttachFileIcon color="secondary" onClick={() => window.open(`${BASE_URL}/${newDetails?.cmlCopyObject?.path}`, '_blank')} />
                                                </Badge>
                                            </Tooltip>
                                            : <IconButton color="secondary" disabled={isFileLoading} onClick={() =>
                                                document.getElementById(`input-file`).click()}>
                                                <AddIcon />
                                            </IconButton>
                                    }
                                </Stack>
                            </Stack>
                        </Box>
                        <Box sx={{ flex: 1 }} />
                    </Stack>
                </Stack>

                <Divider />
                <Stack sx={{ marginBottom: "1rem", marginTop: "1rem" }} direction="column" spacing={2}>
                    <p className="section-label p-0 m-0">CKYC</p>
                    <Stack direction="row" spacing={2}>
                        <TextField
                            sx={{ flex: 1 }}
                            id="outlined-basic"
                            name="pan"
                            label="PAN"
                            variant="outlined"
                            value={investorPan}
                            fullWidth
                            // onChange={(e) => setInvestorPan(e.target.value)}
                            onChange={(e) => {
                                setKycDetails(null)
                                setKycStatus(0)
                                setShowKycDetails(false)
                                setInvestorPan(e.target.value)
                            }}
                            disabled={ckycStatusFromServer === 1 ? true : false}
                            InputProps={{
                                endAdornment: ckycStatusFromServer ? '' : <Button variant="outlined" color="success" sx={{ textTransform: 'none' }} onClick={() => verifyPAN()} startIcon={<CheckCircleIcon />} disabled={actionLoader || !isValidPAN(investorPan)}>
                                    <span className="fs-med-medium">Verify</span>
                                </Button>
                            }}
                            helperText={
                                kycStatus ? '' : (kycDetails?.ckycAvailable ? '' : !isValidPAN(investorPan) ? 'Please Enter Valid PAN' : '')
                            }
                        />
                        <Box sx={{ flex: 1 }}>
                            <div className="d-flex gap-3">
                                <div>
                                    <p className="section-label mb-2" style={{ fontSize: '12px' }}>CKYC VERIFIED?</p>

                                    <p className="fs-med-medium p-0 m-0">
                                        {(kycStatus || manualCkyc) ? 'Verified' : (kycDetails?.ckycAvailable ? 'Verified' : 'Not Verified')}
                                        {
                                            kycDetails?.ckycAvailable
                                                ? <Info color="primary" className="ms-1" style={{ cursor: 'pointer' }} onClick={() => setShowKycDetails(!showKycDetails)} />
                                                : (
                                                    kycStatus
                                                        ? <Tooltip title="Fetch PAN Details">
                                                            <ReplayIcon color="primary" className="ms-1" style={{ cursor: 'pointer' }} onClick={() => verifyPAN()} />
                                                        </Tooltip>
                                                        : ''
                                                )
                                        }
                                    </p>
                                </div>
                                {(showKraToggle || manualCkyc) && !kycStatus ? (
                                    <p className="fs-med-medium p-0 m-0">
                                        <Box sx={{ flex: 1 }}>
                                            <p
                                                className="section-label mb-0"
                                                style={{ fontSize: "12px" }}
                                            >
                                                KRA VERIFIED?
                                            </p>
                                            {isKraSaved && manualCkyc ? (
                                                <Stack
                                                    direction="row"
                                                    alignItems="center"
                                                    spacing={1}
                                                    sx={{ mt: 1 }}
                                                >
                                                    <span>Verified</span>
                                                    <CheckCircleIcon color="success" fontSize="small" />
                                                </Stack>
                                            ) : (
                                                <Switch
                                                    color="secondary"
                                                    checked={manualCkyc}
                                                    onChange={(e) => {
                                                        if (!investorPan && !manualCkyc) {
                                                            enqueueSnackbar(
                                                                "Please enter PAN before verify.",
                                                                SNACKBAR_ERROR
                                                            );
                                                            return;
                                                        }
                                                        setManualCkyc(e.target.checked ? 1 : 0);
                                                        setIsKraSaved(false);
                                                    }}
                                                />
                                            )}
                                        </Box>
                                    </p>
                                ) : null}

                                {/* <p className="fs-med-medium p-0 m-0">

                                    <Box sx={{ flex: 1 }}>
                                        <p className="section-label mb-0" style={{ fontSize: '12px' }}>CKYC VERIFIED?</p>
                                        {investor?.ckycStatus ? <p className="fs-med-medium p-2 m-0">Verified <CheckCircleIcon color="success" /> </p> :
                                            <p className="fs-med-medium p-2 m-0">Not Verified <CancelRoundedIcon color="error" /></p>
                                        }
                                    </Box>

                                </p> */}
                            </div>

                        </Box>
                    </Stack>
                    <Stack direction="row" spacing={2}>
                        <TextField
                            sx={{ flex: 1 }}
                            id="outlined-basic"
                            name="gstNo"
                            label="GST Number"
                            variant="outlined"
                            value={newDetails?.gstNo}
                            onChange={(e) => handleNewDetailsChange(e)}
                            inputProps={{ maxLength: 15 }}
                            fullWidth
                        />
                        <Box sx={{ flex: 1 }} />
                    </Stack>
                    {
                        showKycDetails ?
                            <Stack direction="row" spacing={3} alignItems="center">
                                <Box sx={{ flex: 1 }}>
                                    <div className="d-flex gap-3 align-items-center">
                                        <img src={`data:image/png;base64, ${kycDetails?.dataResponse.photo}`} alt="..." style={{ cursor: "pointer", width: '70px' }} onClick={() => toggleKycImage(kycDetails?.dataResponse.photo)} />
                                        <div className="d-flex flex-column">
                                            <p className="section-label mb-1" style={{ fontSize: "12px" }}>Name</p>
                                            <p className="fs-med-medium p-0 m-0">{kycDetails?.dataResponse.name ?? "---"}</p>
                                        </div>
                                    </div>
                                </Box>
                                <Box sx={{ flex: 1 }}>
                                    <p className="section-label mb-1" style={{ fontSize: "12px" }}>Account Type</p>
                                    <p className="fs-med-medium p-0 m-0">{kycDetails?.ckycaccType ?? "---"}</p>
                                </Box>
                                <Box sx={{ flex: 1 }}>
                                    <p className="section-label mb-1" style={{ fontSize: "12px" }}>CKYC Reference ID</p>
                                    <p className="fs-med-medium p-0 m-0">{kycDetails?.dataResponse.ckycReferenceId ?? "---"}</p>
                                </Box>
                            </Stack> : ''
                    }
                </Stack>

                <Divider />
                <Stack sx={{ marginBottom: "1rem", marginTop: "1rem" }} direction="column" spacing={2}>
                    <Box sx={{ flex: 1 }}>
                        <div className="d-flex gap-3">
                            <div>
                                <p className="fs-med-medium p-0 m-0">
                                    <Box sx={{ flex: 1 }}>
                                        <p
                                            className="section-label mb-0"
                                            style={{ fontSize: "15px" }}
                                        >
                                            ACCREDITATION
                                        </p>
                                        <Switch
                                            color="secondary"
                                            checked={manualAccreditation === 1}
                                            onChange={(e) => {
                                                setManualAccreditation(e.target.checked ? 1 : 0);
                                            }}
                                            disabled={isEdit && investor?.isAccredited === 1}
                                        />
                                    </Box>
                                </p>
                            </div>
                        </div>
                    </Box>
                </Stack>
                {(kycStatus || manualCkyc) ?
                    <div>
                        <Divider />
                        <Stack sx={{ marginBottom: "1rem", marginTop: "1rem" }} direction="column" spacing={2}>
                            <p className="section-label p-0 m-0">Investment Preference</p>
                            <Stack direction="row" spacing={2}>
                                <FormControl sx={{ flex: 1 }}>
                                    <InputLabel id="demo-simple-select-label">Investment Preference</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={invPreference}
                                        label="Investment Preference"
                                        onChange={(e) => setInvPreference(parseInt(e.target.value))}
                                        disabled={mcaStatusFromServer === 1}
                                    >
                                        <MenuItem value="0">None</MenuItem>
                                        <MenuItem value="1">Lumpsum Investment</MenuItem>
                                        <MenuItem value="2">SIP Investment</MenuItem>
                                    </Select>
                                </FormControl>
                                <Box sx={{ flex: 1 }} />
                            </Stack>
                        </Stack>

                    </div> : ''
                }
            </DrawerLayout>
        </LocalizationProvider>
    );
}

export default InvestorModal;