import React, { useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import SearchIcon from "@mui/icons-material/SearchOutlined";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import Button from "@mui/material/Button";
import {
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Skeleton,
  Tooltip,
  Stack,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  CircularProgress,
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import { toLocalePrice } from "../../utils/StringUtil";
import { apiCompanyUpdates, apiGetMassEmail, apiGetMassEmailLog, apiUpdateCompanyUpdate } from "../../utils/api/api";
import { useSnackbar } from "notistack";
import {
  MASS_EMAIL_FAILED,
  MASS_EMAIL_PENDING,
  MASS_EMAIL_SUCCESS,
  RECORD_DISPLAY_LIMIT,
  SNACKBAR_ERROR,
  SNACKBAR_SUCCESS,
} from "../../utils/constants/constants";
import CloseIcon from "@mui/icons-material/Close";

import FilterListIcon from "@mui/icons-material/FilterList";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { formatDateTime, isSubAdmin } from "../../utils/StringUtil";
import AlertLayout from "../../components/layout/AlertLayout";
import MassMailerDrawer from "./MassMailerDrawer";

const MassMailer = () => {

  const { enqueueSnackbar } = useSnackbar();
  const [massEmailList, setMassEmailList] = useState([]);
  const [expanded, setExpanded] = useState(null);
  const [massEmailLogList, setMassEmailLogList] = useState([]);

  const [investorList, setInvestorList] = useState([]);

  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [updatesList, setUpdatesList] = useState([]);
  const [editUpdate, setEditUpdate] = useState(null);
  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  const [alertLoader, setAlertLoader] = useState(false);

  const [totalRecords, setTotalRecords] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const userType = process.env.REACT_APP_USER_TYPE;

  const getMassEmailList = () => {
    setIsLoading(true);
    apiGetMassEmail()
      .then((data) => {
        setIsLoading(false);
        setMassEmailList(data.reverse());
      })
      .catch((error) => {
        enqueueSnackbar(error, SNACKBAR_ERROR);
      });
  };


  const getMassEmailLogList = (param = {}) => {
    setIsLoading(true);

    param.meRid = expanded;
    apiGetMassEmailLog(param)
      .then((data) => {
        setIsLoading(false);
        setMassEmailLogList(data);
      })
      .catch((error) => {
        setIsLoading(false);
        enqueueSnackbar(error, SNACKBAR_ERROR);
      });
  };

  const handleClose = () => {
    setOpen(false);
    setIsEdit(false);
  };

  const handleOpen = () => {
    setOpen(true);
    setEditUpdate({});
  };

  const handleSave = () => {
    setPage(1);
    handleClose();
  };

  const toggleAlertDialog = () => {
    if (openAlertDialog) {
      setOpenAlertDialog(false);
    } else {
      setOpenAlertDialog(true);
    }
  };
  const handleAlertDialog = () => {
    setAlertLoader(true);
    editUpdate.status = 0;
    apiUpdateCompanyUpdate(editUpdate)
      .then((data) => {
        setAlertLoader(false);
        enqueueSnackbar(data, SNACKBAR_SUCCESS);
        toggleAlertDialog();
        setPage(1);

      })
      .catch((error) => {
        setAlertLoader(false);
        enqueueSnackbar(error, SNACKBAR_ERROR);
      });
  };


  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);
  };

  useEffect(() => {
    getMassEmailList();
  }, [open]);

  useEffect(() => {
    expanded !== null && getMassEmailLogList();
  }, [expanded]);



  return (
    <Layout title="Mass Mailer Investor">
      <AlertLayout
        open={openAlertDialog}
        title="Are You Sure ?"
        actionLoader={alertLoader}
        closeAction={toggleAlertDialog}
        positiveAction={{ label: "Delete", onClick: handleAlertDialog }}
      >
        <p className="fs-large-medium p-0 m-0">
          This action cannot be undone. This will permanently delete.
        </p>
      </AlertLayout>
      {userType === "ADMIN" && !isSubAdmin() && (
        <Button
          sx={{
            height: "64px",
            borderRadius: "50%",
            position: "fixed",
            zIndex: 10,
            bottom: "20px",
            right: "20px",
          }}
          onClick={handleOpen}
          variant="contained"
          aria-label="edit"
          color="secondary"
        >
          <AddIcon />
        </Button>
      )}

      <MassMailerDrawer
        open={open}
        onClose={handleClose}
        handleSave={handleSave}
      />

<div className="p-4">
  {isLoading ? (
    [...Array(RECORD_DISPLAY_LIMIT)].map((_e, index) => (
      <Skeleton
        animation="wave"
        key={index}
        style={{ marginBottom: 6 }}
        variant="rectangular"
        height={40}
      />
    ))
  ) : (
    <Stack>
      {massEmailList.map((data) => (
        <Accordion
          key={data.meRid}
          expanded={expanded === data.meRid}
          onChange={handleChange(data.meRid)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon color="primary" />}
            aria-controls={`panel-${data.meRid}-content`}
            id={`panel-${data.meRid}-header`}
          >
            <div className="row align-items-center p-0 m-0 w-100">
              <div className="col-3">
                <h5 className="fs-med-semibold">{data.formType}</h5>
              </div>
              <div className="col-6 fs-med-semibold">{data.subject}</div>
              <div className="col-3 fs-med-semibold">
                {data.createdDatetime ? formatDateTime(data.createdDatetime) : 'N/A'}
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            {expanded === data.meRid && massEmailLogList.length > 0 ? (
              <table className="table table-striped table-borderless p-0 m-0 w-100">
                <tbody>
                  {massEmailLogList.map((logData, key) => (
                    <tr className="fs-normal-medium text-center" key={key}>
                      <td className="pt-2">
                        {logData.fullName}
                        <span className="fs-small-regular text-muted ms-2">
                          {logData.invCode}
                        </span>
                      </td>
                      <td className="pt-2">{logData.invEmail}</td>
                      <td className="">
                        <p className="p-0 m-0">
                          <span
                            className={`fs-normal-medium px-2 py-1 transaction-status ${
                              logData.emailStatus === MASS_EMAIL_SUCCESS
                                ? "bg-success text-white"
                                : "bg-danger text-white"
                            }`}
                          >
                            {/* Display "Success" if status is MASS_EMAIL_SUCCESS, otherwise "Pending" */}
                            {logData.emailStatus === MASS_EMAIL_SUCCESS ? "Success" : "Pending"}
                          </span>
                        </p>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p className="fs-med-regular p-0 m-0">No Data</p>
            )}
          </AccordionDetails>
        </Accordion>
      ))}
    </Stack>
  )}
</div>

    </Layout>
  );
};

export default MassMailer;
