import React, { useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import ClassModal from "./ClassModal";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import SearchIcon from "@mui/icons-material/SearchOutlined";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import { IconButton, Skeleton } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import { apiUpdateInvestor, apiUpdateMasterClass, getInvestors, getMasterClass, investorEdit } from "../../utils/api/api";
import { useSnackbar } from 'notistack';
import { RECORD_DISPLAY_LIMIT, SNACKBAR_ERROR, SNACKBAR_SUCCESS } from "../../utils/constants/constants";
import CloseIcon from "@mui/icons-material/Close";
import { isSubAdmin } from "../../utils/StringUtil";

function InvestorClass() {
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [masterClassList, setMasterClassList] = useState([]);
  const [editMasterClass, setEditMasterClass] = useState(null);

  const [totalRecords, setTotalRecords] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const userType = process.env.REACT_APP_USER_TYPE;

  const handleClose = () => {
    setOpen(false);
    setIsEdit(false);
  };

  const handleOpen = () => {
    setOpen(true);
    setEditMasterClass({});
  };

  const handleEdit = (id) => {
    setEditMasterClass(masterClassList.find((item) => item.cmRid === id))
    setOpen(true);
    setIsEdit(true);
  };

  const handleSave = () => {
    setPage(1);
    getMasterClassList();
    handleClose();
  };

  const getMasterClassList = (param = {}) => {
    param.pageNumber = page;
    param.name = searchTerm.trim();
    param.recLimit = RECORD_DISPLAY_LIMIT;
    param.status = 1;
    setIsLoading(true);
    getMasterClass(param)
      .then((data) => {
        setIsLoading(false);
        if(page === 1){
          setTotalRecords(data.records);
        }
        setMasterClassList(data.classMasters);
        
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  }

  const handlePageChange = (_event, value) => {
    setPage(value)
  }

  useEffect(() => {
    if (searchTerm.length < 3) {
      if (searchTerm.trim() === "") {
        getMasterClassList();
      } else {
        return
      }
    } else {
        getMasterClassList();
    }
    setPage(1);
  }, [searchTerm]);

  useEffect(() => {
    getMasterClassList();
  }, [page]);

  const deleteMasterClass = (masterClass) => {
    masterClass.status = 0;
    if(window.confirm("Are you sure want to delete?") == 1){
      apiUpdateMasterClass(masterClass)
        .then((data) => {
          enqueueSnackbar(data,SNACKBAR_SUCCESS);
          setPage(1);
          getMasterClassList();
        })
        .catch((error) => {
          enqueueSnackbar(error,SNACKBAR_ERROR);
        });
    }
  }

  return (
    <Layout title="Class">
      { userType === "ADMIN" && !isSubAdmin() && (
        <Button
        sx={{
        height: "64px",
        borderRadius: "50%",
        position: "fixed",
        bottom: "20px",
        right: "20px",
        }}
        onClick={handleOpen}
        variant="contained"
        aria-label="edit"
        color="secondary"
    ><AddIcon /></Button>
      )}
        
        <ClassModal
            open={open}
            onClose={handleClose}
            isEdit={isEdit}
            masterClass={editMasterClass}
            handleSave={(masterClass) => handleSave(masterClass)}
        />

        <div className="p-4">
          <div className="d-flex flex-row justify-content-between bg-white p-3 mb-4" style={{borderRadius: '5px'}}>
            <div className="search-input-btn d-flex flex-row justify-content-between rounded bg-white px-3" style={{border: '1px solid #000'}}>
              <div className="my-auto">
                  <SearchIcon sx={{ cursor: 'pointer' }} />
              </div>
              <input
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="form-control custom-form-control border-0 bg-transparent fs-large-regular "
                placeholder="Search by Name"
              />
              <div onClick={() => setSearchTerm('')} className="my-auto">
                  <CloseIcon sx={{ cursor: 'pointer' }} />
              </div>
            </div>
            <div className="justify-content-evenly d-flex align-items-center">
              <Pagination color="secondary" size="small" siblingCount={0} onChange={handlePageChange} count={totalRecords < RECORD_DISPLAY_LIMIT ? 1 : Math.ceil(totalRecords / RECORD_DISPLAY_LIMIT)} page={page} />
            </div>
          </div>

          <div className="bg-white py-2 px-3" style={{borderRadius: '5px'}}>
        {
            isLoading ? [...Array(RECORD_DISPLAY_LIMIT)].map((_e, index) => (
                <Skeleton
                animation="wave"
                key={index}
                style={{ marginBottom: 6 }}
                variant="rectangle"
                height={40}
                />
            )) : 
            <div>
                {masterClassList.length > 0 ? (
                    <table
                        className="table table-striped table-borderless w-100"
                        id="order-table"
                    >
                        <thead>
                            <tr className="fs-normal-semibold text-center">
                                {/* <th scope="col">
                                <p className="m-0">Actions</p>
                                </th> */}
                                <th scope="col">
                                <p className="m-0">Class Name</p>
                                </th>
                                <th scope="col">
                                <p className="m-0">Hurdle Rate %</p>
                                </th>
                                <th scope="col">
                                <p className="m-0">Carry %</p>
                                </th>
                            </tr>
                        </thead>
                        <tbody className="bg-table-blue">{
                            masterClassList.map((item, key) => {
                            return (
                                <tr className="fs-normal-medium text-center"
                                key={key}
                                >
                                {/* <td className="p-0">
                                    <div className="d-flex">
                                    <IconButton onClick={() => handleEdit(item.cmRid)} aria-label="edit">
                                        <EditIcon className="text-blue" />
                                    </IconButton>
                                    <IconButton aria-label="delete" onClick={()=>deleteMasterClass(item)}>
                                        <DeleteOutlineIcon className="text-danger" />
                                    </IconButton>
                                    </div>
                                </td> */}
                                <td className="pt-3">{item.name}</td>
                                <td className="pt-3">{item.hurdleRate}</td>
                                <td className="pt-3">{item.carry}</td>
                                </tr>
                            )
                            })}
                        </tbody>
                    </table>
                ) : <p>No Data..</p>}
            </div>
        }
        </div>
        </div>
    </Layout>
  );
}

export default InvestorClass;