import React, {useState, useEffect} from 'react';
import DialogLayout from '../../components/layout/DialogLayout';
import { apiInvestorOnboarding, apiInvestorOnboardingFile } from '../../utils/api/api';
import { SNACKBAR_ERROR, SNACKBAR_SUCCESS } from "../../utils/constants/constants";
import { useSnackbar } from 'notistack';
import { CircularProgress, Divider, Stack, TextField } from '@mui/material';

const InvestorOnboarding = ({ open, toggleDialog, scheme, handleSave }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [actionLoader, setActionLoader] = useState(false);
    const [investorList, setInvestorList] = useState([]);
    const [filteredInvList, setFilteredInvList] = useState([]);
    const { enqueueSnackbar } = useSnackbar();

    const downloadFile = async (response) => {
      const blob = new Blob([response.data], { type: response.headers.get('content-type') });
      const downloadLink = document.createElement('a');
      const url = window.URL.createObjectURL(blob);
      downloadLink.href = url;
      downloadLink.setAttribute('download' , response.headers.get('content-disposition').split('"')[1]);
      downloadLink.click();
      window.URL.revokeObjectURL(url);
    }

    const handleFilterInvList = (invList) => {
      const filteredData  = invList.filter((item) => {
        const nullFields = [];
        if (!item.invCode) nullFields.push('Investor ID');
        if (item.invPref === 0) nullFields.push('Client Type');
        if (item.invPref === 2 && parseFloat(item.sipAmount) <= 0) nullFields.push('SIP Amount');
        if (parseFloat(item.capitalCommitted) <= 0) nullFields.push('Capital Commitment');
        if (parseFloat(item.capitalContribution) <= 0) nullFields.push('Capital Contribution');
        if (!item.pan) nullFields.push('PAN');
        if (!item.address) nullFields.push('Address');
        if (!item.bankName) nullFields.push('Bank Name');
        if (!item.accountNo) nullFields.push('Bank Account No');
        if (!item.ifscSwift) nullFields.push('IFSC/SWIFT Code');
        if (!item.bankAddress) nullFields.push('Bank Address');
        if (!item.investorType) nullFields.push('Investor Type');
        if (!item.mobile) nullFields.push('Mobile');
        if (!item.email) nullFields.push('Email');
        if (!item.className) nullFields.push('Class Code');

        item.nullFields = nullFields.join(', ');
        return nullFields.length > 0;
      });
  
      setFilteredInvList(filteredData);
    }

    const getInvestorOpportunity = (param = {}) => {
        setIsLoading(true);
        param.oppRid = scheme?.oppRid;
        apiInvestorOnboarding(param)
            .then((data) => {
              setIsLoading(false);
                setInvestorList(data);
                handleFilterInvList(data);
                // setFilteredInvList(
                //   data.investorOpportunities.filter(item => !item.consent || !item.fundStatus)
                // );
            })
            .catch((error) => {
              setIsLoading(false);
                enqueueSnackbar(error,SNACKBAR_ERROR);
            });
    }

    useEffect(() => {
        if(open){
            getInvestorOpportunity();
        }
    }, [open]);

    const downloadInvestorOnboarding = () => {
      setActionLoader(true);
      apiInvestorOnboardingFile({
          "oppRid": scheme?.oppRid
      }).then((data) => {
          setActionLoader(false);
          downloadFile(data);
          handleSave();
      })
      .catch((error) => {
          setActionLoader(false);
          enqueueSnackbar(error,SNACKBAR_ERROR);
      });
    }

  return (
    <DialogLayout
      open={open}
      title="Investor Onboarding"
      maxWidth={filteredInvList.length === 0 ? 'sm' : 'md'}
      actionLoader={actionLoader}
      closeAction={toggleDialog}
      negativeAction={
        {label: 'Cancel', onClick : toggleDialog}
      }
      positiveAction={{
          label: filteredInvList.length === 0 ? 'Download' : 'Download Anyway',
          onClick : downloadInvestorOnboarding}
    }>
      {isLoading ? <CircularProgress /> :
        (filteredInvList.length > 0 ?
            <Stack direction="column" spacing={2}>
            <p className="fs-large-regular w-75">
              Please complete the pending activities before proceeding further
            </p>

            <div className="d-flex gap-3 flex-column">
              {filteredInvList.map((data, key)=> (
                <>
                  <div className="d-flex gap-2 flex-column flex-grow-1" key={key}>
                    <h5 className="fs-med-semibold text-primary p-0 m-0">
                      {data.fullName}
                      {data.invCode && <span className="text-muted ms-2">( {data.invCode} )</span>}
                    </h5>
                    <p className="fs-normal-med p-0 m-0 ms-3">
                      <i className="text-danger">{data?.nullFields}</i> Missing.</p>
                  </div>
                  {key < filteredInvList.length - 1 && <Divider />}
                </>
              ))}
            </div>
          </Stack> : <Stack direction="column" spacing={2}>
            <p className="fs-large-regular w-75">
              click on download to proceeding further
            </p>
        </Stack>) }
    </DialogLayout>
  );
}

export default InvestorOnboarding;