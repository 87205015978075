import React, { useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import SearchIcon from "@mui/icons-material/SearchOutlined";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import {
  Divider,
  FormControlLabel,
  IconButton,
  InputLabel,
  ListItemText,
  Menu,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Skeleton,
  Tooltip,
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import {
  apiDeletePartner,
  apiGetSilentLoginToken,
  getPartners,
} from "../../utils/api/api";
import { useSnackbar } from "notistack";
import {
  PREVIEW_URL,
  RECORD_DISPLAY_LIMIT,
  SNACKBAR_ERROR,
  SNACKBAR_SUCCESS,
} from "../../utils/constants/constants";
import CloseIcon from "@mui/icons-material/Close";
import FilterListIcon from "@mui/icons-material/FilterList";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import AlertLayout from "../../components/layout/AlertLayout";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PartnerLogin from "./PartnerLogin";
import PartnerPassword from "./PartnerPassword";
import CircleIcon from "@mui/icons-material/Circle";
import PartnerModal from "./PartnerModal";
import { isSubAdmin } from "../../utils/StringUtil";

const Partner = () => {
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [partnerList, setPartnerList] = useState([]);
  const [editPartner, setEditPartner] = useState(null);
  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  const [alertLoader, setAlertLoader] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [anchorElSort, setAnchorElSort] = useState(null);
  const openSortMenu = Boolean(anchorElSort);
  const [sortPartner, setSortPartner] = useState(null);
  const [anchorElFilter, setAnchorElFilter] = useState(null);
  const openFilterMenu = Boolean(anchorElFilter);
  const [openPasswordDialog, setOpenPasswordDialog] = useState(false);
  const userType = process.env.REACT_APP_USER_TYPE;

  const [anchorElMap, setAnchorElMap] = useState({});
  const handleMenuOpen = (event, index) => {
    setAnchorElMap((prevState) => ({
      ...prevState,
      [index]: event.currentTarget,
    }));
  };
  const handleMenuClose = (index) => {
    setAnchorElMap((prevState) => ({
      ...prevState,
      [index]: null,
    }));
  };

  const handleSortPartner = (value) => {
    setSortPartner(value);
    setAnchorElSort(null);
  };

  const handleClose = () => {
    setOpen(false);
    setIsEdit(false);
  };

  const handleOpen = () => {
    setOpen(true);
    setEditPartner({});
  };

  const handleEdit = (id) => {
    setEditPartner(partnerList?.find((item) => item.partnerRid === id));
    setOpen(true);
    setIsEdit(true);
  };

  const handleSave = () => {
    setPage(1);
    getPartnerList();
    handleClose();
  };

  const toggleAlertDialog = () => {
    if (openAlertDialog) {
      setOpenAlertDialog(false);
    } else {
      setOpenAlertDialog(true);
    }
  };

  const handleAlertDialog = (params = {}) => {
    setAlertLoader(true);
    params.partnerRid = editPartner.partnerRid;
    params.userRid = editPartner.userRid;
    apiDeletePartner(params)
      .then((data) => {
        setAlertLoader(false);
        enqueueSnackbar(data, SNACKBAR_SUCCESS);
        toggleAlertDialog();
        setPage(1);
        getPartnerList();
      })
      .catch((error) => {
        setAlertLoader(false);
        enqueueSnackbar(error, SNACKBAR_ERROR);
      });
  };

  const getPartnerList = (param = {}) => {
    param.pageNumber = page;
    param.fullName = searchTerm.trim();
    param.recLimit = RECORD_DISPLAY_LIMIT;
    param.sort = sortPartner;
    setIsLoading(true);
    getPartners(param)
      .then((data) => {
        setIsLoading(false);
        setPartnerList(data.partnerMasterList);
        if (page === 1) {
          setTotalRecords(data.records);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const handlePageChange = (_event, value) => {
    setPage(value);
  };

  useEffect(() => {
    if (searchTerm.length < 3) {
      if (searchTerm.trim() === "") {
        getPartnerList();
      } else {
        return;
      }
    } else {
      getPartnerList();
    }
    setPage(1);
  }, [searchTerm]);

  useEffect(() => {
    getPartnerList();
  }, [page, sortPartner]);

  const deleteUser = (partnerDetails) => {
    setEditPartner(partnerDetails);
    toggleAlertDialog();
  };

  const openInvPreview = (userRid) => {
    apiGetSilentLoginToken(userRid).then((res) => {
      if (res.status) window.open(`${PREVIEW_URL}/${res.data.guid}`, "_blank");
    });
  };

  const [openPartnerLogin, setOpenPartnerLogin] = useState(false);
  const togglePartnerLogin = (index, id) => {
    if (openPartnerLogin) {
      setOpenPartnerLogin(false);
    } else {
      setEditPartner(partnerList?.find((item) => item.partnerRid === id));
      setOpenPartnerLogin(true);
      handleMenuClose(index);
    }
  };
  const handlePartnerLoginSave = () => {
    getPartnerList();
  };

  const togglePasswordDialog = (index, id) => {
    if (openPasswordDialog) {
      setOpenPasswordDialog(false);
    } else {
      setEditPartner(partnerList?.find((item) => item.partnerRid === id));
      setOpenPasswordDialog(true);
      handleMenuClose(index);
    }
  };

  return (
    <Layout title="Partners">
      <AlertLayout
        open={openAlertDialog}
        title="Are You Sure ?"
        actionLoader={alertLoader}
        closeAction={toggleAlertDialog}
        positiveAction={{ label: "Delete", onClick: handleAlertDialog }}
      >
        <p className="fs-large-medium p-0 m-0">
          This action cannot be undone. This will permanently delete.
        </p>
      </AlertLayout>

      <PartnerLogin
        open={openPartnerLogin}
        toggleDialog={togglePartnerLogin}
        partner={editPartner}
        handleSave={handlePartnerLoginSave}
      />

      <PartnerPassword
        open={openPasswordDialog}
        toggleDialog={togglePasswordDialog}
        partner={editPartner}
      />
      {userType === "ADMIN" && !isSubAdmin() && (
        <Button
          sx={{
            height: "64px",
            borderRadius: "50%",
            position: "fixed",
            bottom: "20px",
            right: "20px",
          }}
          onClick={handleOpen}
          variant="contained"
          aria-label="edit"
          color="secondary"
        >
          <AddIcon />
        </Button>
      )}

      <PartnerModal
        open={open}
        onClose={handleClose}
        isEdit={isEdit}
        partner={editPartner}
        handleSave={(partner) => handleSave(partner)}
      />

      <div className="p-4">
        <div
          className="d-flex flex-row justify-content-between bg-white p-3 mb-4"
          style={{ borderRadius: "5px" }}
        >
          <div className="d-flex align-items-center gap-2">
            <div
              className="search-input-btn d-flex flex-row justify-content-between rounded bg-white px-3"
              style={{ border: "1px solid #000" }}
            >
              <div className="my-auto">
                <SearchIcon sx={{ cursor: "pointer" }} />
              </div>
              <input
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="form-control custom-form-control border-0 bg-transparent fs-large-regular "
                placeholder="Search"
              />
              <div onClick={() => setSearchTerm("")} className="my-auto">
                <CloseIcon sx={{ cursor: "pointer" }} />
              </div>
            </div>
            <Tooltip title="Sort">
              <IconButton
                color="secondary"
                aria-controls={openSortMenu ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openSortMenu ? "true" : undefined}
                onClick={(e) => setAnchorElSort(e.currentTarget)}
              >
                <FilterListIcon />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ boxSizing: "small" }}
              id="basic-menu"
              anchorEl={anchorElSort}
              open={openSortMenu}
              onClose={() => setAnchorElSort(null)}
              MenuListProps={{ "aria-labelledby": "basic-button" }}
              PaperProps={{ style: { width: "250px" } }}
            >
              <p className="fs-normal-semibold text-primary px-3 my-2">
                Partner Name
              </p>
              <RadioGroup
                value={sortPartner}
                onChange={(e) => handleSortPartner(parseInt(e.target.value))}
              >
                <MenuItem className="d-flex justify-content-between align-items-center">
                  <p className="fs-normal-medium p-0 m-0">Sort By ASC</p>
                  <Radio size="24px" value="1" />
                </MenuItem>
                <MenuItem className="d-flex justify-content-between align-items-center">
                  <p className="fs-normal-medium p-0 m-0">Sort By DESC</p>
                  <Radio size="24px" value="2" />
                </MenuItem>
              </RadioGroup>
              <Divider />
              <MenuItem onClick={() => handleSortPartner(null)}>
                <p className="fs-normal-medium p-0 m-0">Clear</p>
              </MenuItem>
            </Menu>
          </div>

          <div className="justify-content-evenly d-flex align-items-center">
            <Pagination
              color="secondary"
              size="small"
              siblingCount={0}
              onChange={handlePageChange}
              count={
                totalRecords < RECORD_DISPLAY_LIMIT
                  ? 1
                  : Math.ceil(totalRecords / RECORD_DISPLAY_LIMIT)
              }
              page={page}
            />
          </div>
        </div>

        <div className="bg-white py-2 px-3" style={{ borderRadius: "5px" }}>
          {isLoading ? (
            [...Array(RECORD_DISPLAY_LIMIT)].map((_e, index) => (
              <Skeleton
                animation="wave"
                key={index}
                style={{ marginBottom: 6 }}
                variant="rectangle"
                height={40}
              />
            ))
          ) : (
            <div>
              {partnerList?.length > 0 ? (
                <table
                  className="table table-striped table-borderless w-100"
                  id="order-table"
                >
                  <thead>
                    <tr className="fs-normal-semibold text-center">
                      {!isSubAdmin() && (
                        <th scope="col">
                          <p className="m-0">Actions</p>
                        </th>
                      )}
                      <th scope="col">
                        <p className="m-0">Partner</p>
                      </th>
                      <th scope="col">
                        <p className="m-0">Mobile</p>
                      </th>
                      <th scope="col">
                        <p className="m-0">Email</p>
                      </th>
                      <th scope="col"></th>
                    </tr>
                  </thead>

                  <tbody className="bg-table-blue">
                    {partnerList?.map((item, index) => {
                      return (
                        <tr
                          className="fs-normal-medium text-center"
                          key={item.partnerRid}
                        >
                          {!isSubAdmin() && (
                          <td className="p-0">
                            
                              <div className="d-flex">
                                <IconButton
                                  onClick={() => handleEdit(item.partnerRid)}
                                  aria-label="edit"
                                >
                                  <EditIcon className="text-blue" />
                                </IconButton>
                              </div>                         
                          </td>
                           )}
                          <td className="pt-3">
                            <div className="d-flex gap-1 align-items-center">
                              {item.loginEnabled ? (
                                <CircleIcon
                                  sx={{ fontSize: "10px", color: "#008000" }}
                                />
                              ) : (
                                <CircleIcon
                                  sx={{ fontSize: "10px", color: "#D3D3D3" }}
                                />
                              )}
                              <span>{item.fullName}</span>
                            </div>
                          </td>
                          <td className="pt-3">
                            {item.countryCode} {item.mobile}
                          </td>
                          <td className="pt-3">
                            <div className="d-flex gap-1 align-items-center">
                              <span>{item.email}</span>
                            </div>
                          </td>
                          {!isSubAdmin() && (
                            <td className="pt-0">
                              <IconButton
                                id="basic-button"
                                aria-controls={
                                  Boolean(anchorElMap[index])
                                    ? "basic-menu"
                                    : undefined
                                }
                                aria-haspopup="true"
                                aria-expanded={
                                  Boolean(anchorElMap[index])
                                    ? "true"
                                    : undefined
                                }
                                onClick={(e) => handleMenuOpen(e, index)}
                              >
                                <MoreVertIcon fontSize="small" />
                              </IconButton>
                              <Menu
                                id="basic-menu"
                                anchorEl={anchorElMap[index]}
                                open={Boolean(anchorElMap[index])}
                                elevation={1}
                                onClose={() => handleMenuClose(index)}
                                MenuListProps={{
                                  "aria-labelledby": "basic-button",
                                }}
                                color="secondary"
                              >
                                <MenuItem
                                  className="fs-med-regular"
                                  onClick={() =>
                                    togglePartnerLogin(index, item.partnerRid)
                                  }
                                >
                                  Partner Login
                                </MenuItem>
                                <MenuItem
                                  className="fs-med-regular"
                                  onClick={() => {
                                    togglePasswordDialog(
                                      index,
                                      item.partnerRid
                                    );
                                  }}
                                >
                                  Change Password
                                </MenuItem>
                                <MenuItem
                                  className="fs-med-regular text-danger"
                                  onClick={() => {
                                    deleteUser(item);
                                    handleMenuClose(index);
                                  }}
                                >
                                  Delete Partner
                                </MenuItem>
                                
                              </Menu>
                            </td>
                          )}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              ) : (
                <p>No Data..</p>
              )}
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Partner;
