import Layout from "../../components/layout/Layout";
import React, { useEffect, useState } from "react";
import Pagination from "@mui/material/Pagination";
import { IconButton, Skeleton} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { getQueries } from "../../utils/api/api";
import QueriesModal from "./QueriesModal";
import { RECORD_DISPLAY_LIMIT, SNACKBAR_ERROR} from "../../utils/constants/constants";
import { useSnackbar } from 'notistack'
import LaunchIcon from '@mui/icons-material/Launch';


const Queries = () => {

    const [queriesList, setQueriesList] = useState([]);
    const [open, setOpen] = useState(false);
    // const [isEdit, setIsEdit] = useState(false);
    const [editQuery, setEditQuery] = useState(null);
    const [totalRecords, setTotalRecords] = useState(0);
    const [page, setPage] = useState(1);
    const [isLoading,setIsLoading] = useState(false)
    const { enqueueSnackbar } = useSnackbar()


    const handleClose = () => {
        setOpen(false)
        setEditQuery(null)
    }

    useEffect(() => {
        getQueriesList({ pageNumber: page })
    }, [page])

    const handleEdit = (id) => {
        setEditQuery(queriesList.find((item) => item.queryRid === id))
        setOpen(true);
        // setIsEdit(true);
    };

    const getQueriesList = (params = {}) => {
        setIsLoading(true)
        params.recLimit = RECORD_DISPLAY_LIMIT
        getQueries(params)
            .then((data) => {
                setIsLoading(false)
                setQueriesList(data.queriesList)
                if(page === 1){
                    setTotalRecords(data.records)
                }
            })
            .catch((e) => {
                setIsLoading(false)
                enqueueSnackbar(e,SNACKBAR_ERROR);
            })
    }

    const handlePageChange = (_event, value) => {
        setPage(value)
    }

    useEffect(() => {
        getQueriesList()
    }, [])

    return (
        <Layout title="Queries">
            <QueriesModal
                open={open}
                onClose={handleClose}
                query={editQuery}
            />

            <div className="p-4">
                <div className="d-flex flex-row justify-content-between bg-white p-3 mb-4" style={{borderRadius: '5px'}}>
                    <div className="search-input-btn d-flex flex-row justify-content-between border-0 rounded px-3"></div>
                    <div className="justify-content-evenly d-flex align-items-center">
                        <Pagination color="secondary" size="small" siblingCount={0} onChange={handlePageChange} count={totalRecords < RECORD_DISPLAY_LIMIT ? 1 : Math.ceil(totalRecords / RECORD_DISPLAY_LIMIT)} page={page} />
                    </div>
                </div>

                <div className="bg-white py-2 px-3" style={{borderRadius: '5px'}}>
                {
                    isLoading ? (
                        [...Array(RECORD_DISPLAY_LIMIT)].map((_e, index) => (
                            <Skeleton
                                animation="wave"
                                key={index}
                                style={{ marginBottom: 6 }}
                                variant="rectangle"
                                height={40}
                            />
                        ))

                    ) : (
                        <div>
                            <table
                                className="table table-striped table-borderless w-100"
                                id="order-table"
                            >
                                <thead>
                                    <tr className="fs-normal-semibold text-center">
                                        <th scope="col">
                                            <p className="mb-2">Actions</p>
                                        </th>
                                        {/* <th scope="col">
                                    <p className="m-0">id</p>
                                </th> */}
                                        <th scope="col">
                                            <p className="mb-2">Name</p>
                                        </th>
                                        <th scope="col">
                                            <p className="mb-2">Email</p>
                                        </th>
                                        <th scope="col">
                                            <p className="mb-2">Phone</p>
                                        </th>
                                        <th scope="col">
                                            <p className="mb-2">Interested In</p>
                                        </th>
                                    </tr>
                                </thead>
                                {
                                    queriesList ? (
                                        <tbody className="bg-table-blue">
                                            {queriesList.map((item) => {
                                                return (
                                                    <tr
                                                        className="fs-normal-medium text-center"
                                                        key={item.queryRid}
                                                    >
                                                        <td className="p-0">
                                                            <div className="d-flex">
                                                                <IconButton
                                                                    onClick={() => handleEdit(item.queryRid)}
                                                                    aria-label="edit"
                                                                >
                                                                    <LaunchIcon className="text-blue" />
                                                                </IconButton>
                                                                {/* <IconButton aria-label="delete">
                                                            <DeleteOutlineIcon className="text-danger" />
                                                        </IconButton> */}
                                                            </div>
                                                        </td>
                                                        {/* <td className="pt-3">{item.contactRid}</td> */}
                                                        <td className="pt-3">{item.name}</td>
                                                        <td className="pt-3">{item.email}</td>
                                                        <td className="pt-3">{item.phone}</td>
                                                        <td className="pt-3">{item.interestedIn}</td>

                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    ) : (
                                        <p>No Data..</p>
                                    )}
                            </table>
                        </div>
                    )
                }
            </div>
            </div>

            
            
        </Layout>
    );
}

export default Queries;