import React, {useState,useEffect} from 'react';
import DrawerLayout, { ScoreBoard } from '../../components/layout/DrawerLayout';
import { Box, CircularProgress, Stack } from '@mui/material';
import { apiFunds, apiInvestorOpportunity } from '../../utils/api/api';
import { SNACKBAR_ERROR } from "../../utils/constants/constants";
import { toLocalePrice } from '../../utils/StringUtil';
import { useSnackbar } from 'notistack';

const InvestorFeeGenerated = ({ open, toggleDrawer, investor }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [invRid, setInvRid] = useState(null);
    const [invName, setInvName] = useState('null');
    const [invCode, setInvCode] = useState('null');
    const [upFront, setUpFront] = useState(0.00);
    const [gst, setGST] = useState(0.00);
    const [totalAdditionalCharges, setTotalAdditionalCharges] = useState(0.00);
    const [patnerFee, setPatnerFee] = useState(0.00);
    const [netIncome, setNetIncome] = useState(0.00);
    const [investorList, setInvestorList] = useState([]);
    const { enqueueSnackbar } = useSnackbar();

    const getInvestorOpportunity = (param = {}) => {
      setIsLoading(true);
      param.invRid = investor?.invRid;
      param.oppRid = null;
      param.drawdown = null;
      param.consent = null;
      param.fundStatus = 1;
      apiInvestorOpportunity(param)
          .then((data) => {
              setIsLoading(false);
              setInvestorList(data.investorOpportunities);
              const upfrontTotal = data.investorOpportunities.reduce((val, obj) => val + obj.upfrontAmount, 0);
              const gstTotal = data.investorOpportunities.reduce((val, obj) => val + obj.gst, 0);
              const acTotal = data.investorOpportunities.reduce((val, obj) => val + obj.aditionalCharges, 0);
              const ppTotal = data.investorOpportunities.reduce((val, obj) => val + obj.partnerFee, 0);
              const netIncTotal = data.investorOpportunities.reduce((val, obj) => val + obj.netIncome, 0);
              setUpFront(toLocalePrice(upfrontTotal));
              setGST(toLocalePrice(gstTotal));
              setTotalAdditionalCharges(toLocalePrice(acTotal));
              setPatnerFee(toLocalePrice(ppTotal));
              setNetIncome(toLocalePrice(netIncTotal));
          })
          .catch((error) => {
              setIsLoading(false);
              enqueueSnackbar(error,SNACKBAR_ERROR);
          });
    }

    useEffect(() => {
      if(open){
        getInvestorOpportunity();
        setInvRid(investor ? (investor.invRid || null) : null);
        setInvName(investor ? (investor.fullName || null) : null);
        setInvCode(investor ? (investor.invCode || null) : null);
      }
    }, [open]);

  return (
    <DrawerLayout
      open={open}
      title="Fee Generated"
      closeAction={toggleDrawer}
      positiveAction={
        {label: 'Close', onClick : toggleDrawer}
      }
    >
        <Stack sx={{ marginBottom: "2rem" }} direction="row" spacing={2}>
          <Box sx={{flex: 1}}>
            <p className="section-label mb-1" style={{fontSize: '12px'}}>Investor</p>
            <p className="fs-large-medium p-0 m-0">
              {invName}
              <span className="fs-small-regular text-muted ms-2">{invCode}</span>
            </p>
          </Box>
        </Stack>
        <Stack sx={{ marginBottom: "2rem" }} direction="row" spacing={2}>
            <ScoreBoard
                label="Total Upfront Fee"
                value={upFront}
                flex={1}
            />
            <ScoreBoard
                label="Total Statutory Fees"
                value={gst}
                flex={1}
            />
            <ScoreBoard
              label="Total Additional Charges"
              value={totalAdditionalCharges}
              flex={1}
            />
            <ScoreBoard
                label="Partner Payment"
                value={patnerFee}
                flex={1}
            />
            <ScoreBoard
                label="Net Income"
                value={netIncome}
                flex={1}
            />
        </Stack>
        <Stack sx={{ marginBottom: "2rem" }} direction="column" className={investorList.length === 0 ? 'd-none' : ''} spacing={2}>
        <p className="section-label p-0 m-0">Scheme Wise</p>
        
        <Stack>
          {isLoading ? <CircularProgress /> : 
          <table className="table table-striped table-borderless w-100 p-0 m-0">
            <thead>
              <tr className="fs-normal-semibold text-center">
                <th scope="col">
                  <p className="mb-2">Scheme</p>
                </th>
                <th scope="col" colspan="2">
                  <p className="mb-2 text-center">Upfront</p>
                </th>
                <th scope="col">
                  <p className="mb-2 text-end">Statutory Fees</p>
                </th>
                <th scope="col">
                  <p className="mb-2 text-end">Additional Charges</p>
                </th>
                <th scope="col">
                  <p className="mb-2 text-end">Partner Payment</p>
                </th>
                <th scope="col">
                  <p className="mb-2 text-end">Net Income</p>
                </th>
              </tr>
            </thead>
            <tbody>
              {investorList.map((data, key)=>{
                return(
                  <tr className="fs-normal-medium text-center" key={key}>
                    <td className="pt-2">{data.oppTitle}</td>
                    <td className="pt-2 text-end">
                      {toLocalePrice(data.upfrontAmount)}
                    </td>
                    <td className="pt-2 text-start">
                      <span className="fs-small-regular text-muted">{toLocalePrice(data.upfront)}%</span>
                    </td>
                    <td className="pt-2 text-end">{toLocalePrice(data.gst)}</td>
                    <td className="pt-2 text-end">
                      {toLocalePrice(data.additionalCharges)}
                    </td>
                    <td className="pt-2 text-end">{toLocalePrice(data.partnerFee)}</td>
                    <td className="pt-2 text-end">{toLocalePrice(data.netIncome)}</td>
                  </tr>
                );
              })}
            </tbody>
          </table> }
        </Stack>
      </Stack>
    </DrawerLayout>
  );
}

export default InvestorFeeGenerated;