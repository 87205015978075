import React, { useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import SearchIcon from "@mui/icons-material/SearchOutlined";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import {
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Skeleton,
  Tooltip,
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import { apiCompanyUpdates, apiUpdateCompanyUpdate } from "../../utils/api/api";
import { useSnackbar } from "notistack";
import {
  RECORD_DISPLAY_LIMIT,
  SNACKBAR_ERROR,
  SNACKBAR_SUCCESS,
} from "../../utils/constants/constants";
import CloseIcon from "@mui/icons-material/Close";

import FilterListIcon from "@mui/icons-material/FilterList";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import CompanyUpdateDrawer from "./CompanyUpdateDrawer";
import { formatDateTime, isSubAdmin } from "../../utils/StringUtil";
import AlertLayout from "../../components/layout/AlertLayout";

const CompanyUpdates = () => {
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [updatesList, setUpdatesList] = useState([]);
  const [editUpdate, setEditUpdate] = useState(null);
  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  const [alertLoader, setAlertLoader] = useState(false);

  const [totalRecords, setTotalRecords] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const userType = process.env.REACT_APP_USER_TYPE;

  const handleClose = () => {
    setOpen(false);
    setIsEdit(false);
  };

  const handleOpen = () => {
    setOpen(true);
    setEditUpdate({});
  };

  const handleSave = () => {
    setPage(1);
    getCompanyUpdates();
    handleClose();
  };

  const toggleAlertDialog = () => {
    if (openAlertDialog) {
      setOpenAlertDialog(false);
    } else {
      setOpenAlertDialog(true);
    }
  };
  const handleAlertDialog = () => {
    setAlertLoader(true);
    editUpdate.status = 0;
    apiUpdateCompanyUpdate(editUpdate)
      .then((data) => {
        setAlertLoader(false);
        enqueueSnackbar(data, SNACKBAR_SUCCESS);
        toggleAlertDialog();
        setPage(1);
        getCompanyUpdates();
      })
      .catch((error) => {
        setAlertLoader(false);
        enqueueSnackbar(error, SNACKBAR_ERROR);
      });
  };

  const handleEdit = (id) => {
    setEditUpdate(updatesList.find((item) => item.cuRid === id));
    setOpen(true);
    setIsEdit(true);
  };

  const getCompanyUpdates = (param = {}) => {
    param.pageNumber = page;
    param.name = searchTerm.trim();
    param.recLimit = RECORD_DISPLAY_LIMIT;
    setIsLoading(true);
    apiCompanyUpdates(param)
      .then((data) => {
        setIsLoading(false);
        setUpdatesList(data.companyUpdatesList);
        if (page === 1) {
          setTotalRecords(data.records);
        }
      })
      .catch((e) => {
        setIsLoading(false);
        enqueueSnackbar(e, SNACKBAR_ERROR);
      });
  };

  const handlePageChange = (_event, value) => {
    setPage(value);
  };

  useEffect(() => {
    if (searchTerm.length < 3) {
      if (searchTerm.trim() === "") {
        getCompanyUpdates();
      } else {
        return;
      }
    } else {
      getCompanyUpdates();
    }
    setPage(1);
  }, [searchTerm]);

  useEffect(() => {
    getCompanyUpdates();
  }, [page]);

  const deleteUpdate = (update) => {
    setEditUpdate(update);
    toggleAlertDialog();
  };

  return (
    <Layout title="Company Updates">
      <AlertLayout
        open={openAlertDialog}
        title="Are You Sure ?"
        actionLoader={alertLoader}
        closeAction={toggleAlertDialog}
        positiveAction={{ label: "Delete", onClick: handleAlertDialog }}
      >
        <p className="fs-large-medium p-0 m-0">
          This action cannot be undone. This will permanently delete.
        </p>
      </AlertLayout>
      {userType === "ADMIN" && !isSubAdmin() && (
        <Button
          sx={{
            height: "64px",
            borderRadius: "50%",
            position: "fixed",
            bottom: "20px",
            right: "20px",
          }}
          onClick={handleOpen}
          variant="contained"
          aria-label="edit"
          color="secondary"
        >
          <AddIcon />
        </Button>
      )}

      <CompanyUpdateDrawer
        open={open}
        onClose={handleClose}
        isEdit={isEdit}
        companyUpdateRid={editUpdate?.cuRid}
        handleSave={handleSave}
      />

      <div className="p-4">
        <div
          className="d-flex flex-row justify-content-between bg-white p-3 mb-4"
          style={{ borderRadius: "5px" }}
        >
          <div className="d-flex align-items-center gap-2">
            <div
              className="search-input-btn d-flex flex-row justify-content-between rounded bg-white px-3"
              style={{ border: "1px solid #000" }}
            >
              <div className="my-auto">
                <SearchIcon sx={{ cursor: "pointer" }} />
              </div>
              <input
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="form-control custom-form-control border-0 bg-transparent fs-large-regular "
                placeholder="Search"
              />
              <div onClick={() => setSearchTerm("")} className="my-auto">
                <CloseIcon sx={{ cursor: "pointer" }} />
              </div>
            </div>
          </div>
          <div className="justify-content-evenly d-flex align-items-center">
            <Pagination
              color="secondary"
              size="small"
              siblingCount={0}
              onChange={handlePageChange}
              count={
                totalRecords < RECORD_DISPLAY_LIMIT
                  ? 1
                  : Math.ceil(totalRecords / RECORD_DISPLAY_LIMIT)
              }
              page={page}
            />
          </div>
        </div>

        <div className="bg-white py-2 px-3" style={{ borderRadius: "5px" }}>
          {isLoading ? (
            [...Array(RECORD_DISPLAY_LIMIT)].map((_e, index) => (
              <Skeleton
                animation="wave"
                key={index}
                style={{ marginBottom: 6 }}
                variant="rectangle"
                height={40}
              />
            ))
          ) : (
            <div>
              {updatesList.length > 0 ? (
                <table
                  className="table table-striped table-borderless w-100"
                  id="order-table"
                >
                  <thead>
                    <tr className="fs-normal-semibold text-center">
                      <th scope="col">
                        <p className="m-0">Actions</p>
                      </th>
                      <th scope="col">
                        <p className="m-0">Company</p>
                      </th>
                      <th scope="col">
                        <p className="m-0">Title</p>
                      </th>
                      <th scope="col">
                        <p className="m-0">Author</p>
                      </th>
                      <th scope="col">
                        <p className="m-0">Publish Date</p>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-table-blue">
                    {updatesList.map((item) => {
                      return (
                        <tr
                          className="fs-normal-medium text-center"
                          key={item.cuRid}
                        >
                          <td className="p-0">
                            <div className="d-flex">
                              <IconButton
                                onClick={() => handleEdit(item.cuRid)}
                                aria-label="edit"
                              >
                                <EditIcon className="text-blue" />
                              </IconButton>
                              <IconButton
                                aria-label="delete"
                                onClick={() => deleteUpdate(item)}
                              >
                                <DeleteOutlineIcon className="text-danger" />
                              </IconButton>
                            </div>
                          </td>
                          <td className="pt-3">{item.companyName}</td>
                          <td className="pt-3">{item.title}</td>
                          <td className="pt-3">{item.authorName}</td>
                          <td className="pt-3">
                            {formatDateTime(item.publishDate, false)}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              ) : (
                <p>No Data..</p>
              )}
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default CompanyUpdates;
