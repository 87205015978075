import React, { useEffect,useState } from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import 'dayjs/locale/en-gb';
import { Autocomplete, Badge, Checkbox, Chip, CircularProgress, Divider, FormControl, FormGroup, FormLabel, IconButton, InputLabel, MenuItem, Select, Switch, Tooltip } from "@mui/material";
import { useSnackbar } from 'notistack';
import dayjs from 'dayjs';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import ArrowRightAltRoundedIcon from '@mui/icons-material/ArrowRightAltRounded';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import styles from "../projects/Project.module.css";
import { RemoveCircle } from "@mui/icons-material";
import Card from '@mui/material/Card';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { DatePicker } from "@mui/x-date-pickers";
import { apiCompanyUpdateDetails, apiCreateInvestor, apiMca, apiSaveCompanyUpdate, apiTags, apiUpdateCompanyUpdate, apiUpdateInvestor, getCompanies, getDataDictionaryValues, getInvestments, getMasterClass, getOpportunity, getTransactions, uploadFile, uploadImage } from "../../utils/api/api";
import { BASE_URL, DD_TYPE_AUTHOR, DD_TYPE_BANK_TYPE, DD_TYPE_INVESTOR_TYPE, DD_TYPE_SOURCE_TYPE, DD_TYPE_TAGS, SNACKBAR_ERROR, SNACKBAR_SUCCESS, SNACKBAR_WARNING, TINY_MCE_API_KEY } from "../../utils/constants/constants";

import { containsOnlyNumbers, isNonZeroNumber, isValidPAN } from "../../utils/Validator";
import DrawerLayout, { ScoreBoard } from "../../components/layout/DrawerLayout";
import { Editor } from '@tinymce/tinymce-react';

const CompanyUpdateDrawer = ({ open, onClose, isEdit, companyUpdateRid, handleSave }) => {
  const [cuRid, setCuRid] = useState(null);
  const [compRid, setCompRid] = useState(null);
  const [companyList, setCompanyList] = useState([]);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [author, setAuthor] = useState(null);
  const [authorName, setAuthorName] = useState(null);
  const [authorList, setAuthorList] = useState([]);
  const [publishDate, setPublishDate] = useState(
    new Date().toISOString().split('T')[0]
  );
  const [attachments, setAttachments] = useState([]);
  const [tagsList, setTagsList] = useState([]);
  const [tagObj, setTagObj] = useState(null);
  const [selTagsList, setSelTagsList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isFileLoading, setIsFileLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [actionLoader, setActionLoader] = useState(false);

  const [companyUpdate, setCompanyUpdate] = useState({});

  const handlePublishDate = (selectedDate) => {
    const date = new Date(selectedDate?.$d);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const formattedDate = `${year}-${month}-${day}`;
    // const formattedDate = date.toISOString().split('T')[0];
    setPublishDate(formattedDate);
  }

  const handleAutoCompleteFS = (value, type) => {
    value = value.trim();

    if(type === DD_TYPE_AUTHOR){
      const ddData = authorList.find((item) =>
        item.ddValue.trim() === value
      );
      if (ddData) {
        setAuthorName(null);
        setAuthor(ddData.ddIndex);
      } else {
        setAuthorName(value);
        setAuthor(null);
      }
    }
    if(type === DD_TYPE_TAGS){
      const ddData = tagsList.find((item) =>
        item.ddValue.trim() === value
      );
      if (ddData) {
        setTagObj({
          "ddValue": ddData.ddValue,
          "ddIndex": ddData.ddIndex
        });
      } else {
        setTagObj({
          "ddValue": value,
          "ddIndex": 0
        });
      }
    }
  }

  const openDocExplorer = () => {
    document.getElementById('input-file').click()
  }
  const uploadAttachments = (e) => {
    setIsFileLoading(true);
    uploadFile(e.target.files[0])
      .then((res) => {
        setIsFileLoading(false);
        setAttachments([...attachments, res.data]);
      })
      .catch((e) => {
        setIsFileLoading(false);
        enqueueSnackbar(e, SNACKBAR_ERROR);
      });
  }
  const deleteAttachment = (index) => {
    const newList = [...attachments];
    newList.splice(index, 1);
    setAttachments(newList);
  }

  const handleAddtag = () => {
    if(!tagObj || tagObj?.ddValue === ''){
      
    } else {
      setSelTagsList([...selTagsList, tagObj]);
      // setTagObj(null);
    }
  }
  const handleDeleteTag = (index) => {
    const newList = [...selTagsList];
    newList.splice(index, 1);
    setSelTagsList(newList);
  }

  const getCompanyList = (param = {}) => {
    getCompanies(param)
      .then((data) => {
        setCompanyList(data.companyList);
      }).catch((e) => {
        console.log(e);
      });
  }

  const getDataDictionary = () => {
    getDataDictionaryValues()
      .then((data) => {
        const authorTypes = data.filter((item) =>
          item.ddType === DD_TYPE_AUTHOR
        );
        const tagTypes = data.filter((item) =>
          item.ddType === DD_TYPE_TAGS
        );

        setAuthorList(authorTypes);
        setTagsList(tagTypes);
      })
      .catch((e) => {
        console.log(e);
      })
  }

  const getTags = (tags) => {
    apiTags({
      "tags": tags
    })
      .then((data) => {
        setSelTagsList(data);
      }).catch((e) => {
        console.log(e);
      });
  }

  const getCompanyUpdateDetails = (param = {}) => {
    param.cuRid = companyUpdateRid;
    apiCompanyUpdateDetails(param)
      .then((data) => {
        setCompanyUpdate(data);
      }).catch((e) => {
        enqueueSnackbar(e,SNACKBAR_ERROR)
      })
  }

  useEffect(() => {
    setCuRid(isEdit ? companyUpdate.cuRid : null);
    setTitle(isEdit ? companyUpdate.title : '');
    setCompRid(isEdit ? companyUpdate.compRid : null);
    setAuthor(isEdit ? companyUpdate.author : null);
    setPublishDate(isEdit ? companyUpdate.publishDate : publishDate);
    setDescription(isEdit ? companyUpdate.description : '');
    setAttachments(isEdit ? (companyUpdate.attachments ? JSON.parse(companyUpdate.attachments) : []) : []);
    setSelTagsList([]);
    if(isEdit){
      companyUpdate.tags !== null && getTags(companyUpdate.tags);
    }
  }, [companyUpdate]);

  useEffect(() => {
    if(open){
      if(isEdit){
        getCompanyUpdateDetails();
      }
    }
    getCompanyList();
    getDataDictionary();

    setCuRid(null);
    setTitle('');
    setCompRid(null);
    setAuthor(null);
    setPublishDate(publishDate);
    setDescription('');
    setAttachments([]);
    setSelTagsList([]);
  }, [open]);

  const saveCompanyUpdate = (reqBody) => {
    if(isEdit){
      setActionLoader(true);
        apiUpdateCompanyUpdate(reqBody)
        .then((data) => {
            setActionLoader(false);
            enqueueSnackbar(data,SNACKBAR_SUCCESS);
            handleSave();
        })
        .catch((error) => {
          setActionLoader(false);
          enqueueSnackbar(error,SNACKBAR_ERROR);
        });
    } else {
      setActionLoader(true);
        apiSaveCompanyUpdate(reqBody)
        .then((data) => {
            setActionLoader(false);
            enqueueSnackbar(data,SNACKBAR_SUCCESS);
            handleSave();
        })
        .catch((error) => {
          setActionLoader(false);
          enqueueSnackbar(error,SNACKBAR_ERROR);
        });
    }
  }

  const onSubmit = () => {
    const reqBody = {
      "cuRid": cuRid,
      "compRid": compRid,
      "title": title,
      "description": description,
      "author": author, 
      "authorName": authorName,
      "attachments": attachments,
      "status": 1,
      "publishDate": publishDate,
      "tagsList": selTagsList
    }

    if(title.trim() === '' || description.trim() === ''){
      enqueueSnackbar("Please fill in the required fields", SNACKBAR_WARNING);
    } else if(compRid === null){
      enqueueSnackbar("Please select company", SNACKBAR_WARNING);
    } else if(isFileLoading){
      enqueueSnackbar("File is uploading!! Please wait", SNACKBAR_WARNING);
    } else {
      // console.log(reqBody);
      saveCompanyUpdate(reqBody);
    }
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
        <DrawerLayout
          open={open}
          disableEnforceFocus={true}
          title={isEdit ? 'Edit Company Update' : 'Add Company Update'}
          actionLoader={actionLoader}
          closeAction={onClose}
          negativeAction={
              {label: 'Cancel', onClick : onClose}
          }
          positiveAction={
              {label: 'Save', onClick : onSubmit}
          }
        >
          <Stack sx={{ marginBottom: "1rem" }} direction="row" spacing={2}>
            <TextField
              sx={{ flex: 1 }}
              id="outlined-basic"
              name="title"
              label="Title"
              variant="outlined"
              value={title}
              fullWidth
              onChange={(e) => setTitle(e.target.value)}
            />
          </Stack>
          <Stack sx={{ marginBottom: "1rem" }} direction="row" spacing={2}>
            <Autocomplete
              sx={{ flex: 1 }}
              id="outlined-basic"
              variant="outlined"
              options={companyList}
              getOptionLabel={(option) => option.title}
              value={companyList.find((option) => option.compRid === compRid) || null}
              fullWidth
              onChange={(e, v) => setCompRid(v ? v.compRid : null)}
              renderInput={(params) => <TextField {...params} label="Company" />}
              renderOption={(props, option) => (
                <li {...props} key={option.compRid}>{option.title}</li>
              )}
            />
            <Autocomplete
              freeSolo
              sx={{ flex: 1 }}
              id="outlined-basic"
              variant="outlined"
              options={authorList}
              getOptionLabel={(option) => option.ddValue}
              value={authorList.find((option) => option.ddIndex === author) || null}
              fullWidth
              onInputChange={(e, v) => {
                handleAutoCompleteFS(v, DD_TYPE_AUTHOR);
              }}
              renderInput={(params) => <TextField {...params} label="Author (optional)" />}
            />
          </Stack>
          <Stack sx={{ marginBottom: "1rem" }} direction="row" spacing={2}>
            <Stack sx={{ flex: 1 }}>
              <DatePicker
                id="outlined-basic"
                name="publishDate"
                label="Publish Date"
                variant="outlined"
                value={publishDate}
                fullWidth
                onChange={(v) => handlePublishDate(v)}
                renderInput={(params) => <TextField {...params} />}
              />
            </Stack>
            <Box sx={{ flex: 1 }}></Box>
          </Stack>
          <Stack sx={{ marginBottom: "1rem" }} direction="column" spacing={2}>
            <Editor
              value={description}
              apiKey={TINY_MCE_API_KEY}
              init={{
                height: 400,
                menubar: 'file edit view insert format tools table help',
                // branding: false,
                plugins:
                    'advlist autolink lists link image charmap print preview anchor ' +
                    'searchreplace visualblocks code fullscreen insertdatetime media table paste wordcount',
                toolbar:
                    'undo redo | formatselect | bold italic underline strikethrough | ' +
                    'alignleft aligncenter alignright alignjustify | ' +
                    'bullist numlist outdent indent | ' +
                    'link image media | ' +
                    'code | ' +
                    'table | ' +
                    'removeformat | ' +
                    'fullscreen',
                  images_upload_handler: async blobInfo => {
                      return new Promise((resolve, reject) => {
                          uploadImage(blobInfo.blob())
                              .then((res) => {
                                  const { path } = res.data;
                                  resolve(`${BASE_URL}/${path}`);
                              })
                              .catch((err) => {
                                  enqueueSnackbar(err, SNACKBAR_ERROR);
                                  reject(err);
                              });
                      });
                  },
                content_style:
                    'body { font-family: Arial, sans-serif; font-size: 16px; line-height: 1.6; }',
              }}
              onEditorChange={(c,e)=>setDescription(c)}
            />
            {/* <div>{description}</div> */}
          </Stack>
          <Divider />
          <Stack sx={{ marginBottom: "1rem", marginTop: "1rem" }} direction="column" spacing={2}>
            <Stack sx={{ flex: 1 }} direction="row" spacing={2}>
              <Autocomplete
                freeSolo
                sx={{ flex: 1 }}
                id="outlined-basic"
                variant="outlined"
                options={tagsList}
                getOptionLabel={(option) => option.ddValue}
                value={tagsList.find((option) => option.ddIndex === tagObj?.ddIndex) || null}
                fullWidth
                onInputChange={(e, v) => {
                    handleAutoCompleteFS(v, DD_TYPE_TAGS);
                }}
                renderInput={(params) => <TextField {...params} label="Tag" />}
              />
              <Button variant="text" color="secondary" onClick={handleAddtag}>
                Add
              </Button>
            </Stack>
            <Stack sx={{ flex: 1, flexWrap: 'wrap', gap: 1 }} direction="row">
              {selTagsList.map((data, key)=>{
                return(
                  <Chip color="secondary" label={data.ddValue} onDelete={()=>handleDeleteTag(key)} key={key} />
                );
              })}
            </Stack>
          </Stack>
          <Divider />
          <Stack sx={{ marginBottom: "1rem", marginTop: "1rem" }} direction="column" spacing={2}>
            <p className="section-label p-0 m-0">Attachments</p>
            <input
              className="d-none"
              id="input-file"
              type="file"
              accept=".pdf"
              onClick={(e) => {
                  e.target.value = null;
              }}
              onChange={(e) => {
                  uploadAttachments(e);
              }}
            />
            <Stack sx={{ flex: 1, alignItems: 'center', flexWrap: 'wrap', gap: 1 }} direction="row">
              {attachments.map((data, key)=>{
                return(
                  <Tooltip title={data.actualFileName} key={key}>
                    <Badge
                        badgeContent={<RemoveCircle fontSize="10" onClick={()=>deleteAttachment(key)} />}
                        color="error">
                      <AttachFileIcon color="secondary"/>
                    </Badge>
                  </Tooltip>
                );
              })}
              {isFileLoading ? <CircularProgress color="secondary" /> : <IconButton color="secondary" onClick={() => openDocExplorer()}>
                <AddIcon />
              </IconButton>}
            </Stack>
          </Stack>
        </DrawerLayout>
    </LocalizationProvider>
  );
}

export default CompanyUpdateDrawer;