import { Dialog } from '@mui/material';
import { Box, Button, Divider, IconButton } from '@mui/material';
import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import CancelIcon from '@mui/icons-material/Cancel';

const dialogStyles = makeStyles((theme)=> ({
    dialog: {
      width: '100%',
      flexShrink: 0,
    //   '& .MuiDrawer-paper': {
    //     width: '40%',
    //     boxSizing: 'border-box',
    //     // paddingTop: '60px',
    //     // paddingBottom: '60px',
    //   },
    },
    header: {
      position: 'sticky',
      top: 0,
      backgroundColor: theme.palette.background.default,
      zIndex: theme.zIndex.drawer + 1,
    },
    footer: {
      position: 'sticky',
      bottom: 0,
      backgroundColor: theme.palette.background.default,
      zIndex: theme.zIndex.drawer + 1,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
}));

const DialogLayout = ({open, title, subtitle, maxWidth, actionLoader, closeAction, negativeAction, positiveAction, children}) => {
    const dialogClasses = dialogStyles();

    const handleNegativeAction = () => {
      negativeAction.onClick();
    }

    const handlePositiveAction = () => {
      positiveAction.onClick();
    }

  return (
    <Dialog
        open={open}
        onClose={closeAction}
        fullWidth="true"
        maxWidth={maxWidth ?? "md"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={dialogClasses.dialog}>
            <div className={dialogClasses.header}>
            <div className="d-flex justfy-content-between align-items-center py-3 px-4">
                <div className="flex-grow-1">
                  <h3 className="text-primary fs-x-large-semibold p-0 m-0">
                    {title}
                  </h3>
                  {subtitle ? <p className="text-muted fs-normal-medium p-0 m-0">{subtitle}</p> : ''}
                </div>
                <IconButton onClick={() => closeAction()}>
                  <CancelIcon fontSize="medium" color="secondary" />
                </IconButton>
            </div>
            <Divider />
        </div>
        
        <div className={dialogClasses.content}>{children}</div>
        
        <div className={dialogClasses.footer}>
          <Divider />
          <div className="d-flex gap-3 justify-content-end py-3 px-4">
            {negativeAction ?
            <Button variant="text" color="secondary" onClick={handleNegativeAction}>
              {negativeAction.label}
            </Button> : '' }
            {positiveAction ?
            <Button variant="contained" color="secondary" onClick={handlePositiveAction} disabled={actionLoader}>
              {positiveAction.label}
            </Button> : '' }
          </div>
        </div>
    </Dialog>
  );
}

export default DialogLayout;