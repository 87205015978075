import React, { useState } from 'react';
import {
  Button,
  Typography,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useSnackbar } from 'notistack';
import { apiUploadUnitAllotmentFiles } from '../../utils/api/api';
import { SNACKBAR_SUCCESS, SNACKBAR_ERROR } from '../../utils/constants/constants';

const FileUploadDialog = ({ open, type, selectedRecord, onUpload, onClose }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    setFileName(file ? file.name : '');
  };


  const handleUpload = () => {
    if (!selectedFile) {
      enqueueSnackbar('Please select a file', SNACKBAR_ERROR);
      return;
    }

    if (!selectedRecord || !selectedRecord.utRid || !selectedRecord.invOppRid) {
      enqueueSnackbar('Required record information is missing', SNACKBAR_ERROR);
      return;
    }

    setIsLoading(true);

    apiUploadUnitAllotmentFiles(
      selectedRecord.utRid,
      type,
      selectedRecord.invOppRid,
      selectedFile
    )
      .then((response) => {
        setIsLoading(false);
        enqueueSnackbar(response.message, SNACKBAR_SUCCESS);
        onUpload(response.data);
        setSelectedFile(null);
        setFileName('');
        onClose();
      })
      .catch((error) => {
        setIsLoading(false);
        enqueueSnackbar(error, SNACKBAR_ERROR);
      });
  };
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ pl: 4.8 }}>
        Upload {type === 'seller' ? 'Seller' : 'Buyer'} Unit Allotment Statement
      </DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, p: 2 }}>
          <Box
            sx={{
              border: '2px dashed #ccc',
              borderRadius: 2,
              p: 3,
              textAlign: 'center',
              backgroundColor: '#fafafa',
              cursor: 'pointer',
              '&:hover': {
                borderColor: 'primary.main',
                backgroundColor: '#f5f5f5'
              }
            }}
            onClick={() => document.getElementById('file-input').click()}
          >
            <input
              id="file-input"
              type="file"
              onChange={handleFileSelect}
              style={{ display: 'none' }}
              accept=".pdf,.doc,.docx"
            />
            <CloudUploadIcon sx={{ fontSize: 48, color: 'primary.main', mb: 2 }} />
            <Typography variant="body1" gutterBottom>
              {fileName || 'Click to select a file or drag and drop here'}
            </Typography>

          </Box>
        </Box>
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <Button
          variant="outlined"
          color="primary"
          onClick={onClose}
          disabled={isLoading}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleUpload}
          disabled={!selectedFile || isLoading}
          sx={{ mr: 2}}
        >
          {isLoading ? 'Uploading...' : 'Upload'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default FileUploadDialog;