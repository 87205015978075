import React, {useState, useEffect} from 'react';
import DialogLayout from '../../components/layout/DialogLayout';
import { apiFundTransferred, apiInvestorOpportunity, apiUpdateFundTransferred } from '../../utils/api/api';
import { SNACKBAR_ERROR, SNACKBAR_SUCCESS } from "../../utils/constants/constants";
import { useSnackbar } from 'notistack';
import { CircularProgress, Stack, TextField } from '@mui/material';
import { useHistory } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import 'dayjs/locale/en-gb';
import { DatePicker } from "@mui/x-date-pickers";

const FundTransferDialog = ({ open, toggleDialog, scheme, handleSave }) => {
  const history = useHistory();
  const [investorList, setInvestorList] = useState([]);
  const [filteredInvList, setFilteredInvList] = useState([]);
  const [remark, setRemark] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [actionLoader, setActionLoader] = useState(false);

  const [fundTransferedDate, setFundTransferedDate] = useState(
    new Date().toISOString().split('T')[0]
  );

  const handleFundTransferedDate = (selectedDate) => {
    const date = new Date(selectedDate?.$d);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const formattedDate = `${year}-${month}-${day}`;
    setFundTransferedDate(formattedDate);
  }

  const getInvestorOpportunity = (param = {}) => {
    setIsLoading(true);
    param.oppRid = scheme.oppRid;
    apiInvestorOpportunity(param)
        .then((data) => {
          setIsLoading(false);
            setInvestorList(data.investorOpportunities);
            setFilteredInvList(
              data.investorOpportunities.filter(item => !item.consent || !item.fundStatus)
            );
        })
        .catch((error) => {
          setIsLoading(false);
            enqueueSnackbar(error,SNACKBAR_ERROR);
        });
  }

  useEffect(() => {
    getInvestorOpportunity();
    setRemark(scheme.remark ? scheme.remark : '');
    setFundTransferedDate(scheme.fundTransferred ? (scheme.fundTransferredDate ?? '') : fundTransferedDate);
  }, [open]);

  const saveFundTransferred = (param = {}) => {
    param.oppRid = scheme.oppRid;
    param.remark = remark;
    param.fundTransferredDate = fundTransferedDate;

    if(scheme.fundTransferred){
      setActionLoader(true);
      apiUpdateFundTransferred(param)
        .then((data) => {
          setActionLoader(false);
          enqueueSnackbar(data,SNACKBAR_SUCCESS);
          handleSave();
        })
        .catch((error) => {
          setActionLoader(false);
          enqueueSnackbar(error,SNACKBAR_ERROR);
        });
    } else {
      setActionLoader(true);
      apiFundTransferred(param)
        .then((data) => {
          setActionLoader(false);
          enqueueSnackbar(data,SNACKBAR_SUCCESS);
          handleSave();
        })
        .catch((error) => {
          setActionLoader(false);
            enqueueSnackbar(error,SNACKBAR_ERROR);
        });
    }
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
    <DialogLayout
      open={open}
      title={filteredInvList.length === 0 ? 'Fund Transferred to Company' : 'Investors Pending Activities'}
      actionLoader={actionLoader}
      closeAction={toggleDialog}
      negativeAction={
        filteredInvList.length === 0 ? {label: 'Cancel', onClick : toggleDialog} : ''
      }
      positiveAction={
        filteredInvList.length === 0 ? (scheme.fundTransferred ? {label: 'Save', onClick : saveFundTransferred} : {label: 'mark as transferred', onClick : saveFundTransferred}) : {label: 'Close', onClick : toggleDialog}
      }>
        { isLoading ? <CircularProgress /> :
        <div>
          { filteredInvList.length === 0 ? 
          <Stack direction="column" spacing={2}>
            <Stack direction="row" spacing={2}>
              <DatePicker
                sx={{ flex: 1 }}
                id="outlined-basic"
                name="fundTransferedDate"
                label="Fund Transfered Date"
                variant="outlined"
                value={fundTransferedDate}
                // fullWidth
                onChange={(v) => handleFundTransferedDate(v)}
                disabled={scheme.fundTransferred}
                renderInput={(params) => <TextField {...params} />}
              />
            </Stack>
            <TextField
              sx={{ flex: 1 }}
              id="outlined-multiline-static"
              label="Remark"
              multiline
              rows={4}
              value={remark}
              onChange={(e)=>setRemark(e.target.value)}
              // disabled={scheme.fundTransferred}
              fullWidth
            />
          </Stack> :
          <Stack direction="column" spacing={2}>
            <p className="fs-large-regular w-75">
              Please complete the pending activities before proceeding further
            </p>

            <table className="table table-striped table-borderless w-100">
              <thead>
                <tr className="fs-normal-semibold text-center">
                  <th scope="col">
                    <p className="mb-2">Investor</p>
                  </th>
                  <th scope="col">
                    <p className="mb-2">Consent</p>
                  </th>
                  <th scope="col">
                    <p className="mb-2">Fund</p>
                  </th>
                </tr>
              </thead>
              <tbody>
                { filteredInvList.map((data, key) => {
                  return(
                    <tr className="fs-normal-medium text-center" key={key}>
                      <td className="pt-3">
                        {data.invFullName}
                        <span className="fs-small-regular text-muted ms-2">{data.invCode}</span>
                      </td>
                      <td className="pt-3">
                        {data.consent ? 'Received' : <span className="text-danger">Pending</span>}
                      </td>
                      <td className="pt-3">
                        {data.fundStatus ? 'Received' : <span className="text-danger">Pending</span>}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Stack> }
        </div> }
      </DialogLayout>
    </LocalizationProvider>
  );
}

export default FundTransferDialog;