import React, { useEffect, useState } from 'react'
import DialogLayout from '../../../components/layout/DialogLayout';
import { useSnackbar } from 'notistack';
import { apiClassDematerialization, apiGetRecordMassDemat, apiRecordDematerialization, apiSaveMassDematRecord } from '../../../utils/api/api';
import { CircularProgress, Divider, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material';
import { SNACKBAR_ERROR, SNACKBAR_SUCCESS } from '../../../utils/constants/constants';
import { isSubAdmin } from '../../../utils/StringUtil';
import DeleteIcon from '@mui/icons-material/Delete';

const MassDematUploadDailog = ({ open, toggleDialog, scheme, handleSave }) => {

  const userType = process.env.REACT_APP_USER_TYPE;
  const { enqueueSnackbar } = useSnackbar();
  const [actionLoader, setActionLoader] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [shareDepository, setShareDepository] = useState('NSDL');
  const [dematCreditDate, setDematCreditDate] = useState(null);
  const [remarks, setRemarks] = useState(null);
  const [invList, setInvList] = useState([]);
  const [errorList, setErrorList] = useState([]);

  const [nsdlInvList, setNsdlInvList] = useState([]);
  const [cdslInvList, setCdslInvList] = useState([]);

  const getInvList = () => {
    setIsLoading(true);
    apiGetRecordMassDemat(scheme.oppRid)
      .then((data) => {
        setIsLoading(false);
        setCdslInvList(data.cdslInvestorList);
        setNsdlInvList(data.nsdlInvestorList);
        setInvList(shareDepository === 'NSDL' ? data.nsdlInvestorList : data.cdslInvestorList);
      })
      .catch((error) => {
        setIsLoading(false);
        enqueueSnackbar("Failed to fetch investor list", SNACKBAR_ERROR);
      });
  };


  const saveMassDematRecord = () => {
    if (!shareDepository) {
      enqueueSnackbar("Please select share depository", SNACKBAR_ERROR);
      return;
    }
    if (!dematCreditDate) {
      enqueueSnackbar("Please select demat credit date", SNACKBAR_ERROR);
      return;
    }
    if (!remarks) {
      enqueueSnackbar("Please enter dematerialisation remarks", SNACKBAR_ERROR);
      return;
    }
    if (invList.length === 0) {
      enqueueSnackbar("Investor list is empty", SNACKBAR_ERROR);
      return;
    }

    const reqBody = {
      nsdlCdsl: shareDepository,
      dematCreditDate,
      dematerializationRemark: remarks,
      dematRecordList: invList,
    };

    setIsLoading(true);
    apiSaveMassDematRecord(reqBody)
      .then((response) => {
        setIsLoading(false);
        if (response.status && response.data && Object.keys(response.data).length > 0) {
          const errors = Object.entries(response.data).map(([key, value]) => ({
            ioRid: Number(key),
            error: value,
          }));

          // Filter out only the error records from `invList`
          const errorRecords = invList.filter((inv) =>
            errors.some((err) => err.ioRid === inv.ioRid)
          );

          // Apply errors to the filtered error records
          const updatedErrorRecords = errorRecords.map((inv) => {
            const error = errors.find((err) => err.ioRid === inv.ioRid);
            return { ...inv, error: error ? error.error : null };
          });

          // Set the error list and update the `invList` with only error records
          setErrorList(errors);
          setInvList(updatedErrorRecords);
        } else {
          enqueueSnackbar("Demat recorded successfully", SNACKBAR_SUCCESS)
          handleSave();
        }
      })
      .catch((error) => {
        setIsLoading(false);
        enqueueSnackbar(error, SNACKBAR_ERROR);
      });
  };


  const handleShareDepository = (event) => {
    const { name, value } = event.target;
    setShareDepository(value);
    getInvList();
    if (value === 'NSDL') {
      setInvList(nsdlInvList);
    }
    if (value === 'CDSL') {
      setInvList(cdslInvList);
    }
  }

  const deleteInvestor = (ioRid) => {
    const updatedList = invList.filter((investor) => investor.ioRid !== ioRid);
    setInvList(updatedList);
  };

  const handleInvListChange = (index, field, value) => {
    setInvList((prevInvList) => {
      const updatedList = [...prevInvList];
      updatedList[index][field] = value; // Update the specific field in the specific row
      return updatedList;
    });
  };

  useEffect(() => {
    if (open) {
      // setting nsdl as default share depository
      setShareDepository('NSDL');
      setDematCreditDate(null);
      setRemarks(null);
      setErrorList([]);
      getInvList();
    }
  }, [open]);

  useEffect(() => {
    if (shareDepository === 'NSDL') {
      setInvList(nsdlInvList); // Set NSDL list when shareDepository is NSDL
    } else if (shareDepository === 'CDSL') {
      setInvList(cdslInvList); // Set CDSL list when shareDepository is CDSL
    }
  }, [shareDepository, nsdlInvList, cdslInvList]);


  return (
    <DialogLayout
      open={open}
      title="Mass Demat Upload"
      actionLoader={actionLoader}
      closeAction={toggleDialog}
      negativeAction={(userType === "ADMIN" && !isSubAdmin()) ? { label: 'Cancel', onClick: toggleDialog } : ''}
      positiveAction={(userType === "ADMIN" && !isSubAdmin()) ? { label: 'Save', onClick: saveMassDematRecord } : { label: 'Close', onClick: toggleDialog }}
    >
      <h3 className="fs-large-semibold text-primary mb-4">{scheme.oppTitle}</h3>

      {isLoading ? <CircularProgress /> :
        <div>
          <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">NSDL / CDSL</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="nsdlCdsl"
                  value={shareDepository}
                  label="NSDL / CDSL"
                  onChange={(e) => handleShareDepository(e)}
                >
                  <MenuItem value="NSDL">NSDL</MenuItem>
                  <MenuItem value="CDSL">CDSL</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="outlined-basic"
                type="date"
                name="dematCreditDate"
                label="Demat Credit Date"
                variant="outlined"
                value={dematCreditDate}
                onChange={(e) => setDematCreditDate(e.target.value)}
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="outlined-basic"
                name="dematerializationRemark"
                label="Remark"
                variant="outlined"
                value={remarks}
                onChange={(e) => setRemarks(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>

          <Stack direction="column" spacing={2}>
            <table className="table table-borderless w-100 p-0 m-0 mt-2">
              <thead>
                <tr className="fs-med-semibold text-center px-2">
                  <th scope="col">
                    Investor Name
                  </th>
                  <th className='text-center' scope="col">ISIN Code</th>
                  <th className='text-center' scope="col">Quantity</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {invList?.length ? (
                  invList.map((data, index) => (
                    <React.Fragment key={index}>
                      {/* Main row */}
                      <tr className={`fs-normal-medium py-2 text-center ${data?.error ? 'border border-danger' : ''}`}>
                        <td className="pt-3">
                          {data?.invName ?? ''}
                          <span className="fs-medium-regular text-muted">{data?.invCode ? `(${data.invCode})` : ''}</span>
                          <div className="fs-small-regular text-muted">{data?.className}</div>
                        </td>
                        <td className="pt-2 align-middle">
                          <div className="d-flex gap-1 flex-column">
                            <TextField
                              id="outlined-basic"
                              name="isin"
                              variant="outlined"
                              value={data?.isin ?? ''}
                              onChange={(e) => handleInvListChange(index, 'isin', e.target.value)}
                              size="small"
                              inputProps={{ maxLength: 12 }}
                              fullWidth
                            />
                          </div>
                        </td>
                        <td className="pt-2">
                          <div className="d-flex gap-1 flex-column">
                            <TextField
                              id="outlined-basic"
                              name="unitsAllotted"
                              variant="outlined"
                              value={data?.unitsAllotted ?? ''}
                              onChange={(e) => handleInvListChange(index, 'unitsAllotted', e.target.value)}
                              size="small"
                              inputProps={{ maxLength: 12 }}
                              fullWidth
                            />
                          </div>
                        </td>
                        <td>
                          <IconButton color="error" onClick={() => deleteInvestor(data?.ioRid)}>
                            <DeleteIcon />
                          </IconButton>
                        </td>
                      </tr>

                      {/* Error message row */}
                      {data?.error && (
                        <tr className="">
                          <td colSpan="4" className="text-danger text-start fs-med-regular">
                            {data.error}
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  ))
                ) : (
                  <tr>
                    <td colSpan="3">
                      <p className="text-center">No Records Found</p>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </Stack>
        </div>
      }
    </DialogLayout>
  )
}

export default MassDematUploadDailog
