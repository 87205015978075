import React, { useEffect, useState } from 'react'
import DialogLayout from '../../../components/layout/DialogLayout';
import { useSnackbar } from 'notistack';
import { apiClassDematerialization, apiInvestorOpportunity, apiRecordDematerialization } from '../../../utils/api/api';
import { Button, CircularProgress, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Stack, Switch, TextField, Tooltip, tooltipClasses } from '@mui/material';
import { SNACKBAR_ERROR, SNACKBAR_SUCCESS } from '../../../utils/constants/constants';
import { styled } from '@mui/material/styles';
import { isSubAdmin } from '../../../utils/StringUtil';

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    backgroundColor: theme.palette.common.white,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    border: '1px solid #000',
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(1),
  },
}));

const RecordDematerialization = ({ open, toggleDialog, scheme, handleSave }) => {
  const userType = process.env.REACT_APP_USER_TYPE;
  const [dematDetails, setDematDetails] = useState({});
  const { enqueueSnackbar } = useSnackbar();
  const [actionLoader, setActionLoader] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dematClassList, setDematClassList] = useState([]);

  const handleClassDematChange = (event, index) => {
    const { name, value } = event.target;
    const updatedList = [...dematClassList];
    updatedList[index][name] = value;
    setDematClassList(updatedList);
  }

  const getDematClass = (param = {}) => {
    setIsLoading(true);
    param.oppRid = scheme.oppRid;
    apiClassDematerialization(param)
      .then((data) => {
        setIsLoading(false);
          setDematClassList(data);
      })
      .catch((error) => {
        enqueueSnackbar(error,SNACKBAR_ERROR);
      });
  }

  useEffect(() => {
    if(open)
    getDematClass();
  }, [open]);

  const saveRecordDematerialization = (param = {}) => {
    setActionLoader(true);
    apiRecordDematerialization({
      oppRid: scheme?.oppRid,
      dematClassList
    })
        .then((data) => {
          setActionLoader(false);
          enqueueSnackbar('Data Saved',SNACKBAR_SUCCESS);
          handleSave();
        })
        .catch((error) => {
          setActionLoader(false);
            enqueueSnackbar(error,SNACKBAR_ERROR);
        });
  }

  useEffect(() => {
    if(open){
      setDematDetails({
        cdslIsin: scheme?.cdslIsin,
        nsdlIsin: scheme?.nsdlIsin,
      });
    }
  }, [open]);

  return (
    <DialogLayout
    open={open}
    title="Record Dematerialization"
    actionLoader={actionLoader}
    closeAction={toggleDialog}
    negativeAction={(userType === "ADMIN" && !isSubAdmin()) ? {label: 'Cancel', onClick: toggleDialog} : ''}
    positiveAction={(userType === "ADMIN" && !isSubAdmin()) ? {label: 'Save', onClick : saveRecordDematerialization} : {label: 'Close', onClick: toggleDialog}}
  >
    <h3 className="fs-large-semibold text-primary mb-4">{scheme.oppTitle}</h3>

    {isLoading ? <CircularProgress /> :
        <Stack direction="column" spacing={2}>
          <table className="table table-striped table-borderless w-100 p-0 m-0">
            <thead>
              <tr className="fs-normal-semibold text-center">
                <th scope="col">
                  <p className="mb-2">Class</p>
                </th>
                <th scope="col">NSDL ISIN</th>
                <th scope="col">CDSL ISIN</th>
              </tr>
            </thead>
            <tbody>
              {dematClassList.map((data, key) => {
                return (
                  <tr className="fs-normal-medium text-center" key={key}>
                    <td className="pt-3">
                      <div className="d-flex flex-column">
                        <span>{data.name}</span>
                        <i className="fs-normal-medium text-primary">{`Escrow: ${data?.escrowCount}`}</i>
                      </div>
                    </td>
                    <td className="pt-2">
                      <div className="d-flex gap-1 flex-column">
                        {
                          userType === "ADMIN" && !isSubAdmin()
                            ?
                            <TextField
                              id="outlined-basic"
                              name="nsdlIsin"
                              variant="outlined"
                              value={data?.nsdlIsin ?? ''}
                              onChange={(e) => handleClassDematChange(e, key)}
                              size="small"
                              inputProps={{ maxLength: 12 }}
                              fullWidth
                            />
                            : <span>{data?.nsdlIsin ?? '---'}</span>
                        }
                        <i className="fs-normal-medium text-primary">{`NDSL Investors: ${data?.nsdlCount}`}</i>
                      </div>
                    </td>
                    <td className="pt-2">
                      <div className="d-flex gap-1 flex-column">
                        {
                          userType === "ADMIN" && !isSubAdmin()
                            ?
                            <TextField
                              id="outlined-basic"
                              name="cdslIsin"
                              variant="outlined"
                              value={data?.cdslIsin ?? ''}
                              onChange={(e) => handleClassDematChange(e, key)}
                              size="small"
                              inputProps={{ maxLength: 12 }}
                              fullWidth
                            />
                            : <span>{data?.cdslIsin ?? '---'}</span>
                        }
                        <i className="fs-normal-medium text-primary">{`CDSL Investors: ${data?.cdslCount}`}</i>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Stack>}
    </DialogLayout>
  )
}

export default RecordDematerialization
