import { CircularProgress, Modal, Snackbar } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { useEffect } from "react";
import { useState } from "react";
import { Autocomplete } from "@mui/material";
import styles from "../../projects/Project.module.css";
import { editPortfolioCompany, getDataDictionaryValues, savePortfolioCompany, uploadImage } from "../../../utils/api/api";
import { BASE_URL, DD_TYPE_PROJECT_DOMAIN, DD_TYPE_STAGE_OF_COMPANY, DD_TYPE_INV_ROUND_TYPE, DD_TYPE_GEOGRAPHY, SNACKBAR_SUCCESS, SNACKBAR_WARNING, SNACKBAR_ERROR } from "../../../utils/constants/constants";
import { isEitherStringOrNumberValid, isTextEmpty } from "../../../utils/Validator";
import { useSnackbar } from 'notistack'



const PortfolioCompanyModal = ({ open, onClose, isEdit, company, handleSave }) => {

    const [companyForm, setCompanyForm] = useState(null);
    const [stageOfCompanyList, setStageOfCompanyList] = useState([]);
    const [domainList, setDomainList] = useState([]);
    const [invRoundList, setInvRoundList] = useState([]);
    const [geographyList, setGeographyList] = useState([]);
    const [titleError, setTitleError] = useState(false);
    const [sectorError, setSectorError] = useState(false);
    const [roundError, setRoundError] = useState(false);
    const [geoError, setGeoError] = useState(false);
    const [descError, setDescError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar()


    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 1200,
        maxHeight: '80vh',
        bgcolor: "background.paper",
        boxShadow: 24,
        overflow: "auto",
        padding: '0px 20px',
    };

    // data edit functions

    const handleChange = (e) => {

        clearError()

        setCompanyForm({
            ...companyForm,
            [e.target.name]: e.target.value
        })
    }

    const handleAutoComplete = (value, type) => {

        clearError()

        value = value.trim()

        if (Object.keys(companyForm).length === 0 && isEdit) {
            return
        }

        if (type === DD_TYPE_GEOGRAPHY) {
            const ddData = geographyList.find((item) =>
                item.ddValue.trim() === value
            )

            if (ddData) {
                setCompanyForm({
                    ...companyForm,
                    geoIndex: ddData.ddIndex,
                    geo: null
                })
            } else {
                setCompanyForm({
                    ...companyForm,
                    geoIndex: null,
                    geo: value
                })
            }

        }


        if (type === DD_TYPE_PROJECT_DOMAIN) {
            const ddData = domainList.find((item) =>
                item.ddValue.trim() === value
            )

            if (ddData) {
                setCompanyForm({
                    ...companyForm,
                    sectorIndex: ddData.ddIndex,
                    sector: null,
                })
            } else {
                setCompanyForm({
                    ...companyForm,
                    sector: value,
                    sectorIndex: null
                })
            }

        }


        if (type === DD_TYPE_STAGE_OF_COMPANY) {
            const ddData = stageOfCompanyList.find((item) =>
                item.ddValue.trim() === value
            )

            if (ddData) {
                setCompanyForm({
                    ...companyForm,
                    compStatusIndex: ddData.ddIndex,
                    compStatus: null,
                })
            } else {
                setCompanyForm({
                    ...companyForm,
                    compStatus: value,
                    compStatusIndex: null
                })
            }

        }

        if (type === DD_TYPE_INV_ROUND_TYPE) {
            const ddData = invRoundList.find((item) =>
                item.ddValue.trim() === value
            )

            if (ddData) {
                setCompanyForm({
                    ...companyForm,
                    invRoundIndex: ddData.ddIndex,
                    invRound: null
                })
            } else {
                setCompanyForm({
                    ...companyForm,
                    invRound: value,
                    invRoundIndex: null
                })
            }

        }
    }


    // apis functions

    const submitPortfolioCompany = () => {

        setIsLoading(true)

        if (isEdit) {
            editPortfolioCompany(companyForm)
                .then((res) => {
                    setIsLoading(false)
                    enqueueSnackbar(res, SNACKBAR_SUCCESS)
                    handleSave()
                })
                .catch((e) => {
                    setIsLoading(false)
                    enqueueSnackbar(e,SNACKBAR_ERROR)
                })
        } else {
            savePortfolioCompany(companyForm)
                .then((res) => {
                    setIsLoading(false)
                    enqueueSnackbar(res, SNACKBAR_SUCCESS)
                    handleSave()
                })
                .catch((e) => {
                    setIsLoading(false)
                    enqueueSnackbar(e,SNACKBAR_ERROR)
                })
        }

    }

    const addImage = (e) => {

        uploadImage(e.target.files[0])
            .then((res) => {
                setCompanyForm({
                    ...companyForm,
                    logoUrl: res.data.path,
                });

            })
            .catch((e) => {
                enqueueSnackbar(e,SNACKBAR_ERROR);
            })
    };

    const getDataDictionary = () => {
        getDataDictionaryValues()
            .then((data) => {

                const domains = data.filter((item) =>
                    item.ddType === DD_TYPE_PROJECT_DOMAIN
                )

                const stages = data.filter((item) =>
                    item.ddType === DD_TYPE_STAGE_OF_COMPANY
                )

                const rounds = data.filter((item) =>
                    item.ddType === DD_TYPE_INV_ROUND_TYPE
                )

                const geography = data.filter((item) =>
                    item.ddType === DD_TYPE_GEOGRAPHY
                )

                setDomainList(domains)
                setStageOfCompanyList(stages)
                setInvRoundList(rounds)
                setGeographyList(geography)

            })
            .catch((e) => {
                enqueueSnackbar(e,SNACKBAR_ERROR);
            })
    }

    // other functions

    const handleClose = () => {
        clearError()
        onClose()
    }

    const clearError = () => {
        setDescError(false)
        setTitleError(false)
        setRoundError(false)
        setGeoError(false)
        setSectorError(false)
    }

    const openExlporer = () => {
        document.getElementById('input-image').click()
    }

    // validation

    const handleSubmit = () => {

        let hasError = false;

        if (isTextEmpty(companyForm.title)) {
            setTitleError(true)
            hasError = true
        }

        if (isEitherStringOrNumberValid(companyForm.invRound, companyForm.invRoundIndex)) {
            setRoundError(true)
            hasError = true
        }

        if (isEitherStringOrNumberValid(companyForm.sector, companyForm.sectorIndex)) {
            setSectorError(true)
            hasError = true
        }

        if (isEitherStringOrNumberValid(companyForm.geo, companyForm.geoIndex)) {
            setGeoError(true)
            hasError = true
        }

        if (isTextEmpty(companyForm.description)) {
            setDescError(true)
            hasError = true
        }

        if (hasError) {
            enqueueSnackbar("Please fill in the required fields", SNACKBAR_WARNING)
            return
        }

        submitPortfolioCompany()

    }

    useEffect(() => {

        if (isEdit) {

            if (typeof company.coInvestors === "string") {
                company.coInvestors = JSON.parse(company.coInvestors)
            }

            if (typeof company.coInvestors === "string") {
                company.coInvestors = JSON.parse(company.coInvestors)
            }

            setCompanyForm(company)
        } else {
            setCompanyForm({})
        }

        getDataDictionary()

    }, [company])

    return (
        <>
            <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className="modal-header">
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            {isEdit ? 'Edit Company' : 'Add Company'}
                        </Typography>
                    </div>

                    <Stack sx={{ marginBottom: "1rem", marginTop: '2rem' }} direction="row" spacing={2}>
                        <TextField
                            sx={{ flex: 1 }}
                            id="outlined-basic"
                            name="title"
                            required
                            error={titleError}
                            label="Company"
                            helperText={titleError ? "Not a valid title" : ""}
                            variant="outlined"
                            defaultValue={company ? company.title : ""}
                            fullWidth
                            onChange={(e) => handleChange(e)}
                        />
                        <TextField
                            sx={{ flex: 1 }}
                            id="outlined-basic"
                            name="website"
                            label="Website"
                            variant="outlined"
                            defaultValue={company ? company.website : ""}
                            fullWidth
                            onChange={(e) => handleChange(e)}
                        />


                    </Stack>
                    <Stack sx={{ marginBottom: "1rem" }} direction="row" spacing={2}>

                        <Autocomplete
                            freeSolo
                            name="Sector"
                            sx={{ flex: 1 }}
                            defaultValue={domainList?.find((item) => item.ddIndex === company?.sectorIndex)}
                            onInputChange={(_event, newInputValue) => {
                                handleAutoComplete(newInputValue, DD_TYPE_PROJECT_DOMAIN);
                            }}
                            options={domainList}
                            getOptionLabel={(option) => option.ddValue}
                            renderInput={(params) =>
                                <TextField {...params}
                                    required
                                    error={sectorError}
                                    helperText={sectorError ? "This Field is required" : ""}
                                    label="Sector" />}
                        />
                        <Autocomplete
                            freeSolo
                            name="Round"
                            sx={{ flex: 1 }}
                            defaultValue={invRoundList?.find((item) => item.ddIndex === company?.invRoundIndex)}
                            onInputChange={(_event, newInputValue) => {
                                handleAutoComplete(newInputValue, DD_TYPE_INV_ROUND_TYPE);
                            }}
                            options={invRoundList}
                            getOptionLabel={(option) => option.ddValue}
                            renderInput={(params) =>
                                <TextField {...params}
                                    required
                                    error={roundError}
                                    helperText={roundError ? " This Field is required" : ""}
                                    label="Round" />}
                        />
                        <Autocomplete
                            freeSolo
                            name="Geography"
                            sx={{ flex: 1 }}
                            defaultValue={geographyList?.find((item) => item.ddIndex === company?.geoIndex)}
                            onInputChange={(_event, newInputValue) => {
                                handleAutoComplete(newInputValue, DD_TYPE_GEOGRAPHY);
                            }}
                            options={geographyList}
                            getOptionLabel={(option) => option.ddValue}
                            renderInput={(params) =>
                                <TextField {...params}
                                    required
                                    error={geoError}
                                    helperText={geoError ? " This field is required" : ""}
                                    label="Geography" />}
                        />
                    </Stack>
                    <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
                        <TextField
                            rows="4"
                            multiline
                            variant="outlined"
                            className="form-control"
                            name="description"
                            required
                            error={descError}
                            helperText={descError ? "This field is required" : ""}
                            label="Description"
                            onChange={(e) => handleChange(e)}
                            defaultValue={companyForm ? companyForm.description : ""}
                            aria-label="With textarea" />
                    </Stack>
                    <p className="section-label mt-4 mb-0">
                        Logo
                    </p>
                    <Stack direction="row" sx={{ mt: 2 }}>
                        <input
                            className="d-none"
                            id="input-image"
                            type="file"
                            onClick={(e) => {
                                e.target.value = null;
                            }}
                            onChange={(e) => {
                                addImage(e);
                            }}
                        />
                        <img className="img-preview" onClick={() => openExlporer()} src={companyForm?.logoUrl ? `${BASE_URL}/${companyForm.logoUrl}` : '/no-image.jpg'} />

                    </Stack>

                    <div className="row mx-0 justify-content-end w-100 mt-3 modal-footer bg-white py-4">
                        <button className="btn w-fit px-4 me-1" onClick={() => handleClose()}>
                            Cancel
                        </button>
                        {
                            isLoading ?
                                (
                                    <div
                                        className="btn w-fit px-4"
                                    >
                                        <CircularProgress />
                                    </div>
                                ) :
                                (
                                    <button
                                        className="btn btn-primary w-fit px-4"
                                        type="submit"
                                        onClick={() => handleSubmit()}
                                    >
                                        Save
                                    </button>
                                )
                        }
                    </div>
                </Box>

            </Modal>
        </>
    );
}

export default PortfolioCompanyModal;