import React from "react";
import SideBar from "../navbar/Sidebar";
import Topbar from "../navbar/Topbar";

const Layout = ({ title, children }) => {
  return (
    <div className="row g-0 p-0 m-0 overflow-hidden">
        <div className="col-2 p-0 m-0">
          <SideBar />
        </div>
        <div className="col-10 d-flex flex-column p-0 m-0" style={{height: '100vh', backgroundColor: '#77239608'}}>
          <Topbar title={title}></Topbar>
          <div className="p-0 d-flex flex-grow-1 flex-column" style={{height: '100%', overflowY: 'auto'}}>{children}</div>
        </div>
      </div>
  );
};

export default Layout;
