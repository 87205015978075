import React, { useEffect, useState } from 'react';
import DataCard from './components/card.js'

const Home = ({ currency, data , inrAgainstUsd }) => {
  const [cardData, setCardData] = useState(data || {});

  useEffect(() => {
    setCardData(data);
  }, [data]);

  const metrics = [
    { value: cardData?.totalInvestors, label: 'Total Number of Investors' },
    { value: cardData?.totalActiveInvestors, label: 'Total Active Investors' },
    { value: cardData?.totalInvestments, label: 'Total Investments' },
    { value: cardData?.valueOfInvestment, label: 'Value of Investments', showCurrency: true },
    { value: cardData?.lifetimeExpenses, label: 'Lifetime Expenses', showCurrency: true },
    { value: cardData?.lifetimeIncome, label: 'Lifetime Income', showCurrency: true },
    { value: cardData?.averageInvestmentPerInvestor, label: 'Average Investment per Investor', showCurrency: true },
    { value: cardData?.averageInvestmentPerCompany, label: 'Average Investment per Company',showCurrency: true },
    { value: cardData?.investmentByGP, label: 'Investment by GP', showCurrency: true },
    { value: cardData?.averageInvestmentByGPPerCompany, label: 'Average Investment by GP per Company', showCurrency: true },
  ];

  return (
    <div className="py-4 px-2">
      <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
        {metrics.map((metric, index) => (
          <DataCard
            key={index}
            value={metric.value}
            label={metric.label}
            showCurrency={metric.showCurrency || false}
            currencySymbol={currency === 'INR'? "₹" : "$"} 
            currency = {currency}
            inrAgainstUsd = {inrAgainstUsd}
          />
        ))}
      </div>
    </div>
  );
};

export default Home;
