import React, {useState,useEffect} from 'react';
import DrawerLayout, { ScoreBoard } from '../../components/layout/DrawerLayout';
import { Autocomplete, Button, IconButton, Stack, TextField } from '@mui/material';
import { apiDeletePartnerPayment, apiFunds, apiInvestorOpportunity, apiPartnerPayment, apiSavePartnerPayment, getDataDictionaryValues, getOpportunity, getPartners } from '../../utils/api/api';
import { DD_TYPE_PATNERS, SNACKBAR_ERROR, SNACKBAR_SUCCESS, SNACKBAR_WARNING } from "../../utils/constants/constants";
import { formatDateTime, isSubAdmin, toLocalePrice } from '../../utils/StringUtil';
import { useSnackbar } from 'notistack';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import 'dayjs/locale/en-gb';
import { DatePicker } from "@mui/x-date-pickers";
import AlertLayout from '../../components/layout/AlertLayout';
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const PartnerPayments = ({ open, toggleDrawer }) => {
    const [schemeList, setSchemeList] = useState([]);
    const [investorList, setInvestorList] = useState([]);
    const [partnerList, setPartnerList] = useState([]);
    const [partnerRid, setPartnerRid] = useState(null);
    const [patnerValue, setPatnerValue] = useState(null);
    const [oppRid, setOppRid] = useState(null);
    const [investorOpportunity, setInvestorOpportunity] = useState(null);
    const [paymentDate, setPaymentDate] = useState(
        new Date().toISOString().split('T')[0]
    );
    const [patnerAmount, setPatnerAmount] = useState(0.00);
    const [remark, setRemark] = useState('');
    const [partnerPayment, setPartnerPayment] = useState([]);
    const { enqueueSnackbar } = useSnackbar();
    const [editPatnerPayment, setEditPatnerPayment] = useState(null);
    const [openAlertDialog, setOpenAlertDialog] = useState(false);
    const [alertLoader, setAlertLoader] = useState(false);

    const toggleAlertDialog = () => {
        if(openAlertDialog){
            setOpenAlertDialog(false);
        } else {
            setOpenAlertDialog(true);
        }
    }
    const handleAlertDialog = () => {
        setAlertLoader(true);
        apiDeletePartnerPayment({
            "ppRid": editPatnerPayment?.ppRid
        })
          .then((data) => {
            setAlertLoader(false);
                enqueueSnackbar(data,SNACKBAR_SUCCESS);
                toggleAlertDialog();
                getPartnerPayment();
          })
          .catch((error) => {
            setAlertLoader(false);
              enqueueSnackbar(error,SNACKBAR_ERROR);
          });
    }

    const handlePaymentDate = (selectedDate) => {
        const date = new Date(selectedDate?.$d);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        const formattedDate = `${year}-${month}-${day}`;
        setPaymentDate(formattedDate);
    }

    const getPartnersList = (param = {}) => {
        param.invRid = investorOpportunity?.invRid;
        setPartnerList([]);
        getPartners(param)
        .then((res) => {
            setPartnerList(res.partnerMasterList);
            const primaryPatners = res.partnerMasterList?.filter(item => item.primary === true);
            setPartnerRid(primaryPatners[0]?.partnerRid);
        })
        .catch((e) => {
            enqueueSnackbar(e, SNACKBAR_ERROR);
        });
    }

    const getSchemeList = (param = {}) => {
        param.pageNumber = 0;
        getOpportunity(param)
          .then((data) => {
            setSchemeList(data.opportunityList);
          })
          .catch((error) => {
            enqueueSnackbar(error,SNACKBAR_ERROR)
          });
    }

    const getInvestorOpportunity = (param = {}) => {
        param.oppRid = oppRid;
        apiInvestorOpportunity(param)
            .then((data) => {
                setInvestorList(data.investorOpportunities);
            })
            .catch((error) => {
                enqueueSnackbar(error,SNACKBAR_ERROR);
            });
    }

    const getPartnerPayment = (param = {}) => {
        // param.oppRid = oppRid;
        param.ioRid = investorOpportunity?.ioRid;
        apiPartnerPayment(param)
            .then((data) => {
                setPartnerPayment(data.partnerPayments);
            })
            .catch((error) => {
                enqueueSnackbar(error,SNACKBAR_ERROR);
            });
    }

    useEffect(() => {
        if (investorOpportunity !== null) {
            getPartnerPayment();
            getPartnersList();
        }
    }, [investorOpportunity]);

    useEffect(() => {
        if (oppRid !== null) {
            getInvestorOpportunity();
            setInvestorOpportunity(null);
        }
    }, [oppRid]);

    useEffect(() => {
        if(open){
            getSchemeList();
            setOppRid(null);
            setInvestorList([]);
            setInvestorOpportunity(null);
            setPartnerRid(null);
            setPatnerValue(null);
            setPatnerAmount(0.00);
            setRemark('');
            setPartnerPayment([]);
            setPaymentDate(new Date().toISOString().split('T')[0]);
        }
    }, [open]);

    const handleAddPatner = () => {
        if(!partnerRid) {
            enqueueSnackbar("Please select patner", SNACKBAR_WARNING);
        } else if(parseFloat(patnerAmount) <= 0){
            enqueueSnackbar("Invalid amount", SNACKBAR_WARNING);
        } else {
            apiSavePartnerPayment({
                "amt": patnerAmount,
                "ioRid": investorOpportunity.ioRid,
                "partnerRid": partnerRid,
                "paymentDate": paymentDate,
                "remark": remark
            }).then((data) => {
                enqueueSnackbar(data,SNACKBAR_SUCCESS);
                getPartnerPayment();
                // setPartnerList([]);
                // setPartnerRid(null);
                // setPatnerValue(null);
                setPatnerAmount(0.00);
                setRemark('');
                setPaymentDate(new Date().toISOString().split('T')[0]);
            })
            .catch((error) => {
                enqueueSnackbar(error,SNACKBAR_ERROR);
            });
        }
    }

    const handlePaymentDelete = (item) => {
        setEditPatnerPayment(item);
        toggleAlertDialog();
    }

   

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
    <DrawerLayout
      open={open}
      title="Partner Payments"
      closeAction={toggleDrawer}
      positiveAction={
        {label: 'Close', onClick : toggleDrawer}
      }
    >
        <AlertLayout
            open={openAlertDialog}
            title="Are You Sure ?"
            actionLoader={alertLoader}
            closeAction={toggleAlertDialog}
            positiveAction={
                {label: 'Delete', onClick: handleAlertDialog}
            }>
                <p className="fs-large-medium p-0 m-0">This action cannot be undone. This will permanently delete.</p>
        </AlertLayout>

        <Stack sx={{ marginBottom: "2rem" }} direction="row" spacing={2}>
            <Autocomplete
                sx={{ flex: 1 }}
                id="outlined-basic"
                name="oppRid"
                variant="outlined"
                options={schemeList}
                getOptionLabel={(option) => option.oppTitle}
                value={schemeList.find((option) => option.oppRid === oppRid) || null}
                fullWidth
                onChange={(e, v) => setOppRid(v?v.oppRid:null)}
                renderInput={(params) => <TextField {...params} label="Select Scheme" />}
                renderOption={(props, option) => (
                    <li {...props} key={option.oppRid}>{option.oppTitle}</li>
                )}
                size="small"
            />
            <Autocomplete
                sx={{ flex: 1 }}
                id="outlined-basic"
                name="invRid"
                variant="outlined"
                options={investorList}
                getOptionLabel={(option) => option.invFullName}
                value={investorList.find((option) => option.ioRid === investorOpportunity?.ioRid) || null}
                fullWidth
                onChange={(e, v) => setInvestorOpportunity(v)}
                renderInput={(params) => <TextField {...params} label="Select Investor" />}
                renderOption={(props, option) => (
                    <div className="d-flex align-items-center" {...props} key={option.ioRid}>
                        <AccountCircleIcon color="primary" fontSize="large" />
                        <div className="flex-grow-1 ms-2">
                            <p className="fs-med-medium p-0 m-0">{option.invFullName}</p>
                            <p className="fs-small-regular text-muted p-0 m-0">{option.invCode} • {option.className}</p>
                        </div>
                    </div>
                    // <li {...props} key={option.invRid}>{option.invFullName}</li>
                )}
                size="small"
            />
        </Stack>
        <Stack sx={{ marginBottom: "2rem" }} direction="row" spacing={2} className={investorOpportunity === null ? 'd-none' : ''}>
            <ScoreBoard
                label="Class"
                value={investorOpportunity?.className}
                flex={1}
            />
            <ScoreBoard
                label="Upfront + Statutory Fees"
                value={toLocalePrice(investorOpportunity?.upfrontAmount + investorOpportunity?.gst)}
                flex={1}
            />
        </Stack>
        {!isSubAdmin() && (
        <Stack sx={{ marginBottom: "2rem" }} direction="row" spacing={2} className={investorOpportunity === null ? 'd-none' : ''}>
            <div className="card flex-grow-1 p-3">
            <Stack sx={{ flex: 1 }} direction="column" spacing={2}>
                <Autocomplete
                    sx={{ flex: 1 }}
                    id="outlined-basic"
                    variant="outlined"
                    options={partnerList}
                    getOptionLabel={(option) => option.fullName}
                    value={partnerList.find((option) => option.partnerRid === partnerRid) || null}
                    // defaultValue={partnerList?.find(item => item.primary === true)?.partnerRid}
                    fullWidth
                    onChange = {(e, v) => {
                        setPartnerRid(v.partnerRid);
                    }}
                    renderInput={(params) => <TextField {...params} label="Partner Name" />}
                    size="small"
                />
                <Stack sx={{ flex: 1 }} direction="row" spacing={2}>
                    <Stack sx={{ flex: 1 }} direction="column" spacing={2}>
                        <TextField
                            sx={{ flex: 1 }}
                            id="outlined-basic"
                            name="patnerAmount"
                            type="number"
                            label="Amount"
                            variant="outlined"
                            value={patnerAmount}
                            onChange={(e) => setPatnerAmount(e.target.value)}
                            fullWidth
                            size="small"
                        />
                        <Stack sx={{ flex: 1 }}>
                            <DatePicker
                                id="outlined-basic"
                                name="paymentDate"
                                label="Date (optional)"
                                variant="outlined"
                                value={paymentDate}
                                fullWidth
                                onChange={(v) => handlePaymentDate(v)}
                                renderInput={(params) => <TextField {...params} />}
                                size="small"
                            />
                        </Stack>
                    </Stack>
                    <TextField
                        sx={{ flex: 1 }}
                        id="outlined-multiline-static"
                        label="Remark (optional)"
                        multiline
                        rows={4}
                        value={remark}
                        onChange={(e)=>setRemark(e.target.value)}
                        fullWidth
                        size="small"
                    />
                </Stack>
            </Stack>
            </div>
            <div className="d-flex align-items-end">
                <Button variant="contained" onClick={handleAddPatner} disableElevation>Add</Button>
            </div>
        </Stack>
        )}
        <Stack sx={{ marginBottom: "2rem" }} direction="column" spacing={2} className={investorOpportunity === null || partnerPayment.length === 0 ? 'd-none' : ''}>
            <p className="section-label p-0 m-0">Payment Logs</p>
            <table className="table table-striped table-borderless w-100">
                <thead>
                    <tr className="fs-normal-semibold text-center">
                        <th scope="col">
                            <p className="mb-2">Partner</p>
                        </th>
                        <th scope="col">
                            <p className="mb-2 text-end">Amount</p>
                        </th>
                        <th scope="col">
                            <p className="mb-2">Date</p>
                        </th>
                        <th scope="col">
                            <p className="mb-2">Remarks</p>
                        </th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    {partnerPayment.map((data, key)=>{
                        return(
                            <tr className="fs-normal-medium text-center" key={key}>
                                <td className="pt-3">{data.partnerName}</td>
                                <td className="pt-3 text-end">{toLocalePrice(data.amt)}</td>
                                <td className="pt-3">{formatDateTime(data.paymentDate, false)}</td>
                                <td className="pt-3">{data.remark}</td>
                                {!isSubAdmin() && (
                                    <td className="p-0">
                                    <IconButton color="error" onClick={()=>handlePaymentDelete(data)}>
                                        <DeleteOutlineIcon />
                                    </IconButton>
                                </td>
                                )}
                                
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </Stack>
    </DrawerLayout>
    </LocalizationProvider>
  );
}

export default PartnerPayments;