import React, { useEffect, useState } from 'react';
import DialogLayout from '../../components/layout/DialogLayout';
import { apiApproveDraftInvestment, apiSaveInvestorOpportunity, apiWallet, getMasterClass } from '../../utils/api/api';
import { ScoreBoard } from '../../components/layout/DrawerLayout';
import { Autocomplete, Stack, TextField } from '@mui/material';
import { toLocalePrice } from '../../utils/StringUtil';
import { SNACKBAR_ERROR, SNACKBAR_SUCCESS } from '../../utils/constants/constants';
import { useSnackbar } from 'notistack';

const ApproveDraftInvestment = ({ open, toggleDialog, invOpp, handleSave }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [actionLoader, setActionLoader] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const [walletAmount, setWalletAmount] = useState(0.00);
    const [commitment, setCommitment] = useState(0.00);
    const [upFront, setUpFront] = useState(0);
    const [upFrontAmount, setUpFrontAmount] = useState(0.00);
    const [gst, setGST] = useState(0);
    const [total, setTotal] = useState(0);
    const [masterClassList, setMasterClassList] = useState([]);
    const [cmRid, setCmRid] = useState(null);
    const [carry, setCarry] = useState(0.00);
    const [hurdle, setHurdle] = useState(0.00);
    const [dematCharges, setDematCharges] = useState(0.00);

    const getMasterClassList = (param = {}) => {
        param.status = 1;
        getMasterClass(param)
          .then((data) => {
            setMasterClassList(data.classMasters);
          })
          .catch((error) => {
            console.log(error);
          });
    }
    const getWallet = (param = {}) => {
        param.invRid = invOpp?.invRid;
        apiWallet(param)
          .then((data) => {
            setWalletAmount(data.balanceAmt);
          })
          .catch((error) => {
            console.log(error);
          });
    }

    useEffect(() => {
        const upFrontValue = (parseFloat(commitment) * parseFloat(upFront)) / 100;
        const gstValue = (parseFloat(upFrontValue) * 18.00) / 100;
        const totalValue = parseFloat(commitment) + parseFloat(upFrontValue) + parseFloat(gstValue);
        setUpFrontAmount(upFrontValue);
        setGST(gstValue);
        setTotal(totalValue);
    }, [commitment, upFront]);
    
    useEffect(() => {
        const masterClass = masterClassList.find((option) => option.cmRid === cmRid) || null;
        if(masterClass){
            setCarry(masterClass.carry);
            setHurdle(masterClass.hurdleRate);
        }
    }, [cmRid]);

    const handleApproveDraftInvestment = () => {
        const reqBody = {
            "diRid": invOpp?.diRid,
            "commitment": parseFloat(commitment),
            "upfront": parseFloat(upFront),
            "cmRid": cmRid,
            "totalAmount": parseFloat(total + dematCharges), 
            "dematCharges": parseFloat(dematCharges),
        }
        
        setActionLoader(true);
        apiApproveDraftInvestment(reqBody)
            .then((data) => {
                setActionLoader(false);
                enqueueSnackbar(data,SNACKBAR_SUCCESS);
                handleSave();
            })
            .catch((error) => {
                setActionLoader(false);
                enqueueSnackbar(error,SNACKBAR_ERROR);
            });
    }

    useEffect(() => {
        if(open){
            setWalletAmount(0.00);
            setCommitment(invOpp?.commitment);
            setUpFront(invOpp?.upfront);
            setUpFrontAmount(upFrontAmount);
            setGST(gst);
            setTotal(total);
            setCmRid(invOpp?.cmRid);
            setDematCharges(invOpp?.dematCharges ?? 0.00);

            getWallet();
            getMasterClassList();
        }
    }, [open]);

  return (
    <DialogLayout
      open={open}
      title="Approve Draft Investment"
      subtitle={invOpp?.invCode}
      maxWidth="md"
      actionLoader={actionLoader}
      closeAction={toggleDialog}
      negativeAction={
        {label: 'Cancel', onClick : toggleDialog}
      }
      positiveAction={{
        label: 'Approve',
        onClick : handleApproveDraftInvestment}
    }>
        <Stack sx={{ marginBottom: "2rem" }} direction="row" spacing={2}>
            <ScoreBoard
                label="Wallet Amount"
                value={toLocalePrice(walletAmount)}
                flex={1}
            />
            <TextField
                sx={{ flex: 1 }}
                id="outlined-basic"
                name="commitment"
                type="number"
                label="Commitment"
                variant="outlined"
                value={commitment}
                onChange={(e) => setCommitment(e.target.value)}
                fullWidth
                disabled
            />
      </Stack>
      <Stack sx={{ marginBottom: "2rem" }} direction="row" spacing={2}>
          <TextField
              sx={{ flex: 1 }}
              id="outlined-basic"
              name="upFront"
              type="number"
              label="Upfront (%)"
              variant="outlined"
              value={upFront ?? null}
              onChange={(e) => setUpFront(e.target.value)}
              fullWidth
          />
          <ScoreBoard
            label="UF Amount"
            value={toLocalePrice(upFrontAmount)}
            flex={1}
          />
          <ScoreBoard
            label="Statutory Fees"
            value={toLocalePrice(gst)}
            flex={1}
          />
          <ScoreBoard
            label="Total"
            value={toLocalePrice(total + dematCharges)}
            flex={1}
          />
          <ScoreBoard
            label="Demat Charges"
            value={toLocalePrice(dematCharges)}
            flex={1}
          />
      </Stack>
      <Stack sx={{ marginBottom: "2rem" }} direction="row" spacing={2}>
          <Autocomplete
            sx={{ flex: 1 }}
            id="outlined-basic"
            variant="outlined"
            options={masterClassList}
            getOptionLabel={(option) => option.name}
            value={masterClassList.find((option) => option.cmRid === cmRid) || null}
            fullWidth
            onChange={(e, v) => setCmRid(v.cmRid)}
            renderInput={(params) => <TextField {...params} label="Class" />}
          />
          <ScoreBoard
            label="Carry"
            value={carry + "%"}
            flex={1}
          />
          <ScoreBoard
            label="Hurdle"
            value={hurdle + "%"}
            flex={1}
          />
      </Stack>
    </DialogLayout>
  )
}

export default ApproveDraftInvestment;