import React, { useState, useEffect } from "react";
import DrawerLayout, { ScoreBoard } from "../../components/layout/DrawerLayout";
import { Stack, Switch } from "@mui/material";
import {
  apiInvestorOpportunity,
  apiUpdateFunds,
  apiWallet,
} from "../../utils/api/api";
import {
  SNACKBAR_ERROR,
  SNACKBAR_SUCCESS,
  SNACKBAR_WARNING,
} from "../../utils/constants/constants";
import { useSnackbar } from "notistack";
import {
  isSubAdmin,
  toLocalePrice,
  toLocalePriceNoDecimal,
} from "../../utils/StringUtil";

const ActiveScheme = ({ open, toggleDrawer, investor, handleSave }) => {
  const [invRid, setInvRid] = useState(null);
  const [walletAmount, setWalletAmount] = useState(0.0);
  const [balance, setBalance] = useState(0.0);
  const [invOpportunityList, setInvOpportunityList] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [actionLoader, setActionLoader] = useState(false);
  const userType = process.env.REACT_APP_USER_TYPE;

  const handleInvOppSwitch = (ioRid) => {
    const updatedList = invOpportunityList.map((item) =>
      item.ioRid === ioRid
        ? { ...item, fundStatus: item.fundStatus ? 0 : 1 }
        : item
    );
    updatedList.forEach((item) => {
      if (item.ioRid === ioRid) {
        if (item.fundStatus === 0) {
          setBalance((prevBalance) => prevBalance + item.totalAmount);
        } else if (item.status === 1) {
          setBalance((prevBalance) => prevBalance - item.totalAmount);
        }
      }
    });
    setInvOpportunityList(updatedList);
  };

  const getWallet = (param = {}) => {
    param.invRid = invRid;
    apiWallet(param)
      .then((data) => {
        setWalletAmount(data.balanceAmt);
        setBalance(data.balanceAmt);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getInvestorOpportunity = (param = {}) => {
    param.invRid = invRid;
    param.oppRid = null;
    param.drawdown = null;
    param.consent = null;
    param.fundStatus = null;
    apiInvestorOpportunity(param)
      .then((data) => {
        setInvOpportunityList(data.investorOpportunities);
      })
      .catch((error) => {
        enqueueSnackbar(error, SNACKBAR_ERROR);
      });
  };

  useEffect(() => {
    getInvestorOpportunity();
    getWallet();
  }, [invRid]);

  useEffect(() => {
    setInvRid(investor ? investor.invRid || null : null);
  }, [open]);

  const updateFund = () => {
    const reqBody = {
      invRid: invRid,
      investorOpportunities: invOpportunityList.filter(
        (item) => item.fundTransferred === 0
      ),
    };
    setActionLoader(true);
    apiUpdateFunds(reqBody)
      .then((data) => {
        setActionLoader(false);
        enqueueSnackbar(data, SNACKBAR_SUCCESS);
        handleSave();
      })
      .catch((error) => {
        setActionLoader(false);
        enqueueSnackbar(error, SNACKBAR_ERROR);
      });
  };

  const onSubmit = () => {
    if (parseFloat(balance) < 0) {
      enqueueSnackbar(
        "Total scheme commitment is more than the funds received. Please uncheck the scheme to proceed",
        SNACKBAR_WARNING
      );
    } else {
      updateFund();
    }
  };

  return (
    <DrawerLayout
      open={open}
      title="Active Schemes"
      actionLoader={actionLoader}
      closeAction={toggleDrawer}
      negativeAction={{ label: "Cancel", onClick: toggleDrawer }}
      positiveAction={!isSubAdmin() && { label: "Save", onClick: onSubmit }}
    >
      <Stack
        sx={{ marginBottom: "2rem" }}
        className={invOpportunityList.length === 0 ? "" : "d-none"}
      >
        <p className="fs-large-regular text-center text-muted">No Data</p>
      </Stack>
      <Stack
        sx={{ marginBottom: "2rem" }}
        direction="row"
        spacing={2}
        className={invOpportunityList.length === 0 ? "d-none" : ""}
      >
        <ScoreBoard
          label="Wallet Amount"
          value={toLocalePrice(walletAmount)}
          flex={1}
        />
        <ScoreBoard label="Balance" value={toLocalePrice(balance)} flex={1} />
      </Stack>
      <Stack
        sx={{ marginBottom: "2rem" }}
        direction="column"
        className={invOpportunityList.length === 0 ? "d-none" : ""}
      >
        <table className="table table-striped table-borderless w-100">
          <thead>
            <tr className="fs-normal-semibold text-center">
              <th scope="col">
                <p className="mb-2">Scheme</p>
              </th>
              <th scope="col">
                <p className="mb-2 text-end">Comiitted</p>
              </th>
              <th scope="col">
                <p className="mb-2 text-end">Upfront fee</p>
              </th>
              <th scope="col">
                <p className="mb-2 text-end">Total</p>
              </th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {invOpportunityList.map((data, key) => {
              return (
                <tr className="fs-normal-medium text-center" key={key}>
                  <td className="pt-3">{data.oppTitle}</td>
                  <td className="pt-3 text-end">
                    {toLocalePriceNoDecimal(data.commitment)}
                  </td>
                  <td className="pt-3 text-end">
                    {toLocalePrice(data.upfrontAmount)}
                  </td>
                  <td className="pt-3 text-end">
                    {toLocalePrice(data.totalAmount)}
                  </td>
                  <td className="pt-0 text-center">
                    {data.fundTransferred ? (
                      <div className="fs-small-semibold pt-3">
                        Closed
                      </div>
                    ) : userType === "ADMIN" && !isSubAdmin() ? (
                      <Switch
                        checked={data.fundStatus}
                        onChange={() => handleInvOppSwitch(data.ioRid)}
                        disabled={data.fundTransferred}
                      />
                    ) : data.fundStatus ? (
                      <div className="fs-small-semibold pt-3">
                        Fund Received
                      </div>
                    ) : (
                      <div className="fs-small-semibold pt-3">
                        Fund Not Received
                      </div>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Stack>
    </DrawerLayout>
  );
};

export default ActiveScheme;
