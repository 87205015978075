import { getSubAdminType } from "./LocalStorageUtils";
import { CKYC_FILTER, DEMAT_STATUS_FILTER, INVESTOR_STATUS_FILTER, MCA_FILTER, STAMP_FILTER, USER_TYPE_SUB_ADMIN } from "./constants/constants";

export const toLocalePrice = (value, locale = 'en-IN', currecny = 'INR') => {
    const result = Number(+value).toLocaleString(locale, { currency: currecny, minimumFractionDigits: 2, maximumFractionDigits: 2 });
    return isNaN(parseFloat(result)) ? 0 : result;
}

export const toLocalePriceNoDecimal = (value, locale = 'en-IN', currecny = 'INR') => {
    const result = Number(+value).toLocaleString(locale, { currency: currecny, minimumFractionDigits: 0, maximumFractionDigits: 0 });
    return isNaN(parseFloat(result)) ? 0 : result;
}

export const toCompactPricePrefix = (value, currency = 'INR') => {
    // Use correct currency symbol and format the value
    const currencySymbol = currency === 'USD' ? '$' : '₹'; // Use $ for USD, ₹ for INR
    const locale = currency === 'USD' ? 'en-US' : 'en-IN'; // Locale for USD or INR
    return `${currencySymbol} ${toCompactPrice(value, locale)}`;
  }
  
  export const toCompactPrice = (value, locale = 'en-IN') => {
      const result = Number(+value)
          .toLocaleString(locale, {
              //@ts-ignore
              notation: "compact",
              compactDisplay: "short",
              maximumFractionDigits: 2,
          }).replace('T', 'K'); // Replace 'T' with 'K' for consistency
  
      return isNaN(parseFloat(result)) ? 0 : result;
  }
  

export const handleMuiDatePicker = (selectedDate) => {
    const date = new Date(selectedDate?.$d);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
}

export const formatDateTime = (dateTimeString, flagShowTime) => {
    const date = new Date(dateTimeString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;
    const formattedYear = year;

    let formattedTime = '';
    if (flagShowTime) {
        const formattedHours = hours < 10 ? `0${hours}` : hours;
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
        const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
        formattedTime = ` ${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    }

    return `${formattedDay}/${formattedMonth}/${formattedYear}${formattedTime}`;
}

export const isSubAdmin = () => {
    return getSubAdminType() == USER_TYPE_SUB_ADMIN;
}

export const getInvestorFilterCount = (
    ckyc, mca, stamp, investorStatus, dematCheckBox, dematStatus
) => {
    let count = 0;

    if (ckyc !== CKYC_FILTER.ALL) {
        count++;
    }
    if (mca !== MCA_FILTER.ALL) {
        count++;
    }
    if (stamp !== STAMP_FILTER.ALL) {
        count++;
    }
    if (investorStatus !== INVESTOR_STATUS_FILTER.ALL && investorStatus !== undefined) {
        count++;
    }
    if (dematCheckBox) {
        count++;
    }
    if (dematStatus !== DEMAT_STATUS_FILTER.ALL && dematStatus !== undefined) {
        count++;
    }

    return count;
}

export const isValidNsdldpId = (nsdlDpId) => {
    return /^IN[0-9]{6}$/.test(nsdlDpId);
}

export const isValidCdsldpId = (cdslDpId) => {
    return /^[0-9]{8}$/.test(cdslDpId);
}

export const isValidClientId = (clientId) => {
    return /^[0-9]{8}$/.test(clientId);
}

export const trimSalutationInPan = (name) => {
    if (!name || name.trim() === "") {
        return name;
    }
    // Remove salutation
    let trimmedName = name.replace(/^\s*(mr|mrs|miss|ms|dr|rev)\s*\.?\s+/i, "").trim();

    // Convert to camel case
    return trimmedName
        .split(/\s+/)
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(" ");
}

export const trimSpace = (name) => {
    if (!name || name.trim() === "") {
        return name;
    }
    return name.replace(/\s+/g, " ").trim()
}
export const inrToUsd = (inr, usdPerInr) => {
    // source in INR / INR per dollar
    return (inr / usdPerInr).toFixed(3)
}

// returns string value eg : '150K'
export const convertNotation = (value, usdPerInr, currency = 'INR') => {
    const locale = currency === 'INR' ? 'en-IN' : 'en-US';
    const formattedValue = new Intl.NumberFormat(locale, {
      notation: 'compact',
      compactDisplay: 'short',
    }).format(value);
    return formattedValue.replace('T', 'K');
  };
  
// returns numeric value
export const convertToUsd = (value, usdPerInr) => {
    return parseFloat((value / usdPerInr).toFixed(2));
};

// date should be in YYYY-MM-DD fromat 
export const isValidDate = (date) => {
    try {
        return  !isNaN(new Date(date)) && /^\d{4}-\d{2}-\d{2}$/.test(date);
    } catch (error) {
        return false;
    }
}
