

import React, { useState, useEffect, useCallback } from "react";
import Layout from "../../components/layout/Layout";
import Pagination from "@mui/material/Pagination";
import _ from "lodash";
import { RECORD_DISPLAY_LIMIT } from "../../utils/constants/constants";
import {
  Chip,
  CircularProgress,
  Skeleton,
  Tooltip,
  tooltipClasses,
  Tab,
  Tabs,
  Box,
  Divider,
  FormControl,
  InputLabel,
  ListItemText,
  OutlinedInput,
  Checkbox,
  Select,
  MenuItem,
} from "@mui/material";
import {
  apiDematTableInvestors,
  getDematMasterTable,
} from "../../utils/api/api";
import { styled } from "@mui/material/styles";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SearchIcon from "@mui/icons-material/SearchOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { useSnackbar } from "notistack";
import { SNACKBAR_ERROR } from "../../utils/constants/constants";
import { isSubAdmin } from "../../utils/StringUtil";
import Button from "@mui/material/Button";
import GetAppIcon from "@mui/icons-material/GetApp";
import {
  apiInvestorListDownload,
  getPartners,
  apiGetDematHoldings,
} from "../../utils/api/api";

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    backgroundColor: theme.palette.common.white,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    border: "1px solid #000",
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(1),
  },
}));

export const DematInvestors = ({ dematInvestorList, showTableLoading }) => {
  return (
    <div
      className="d-flex flex-column overflow-y-auto"
      style={{ minWidth: "300px", maxHeight: "500px" }}
    >
      {showTableLoading ? (
        <CircularProgress />
      ) : (
        <>
          {dematInvestorList?.map((investor, index) => (
            <div className="d-flex align-items-center px-2 py-1" key={index}>
              <AccountCircleIcon color="primary" fontSize="large" />
              <div className="d-flex flex-grow-1 flex-column ms-2">
                <div>
                  <span className="fs-med-medium text-dark">
                    {investor?.fullName}
                  </span>
                  <span className="fs-med-medium ms-1">
                    <Chip
                      label={investor?.dematCreditDate ? "CREDITED" : "PENDING"}
                      size="small"
                      color={investor?.dematCreditDate ? "success" : "warning"}
                      variant="outlined"
                      sx={{
                        fontSize: "0.7rem",
                        height: "20px",
                        padding: "4px",
                      }}
                    />
                  </span>
                </div>
                <span className="fs-small-regular text-dark">
                  {investor?.email}
                </span>
                <span className="fs-small-medium text-dark">
                  {investor?.invCode}
                </span>
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

const DematMaster = () => {
  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [dematList, setDematList] = useState([]);
  const [dematDataList, setDematDataList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [dematInvestors, setDematInvestors] = useState([]);
  const [showTableLoader, setShowTableLoader] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [filterPartner, setFilterPartner] = useState([]);
  const [filterScheme, setFilterScheme] = useState([]);
  const [schemeList, setSchemeList] = useState([]);
  const [partnerList, setPartnerList] = useState([]);
  const userType = process.env.REACT_APP_USER_TYPE;
  const { enqueueSnackbar } = useSnackbar();
  const [searchTerm, setSearchTerm] = useState("");
  const [filterCkyc, setFilterCkyc] = useState(-1);
  const [filterMca, setFilterMca] = useState(-1);
  const [filterStamp, setFilterStamp] = useState(-1);
  const [dematFilters, setDematFilters] = useState({});
  const [holdingsList, setHoldingsList] = useState([]);

  const debouncedSearch = useCallback(
    _.debounce((searchValue) => {
      setSearchTerm(searchValue);
    }, 100),
    []
  );

  const handleSearch = (value) => {
    debouncedSearch(value);
  };
  const downloadFile = async (response) => {
    const blob = new Blob([response.data], {
      type: response.headers.get("content-type"),
    });
    const downloadLink = document.createElement("a");
    const url = window.URL.createObjectURL(blob);
    downloadLink.href = url;
    downloadLink.setAttribute(
      "download",
      response.headers.get("content-disposition").split('"')[1]
    );
    downloadLink.click();
    window.URL.revokeObjectURL(url);
  };

  const getDematInvestors = async (params) => {
    setShowTableLoader(true);
    try {
      const res = await apiDematTableInvestors({
        sd: params?.dpType,
        oppRid: params?.oppRid,
        cmRid: params?.cmRid,
      });
      setDematInvestors(res);
      setShowTableLoader(false);
    } catch (error) {
      console.error("Error fetching demat data:", error);
      setShowTableLoader(false);
    }
  };

  const getDematList = async () => {
    const params = {
      page,
      limit: RECORD_DISPLAY_LIMIT
    };
    setIsLoading(true);
    try {
      const data = await getDematMasterTable(params);
      setDematList(data.tableColumn);
      setDematDataList(data.tableData);
      if (typeof data.records === 'number') {
        setTotalRecords(data.records);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching demat data:", error);
      enqueueSnackbar(error, SNACKBAR_ERROR);
      setTotalRecords(0);
      setIsLoading(false);
    }
  };





  const getDematHoldings = async () => {
    setIsLoading(true);
    try {
      const params = {
        page,
        limit: RECORD_DISPLAY_LIMIT,
        invName: searchTerm,
        partnerRids: filterPartner.join(","),
        schemeRids: filterScheme.join(","),
      };

      const response = await apiGetDematHoldings(params);
      setHoldingsList(response.holdingsList);

      if (page === 1 && typeof response.records === "number") {
        setTotalRecords(response.records);
      }

      if (page === 1) {
        const schemes = response.holdingsList.reduce((acc, investor) => {
          investor.dematDetailList.forEach((detail) => {
            if (detail.schemeRid && detail.schemeName) {
              acc.push({ schemeRid: detail.schemeRid, schemeName: detail.schemeName });
            }
          });
          return acc;
        }, []);

        setSchemeList(_.uniqBy(schemes, "schemeRid"));
      }
    } catch (error) {
      console.error("Error fetching demat holdings:", error);
      enqueueSnackbar(error, SNACKBAR_ERROR);
      setTotalRecords(0);
    } finally {
      setIsLoading(false);
    }
  };


  useEffect(() => {
    if (tabIndex === 1) {
      const initialParams = {
        page: 1,
        limit: RECORD_DISPLAY_LIMIT,
      };
      getDematHoldings(initialParams);
    }
  }, [tabIndex]);

  useEffect(() => {
    if (tabIndex === 0) {
      getDematList();
    } else if (tabIndex === 1 && page > 0) {
      getDematHoldings();

    }
  }, [page, filterPartner, filterScheme, searchTerm]);



  const handlePageChange = (_event, value) => {
    setPage(value);
  };
  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  // const downloadInvestorList = (param = {}) => {
  //   param.ckycStatus = filterCkyc < 0 ? null : filterCkyc;
  //   param.mcaStatus = filterMca < 0 ? null : filterMca;
  //   param.stamp = filterStamp < 0 ? null : filterStamp;
  //   param.partnerRid = filterPartner.map((item) => item).join(",");
  //   param.scheme = filterScheme.map((item) => item).join(",");

  //   const reqParams = {
  //     ...param,
  //     ...dematFilters,
  //     isActive:
  //       parseInt(dematFilters?.isActive) === 1
  //         ? true
  //         : parseInt(dematFilters?.isActive) === 0
  //           ? false
  //           : null,
  //     dematPending:
  //       parseInt(dematFilters?.dematPending) === 1
  //         ? true
  //         : parseInt(dematFilters?.dematPending) === 0
  //           ? false
  //           : null,
  //   };

  //   apiInvestorListDownload(reqParams)
  //     .then((data) => {
  //       downloadFile(data);
  //     })
  //     .catch((error) => {
  //       enqueueSnackbar(error, SNACKBAR_ERROR);
  //     });
  // };


  const displayTableValue = (value) => {
    if (value === null || value === undefined || value === '') {
      return '---';
    }
    return value;
  };

  const formatNumber = (number) => {
    if (number === null || number === undefined) {
      return '---';
    }
    return number.toLocaleString() || '---';
  };
  const getPartnersList = useCallback(() => {
    setPartnerList([]);
    getPartners()
      .then((res) => {
        setPartnerList(res.partnerMasterList);
      })
      .catch((e) => {
        enqueueSnackbar(e, SNACKBAR_ERROR);
      });
  }, [enqueueSnackbar]);

  useEffect(() => {
    getPartnersList();
  }, [getPartnersList]);




  useEffect(() => {
    if (tabIndex === 1) {
      setPage(1); // Reset page first
    }
  }, [searchTerm, filterPartner, filterScheme]);

  const styles = {
    stickyColumn: {
      position: "sticky",
      left: 0,
      zIndex: 1,
    },
    stickyTop: {
      position: "sticky",
      top: 0,
      zIndex: 2,
    },
    stickyColumn2: {
      position: "sticky",
      left: "250px",
      zIndex: 1,
    },
    stickyColumn3: {
      position: "sticky",
      left: "400px",
      zIndex: 1,
    },
  };
  const renderTableContent = () => {
    if (tabIndex === 0) {
      return (
        <div className="overflow-x-scroll">
          {dematList?.length > 0 ? (
            <table
              className="table table-striped table-borderless w-100 position-relative"
              id="order-table"
            >
              <thead>
                <tr className="fs-normal-semibold text-center sticky-table-row">
                  <th
                    className="sticky-table-cell"
                    scope="col"
                    style={{ minWidth: "250px" }}
                  >
                    <p className="m-0">Scheme Names</p>
                  </th>
                  {dematList.map((item, key) => (
                    <th
                      key={key}
                      scope="col"
                      style={{ minWidth: "150px" }}
                      className="text-center"
                    >
                      <p
                        className={`${item.dpType === "ESCROW" ? "text-danger" : ""
                          } m-0`}
                      >
                        {item.name} {item.dpType}
                      </p>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="bg-table-blue">
                {dematDataList.map((data, index) => (
                  <tr key={index} className="fs-normal-medium text-center">
                    <td className="sticky-table-cell">{data.schemeName}</td>
                    {data.cells.map((cellData, cellIndex) => (
                      <td className="text-center" key={cellIndex}>
                        {cellData?.dpType === null ? (
                          <span>-</span>
                        ) : cellData?.value === 0 ? (
                          <span
                            className={`${cellData.dpType === "ESCROW" ? "text-danger" : ""
                              }`}
                          >
                            {cellData.value}
                          </span>
                        ) : (
                          <CustomTooltip
                            title={
                              <DematInvestors
                                dematInvestorList={dematInvestors}
                                showTableLoading={showTableLoader}
                              />
                            }
                            arrow
                          >
                            <span
                              className={`${cellData.dpType === "ESCROW"
                                ? "text-danger"
                                : ""
                                }`}
                              style={{ cursor: "pointer" }}
                              onMouseEnter={() => getDematInvestors(cellData)}
                            >
                              {cellData.value}
                            </span>
                          </CustomTooltip>
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>No Data..</p>
          )}
        </div>
      );
    }


    if (tabIndex === 1) {
      return (
        <div className="d-flex overflow-x-auto">
          {!Array.isArray(holdingsList) || holdingsList.length === 0 ? (
            <p>No Data..</p>
          ) : (
            <table
              className="table table-striped table-borderless w-auto"
              id="order-table"
            >
              <thead>
                <tr
                  className="fs-normal-semibold text-center"
                  style={styles.stickyTop}
                >
                  <th
                    className="sticky-column"
                    style={{ ...styles.stickyColumn }}
                  >
                    <p className="m-0">Investor Name</p>
                  </th>
                  <th
                    className="sticky-column"
                    style={{ ...styles.stickyColumn2 }}
                  >
                    <p className="m-0">DP ID</p>
                  </th>
                  <th
                    className="sticky-column"
                    style={{ ...styles.stickyColumn3 }}
                  >
                    <p className="m-0">Client ID</p>
                  </th>
                  <th scope="col">
                    <p className="m-0">Scheme Name</p>
                  </th>
                  <th scope="col">
                    <p className="m-0">Class</p>
                  </th>
                  <th scope="col">
                    <p className="m-0">No of Units</p>
                  </th>
                  <th scope="col">
                    <p className="m-0">Demat Status</p>
                  </th>
                </tr>
              </thead>
              <tbody className="bg-table-blue">
                {holdingsList.map((investor, index) =>
                  investor.dematDetailList.map((detail, detailIndex) => (
                    <tr
                      key={`${investor.invRid}-${detailIndex}`}
                      className="fs-normal-medium text-center"
                    >
                      {detailIndex === 0 ? (
                        <>
                          <td
                            className="sticky-column"
                            style={{ ...styles.stickyColumn, minWidth: "250px" }}
                          >
                            {displayTableValue(investor.invName)}
                          </td>
                          <td
                            className="sticky-column"
                            style={{ ...styles.stickyColumn2, minWidth: "150px" }}
                          >
                            {displayTableValue(investor.dpId)}
                          </td>
                          <td
                            className="sticky-column"
                            style={{ ...styles.stickyColumn3, minWidth: "150px" }}
                          >
                            {displayTableValue(investor.clientId)}
                          </td>
                        </>
                      ) : (
                        <>
                          <td
                            className="sticky-column"
                            style={{ ...styles.stickyColumn, minWidth: "250px" }}
                          ></td>
                          <td
                            className="sticky-column"
                            style={{ ...styles.stickyColumn2, minWidth: "150px" }}
                          ></td>
                          <td
                            className="sticky-column"
                            style={{ ...styles.stickyColumn3, minWidth: "150px" }}
                          ></td>
                        </>
                      )}
                      <td style={{ minWidth: "200px" }}>{displayTableValue(detail.schemeName)}</td>
                      <td style={{ minWidth: "150px" }}>{displayTableValue(detail.className)}</td>
                      <td style={{ minWidth: "150px", textAlign: "center" }}>
                        {formatNumber(detail.noOfUnits)}
                      </td>
                      <td style={{ minWidth: "200px" }}>
                        <Chip
                          label={detail.dematStatus}
                          size="small"
                          color={
                            detail.dematStatus === "Credited"
                              ? "success"
                              : "warning"
                          }
                          variant="outlined"
                        />
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          )}
        </div>
      );
    }
  };

  return (
    <Layout title="Demat">
      <Box paddingTop="10px" display="flex" alignItems="center">
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="DEMAT MASTER" />
          <Tab label="DEMAT HOLDINGS" />
        </Tabs>
      </Box>
      <Divider style={{ height: "1px", backgroundColor: "#000" }} />
      {tabIndex === 1 && (
        <div
          className="d-flex flex-row justify-content-between bg-white p-3 mb-4"
          style={{ borderRadius: "5px" }}
        >
          <div className="d-flex align-items-center gap-2 flex-wrap">
            <div
              className="search-input-btn d-flex flex-row justify-content-between rounded bg-white px-3"
              style={{
                border: "1px solid #000",
                minWidth: "200px",
                flex: "1 1 auto",
              }}
            >
              <div className="my-auto">
                <SearchIcon sx={{ cursor: "pointer" }} />
              </div>
              <input
                type="text"
                value={searchTerm}
                onChange={(e) => handleSearch(e.target.value)} // Changed here
                className="form-control custom-form-control border-0 bg-transparent fs-large-regular"
                placeholder="Search"
              />
              <div onClick={() => setSearchTerm("")} className="my-auto">
                <CloseIcon sx={{ cursor: "pointer" }} />
              </div>
            </div>
            <div className="d-flex gap-2 flex-wrap">
              {userType === "ADMIN" && (
                <FormControl
                  sx={{
                    width: { xs: "100%", sm: 180 },
                    minWidth: "180px",
                    "@media (max-width: 600px)": {
                      width: "100%",
                    },
                  }}
                  size="small"
                >
                  <InputLabel id="demo-multiple-checkbox-label-1">
                    Filter By Partner
                  </InputLabel>
                  <Select
                    multiple
                    labelId="demo-multiple-checkbox-label-1"
                    id="demo-multiple-checkbox-1"
                    value={filterPartner}
                    onChange={(e) => setFilterPartner(e.target.value)}
                    input={<OutlinedInput label="Filter By Partner" />}
                    renderValue={(selected) => `${selected.length} Selected`}
                  >
                    {partnerList.map((data, key) => (
                      <MenuItem key={key} value={data.partnerRid}>
                        <Checkbox
                          checked={filterPartner.indexOf(data.partnerRid) > -1}
                        />
                        <ListItemText primary={data.fullName} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
              {userType === "ADMIN" && (
                <FormControl
                  sx={{
                    width: { xs: "100%", sm: 180 },
                    minWidth: "180px",
                    "@media (max-width: 600px)": {
                      width: "100%",
                    },
                  }}
                  size="small"
                >
                  <InputLabel id="demo-multiple-checkbox-label-2">
                    Filter By Scheme
                  </InputLabel>
                  <Select
                    multiple
                    labelId="demo-multiple-checkbox-label-2"
                    id="demo-multiple-checkbox-2"
                    value={filterScheme}
                    onChange={(e) => setFilterScheme(e.target.value)}
                    input={<OutlinedInput label="Filter By Scheme" />}
                    renderValue={(selected) => `${selected.length} Selected`}
                  >
                    {schemeList.map((scheme, index) => (
                      <MenuItem key={index} value={scheme.schemeRid}>
                        <Checkbox
                          checked={filterScheme.indexOf(scheme.schemeRid) > -1}
                        />
                        <ListItemText primary={scheme.schemeName} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
              {/* {userType === "ADMIN" && !isSubAdmin() && (
                <Button
                  sx={{ textTransform: "none" }}
                  onClick={(e) => downloadInvestorList()}
                  startIcon={<GetAppIcon />}
                >
                  <span className="fs-med-regular">Export to Excel</span>
                </Button>
              )} */}
            </div>
            <div style={{ flex: 1, width: "900px" }}></div>
            <div className="ms-auto d-flex align-items-center">

              <Pagination
                color="secondary"
                size="small"
                siblingCount={0}
                onChange={handlePageChange}
                count={Math.max(1, totalRecords > 0 ? Math.ceil(totalRecords / RECORD_DISPLAY_LIMIT) : 1)}
                page={page}
              />
            </div>
          </div>
        </div>
      )}
      <div className="d-flex flex-column bg-white overflow-hidden">
        <div
          className="d-flex flex-grow-1 bg-white py-2 px-3 overflow-y-scroll "
          style={{ borderRadius: "5px" }}
        >
          {isLoading
            ? [...Array(RECORD_DISPLAY_LIMIT)].map((_e, index) => (
              <Skeleton
                animation="wave"
                key={index}
                style={{ marginBottom: 6 }}
                variant="rectangle"
                height={40}
              />
            ))
            : renderTableContent()}
        </div>
      </div>
    </Layout>
  );
};

export default DematMaster;

