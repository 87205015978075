import React, {useState, useEffect} from 'react';
import DialogLayout from '../../components/layout/DialogLayout';
import { apiInvestorOpportunity, apiUnitAllotment } from '../../utils/api/api';
import { SNACKBAR_ERROR, SNACKBAR_SUCCESS } from "../../utils/constants/constants";
import { useSnackbar } from 'notistack';
import { Checkbox, IconButton, Stack, TextField, Tooltip } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { useHistory } from "react-router-dom";

const UnitAllotDocs = ({ open, toggleDialog, scheme, files, handleSave }) => {
    const history = useHistory();
    const [investorList, setInvestorList] = useState([]);
    const [filteredInvList, setFilteredInvList] = useState([]);
    const { enqueueSnackbar } = useSnackbar();
    const [actionLoader, setActionLoader] = useState(false);

    const handleOverrideCheck = (ioRid) => {
      const updatedItems = filteredInvList.map(item =>
        item.ioRid === ioRid ? { ...item, override: !item.override } : item
      );
      setFilteredInvList(updatedItems);
    }

	const uploadDocuments = () => {
		const formData = new FormData();
		const selectedInvList = filteredInvList.filter(item => {
			if ((item.status === 'SUCCESS') || (item.status === 'REPLACE' && item.override)) {
				return true;
			}
			return false;
		});
		
		formData.append('body', JSON.stringify({"investorOpportunity": selectedInvList, "oppRid": scheme.oppRid}));
    
    // const uniqueSelectedInvList =selectedInvList.filter((user, index, self) =>
    //   index === self.findIndex((t) => (
    //     t.invCode === user.invCode
    //   ))
    // )
		selectedInvList.filter((user, index, self) =>
      index === self.findIndex((t) => (
        t.invCode === user.invCode
      ))
    ).map(item =>{
			  formData.append('files', item.file);
		})

		setActionLoader(true);
		apiUnitAllotment(formData)
      .then((res) => {
        setActionLoader(false);
          enqueueSnackbar(res,SNACKBAR_SUCCESS);
          handleSave();
      })
      .catch((error) => {
        setActionLoader(false);
          enqueueSnackbar(error,SNACKBAR_ERROR);
      });
	}

    const getInvestorOpportunity = (param = {}) => {
        param.oppRid = scheme.oppRid;
        apiInvestorOpportunity(param)
            .then((data) => {
                setInvestorList(data.investorOpportunities);
            })
            .catch((error) => {
                enqueueSnackbar(error,SNACKBAR_ERROR);
            });
      }

      useEffect(() => {
        const mergeList = investorList.map(io => {
			const matchingFile = files?.find(f => f.name.substr(0, 8).trim() === io.invCode);
			if (matchingFile) {
				return {
					"ioRid": io.ioRid,
					"invFullName": io.invFullName,
					"invCode": io.invCode,
          "fileName": matchingFile?.name,
					"file": matchingFile,
					"override": false,
					"status": io.invOppFiles === null || Object.keys(io.invOppFiles).length === 0 ? 'SUCCESS' : 'REPLACE',
				}
			} else {
				return {
					"ioRid": io.ioRid,
					"invFullName": io.invFullName,
					"invCode": io.invCode,
          "fileName": null,
					"file": null,
					"override": false,
					"status": io.invOppFiles === null || Object.keys(io.invOppFiles).length === 0 ? 'ERROR' : 'WARNING',
				}
			}
        });
    
        setFilteredInvList(mergeList);
      }, [investorList]);

      useEffect(() => {
        getInvestorOpportunity();
        // setFilteredInvList([]);
      }, [open]);

  return (
    <DialogLayout
      open={open}
      title="Attachments"
      actionLoader={actionLoader}
      closeAction={toggleDialog}
      negativeAction={
        {label: 'Cancel', onClick : toggleDialog}
      }
      positiveAction={
        {label: 'Upload', onClick : uploadDocuments}
      }>
        <Stack direction="column" spacing={2}>
        <table className="table table-striped table-borderless w-100">
            <thead>
              <tr className="fs-normal-semibold text-center">
                <th scope="col">
                  <p className="mb-2">Investor Name</p>
                </th>
                <th scope="col">
                  <p className="mb-2">Investor Code</p>
                </th>
				<th scope="col">
                  <p className="mb-2">Selected File</p>
                </th>
                <th scope="col">
                  <p className="mb-2">Status</p>
                </th>
              </tr>
            </thead>
            <tbody>
              { filteredInvList.map((data, key) => {
                return(
                  <tr className="fs-normal-medium text-center" key={key}>
                    <td className="pt-3">{data.invFullName}</td>
                    <td className="pt-3">{data.invCode}</td>
					<td className="pt-0 my-auto">
						{data.file ?
						<Tooltip title={data.file.name}>
							<IconButton color="secondary"><AttachFileIcon fontSize="small" /></IconButton>
						</Tooltip> : ''}
					</td>
                    <td className="pt-3">
						{data.status === 'SUCCESS' ? <i className="fs-normal-medium text-success">success</i> : ''}
						{data.status === 'REPLACE' ? <i className="fs-normal-medium text-warning"><Checkbox size="small" color="secondary" checked={data.override} onChange={()=> handleOverrideCheck(data.ioRid)} />do you want to replace?</i> : ''}
						{data.status === 'ERROR' ? <i className="fs-normal-medium text-danger">file not found</i> : ''}
						{data.status === 'WARNING' ? <i className="fs-normal-medium text-warning">file already exist</i> : ''}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Stack>
      </DialogLayout>
  );
}

export default UnitAllotDocs;