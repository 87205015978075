import Layout from "../../../components/layout/Layout";
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/SearchOutlined";
import Pagination from "@mui/material/Pagination";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { IconButton, Skeleton } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import { getPortfolioCompanies } from "../../../utils/api/api";
import PortfolioCompanyModal from "./PortfolioCompanyModal";
import PortfolioCompDeleteModal from "./PortfolioCompDeleteModal";
import {
  RECORD_DISPLAY_LIMIT,
  SNACKBAR_ERROR,
} from "../../../utils/constants/constants";
import CloseIcon from "@mui/icons-material/Close";
import { useSnackbar } from "notistack";
import { isSubAdmin } from "../../../utils/StringUtil";

const PortfolioCompany = () => {
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [companyList, setCompanyList] = useState([]);
  const [editCompany, setEditCompany] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const userType = process.env.REACT_APP_USER_TYPE;

  const handleOpen = () => {
    setOpen(true);
    setEditCompany(null);
  };

  const handleEdit = (id) => {
    setEditCompany(companyList.find((item) => item.compRid === id));
    setOpen(true);
    setIsEdit(true);
  };

  const handleDelete = (id) => {
    setEditCompany(companyList.find((item) => item.compRid === id));
    setOpenDelete(true);
    setIsEdit(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenDelete(false);
    setIsEdit(false);
    setEditCompany({});
  };

  const getCompanies = (params = {}) => {
    setIsLoading(true);
    params.recordCount = RECORD_DISPLAY_LIMIT;
    getPortfolioCompanies(params)
      .then((data) => {
        setIsLoading(false);
        setCompanyList(data.companyList);
        if (page === 1) {
          setTotalRecords(data.records);
        }
      })
      .catch((e) => {
        setIsLoading(false);
        enqueueSnackbar(e, SNACKBAR_ERROR);
      });
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleClearSearch = () => {
    setSearchTerm("");
  };

  useEffect(() => {
    if (searchTerm.length < 3) {
      if (searchTerm.trim() === "") {
        getCompanies();
      } else {
        return;
      }
    } else {
      getCompanies({ name: searchTerm.trim() });
    }

    setPage(1);
  }, [searchTerm]);

  useEffect(() => {
    getCompanies({ page: page, name: searchTerm });
  }, [page]);

  const handlePageChange = (_event, value) => {
    setPage(value);
  };

  const handleSave = () => {
    setPage(1);
    getCompanies();
    handleClose();
  };

  useEffect(() => {
    getCompanies({ page: 1 });
  }, []);

  return (
    <Layout title="Portfolio Companies">
      {userType === "ADMIN" && !isSubAdmin() && (
        <Button
          sx={{
            height: "64px",
            borderRadius: "50%",
            position: "fixed",
            bottom: "20px",
            right: "20px",
          }}
          onClick={handleOpen}
          variant="contained"
          aria-label="edit"
          color="secondary"
        >
          <AddIcon />
        </Button>
      )}

      <PortfolioCompanyModal
        open={open}
        onClose={handleClose}
        isEdit={isEdit}
        company={editCompany}
        handleSave={() => handleSave()}
      />
      <PortfolioCompDeleteModal
        open={openDelete}
        onClose={handleClose}
        company={editCompany}
        handleSave={() => handleSave()}
      />

      <div className="p-4">
        <div
          className="d-flex flex-row justify-content-between bg-white p-3 mb-4"
          style={{ borderRadius: "5px" }}
        >
          <div
            className="search-input-btn d-flex flex-row justify-content-between rounded bg-white px-3"
            style={{ border: "1px solid #000" }}
          >
            <div className="my-auto">
              <SearchIcon sx={{ cursor: "pointer" }} />
            </div>
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => handleSearch(e)}
              className="form-control custom-form-control border-0 bg-transparent fs-large-regular "
              placeholder="Search by Name"
            />
            <div onClick={() => handleClearSearch()} className="my-auto">
              <CloseIcon sx={{ cursor: "pointer" }} />
            </div>
          </div>
          <div className="justify-content-evenly d-flex align-items-center">
            <Pagination
              color="secondary"
              size="small"
              siblingCount={1}
              onChange={handlePageChange}
              count={
                totalRecords < RECORD_DISPLAY_LIMIT
                  ? 1
                  : Math.ceil(totalRecords / RECORD_DISPLAY_LIMIT)
              }
              page={page}
            />
          </div>
        </div>

        <div className="bg-white py-2 px-3" style={{ borderRadius: "5px" }}>
          {isLoading ? (
            [...Array(RECORD_DISPLAY_LIMIT)].map((_e, index) => (
              <Skeleton
                animation="wave"
                key={index}
                style={{ marginBottom: 6 }}
                variant="rectangle"
                height={40}
              />
            ))
          ) : (
            <table
              className="table table-striped table-borderless w-100"
              id="order-table"
            >
              <thead>
                <tr className="fs-normal-semibold text-center">
                  {!isSubAdmin() && 
                  <th scope="col">
                  <p className="mb-2">Actions</p>
                </th>
                  }                 
                  <th scope="col">
                    <p className="mb-2">Company Name</p>
                  </th>
                  <th scope="col">
                    <p className="mb-2">Sector</p>
                  </th>
                  <th scope="col">
                    <p className="mb-2">Investment Round</p>
                  </th>
                </tr>
              </thead>
              {companyList ? (
                <tbody className="bg-table-blue">
                  {companyList.map((item, index) => {
                    return (
                      <tr className="fs-normal-medium text-center" key={index}>
                        {!isSubAdmin() && (
                        <td className="p-0">
                            <div className="d-flex">
                              <IconButton
                                onClick={() => handleEdit(item.compRid)}
                                aria-label="edit"
                              >
                                <EditIcon className="text-blue" />
                              </IconButton>
                              <IconButton
                                aria-label="delete"
                                onClick={() => {
                                  handleDelete(item.compRid);
                                }}
                              >
                                <DeleteOutlineIcon className="text-danger" />
                              </IconButton>
                            </div>
                        </td>
                        )}
                        <td className="pt-3">{item.title}</td>
                        <td className="pt-3">{item.sector}</td>
                        <td className="pt-3">{item.invRound}</td>
                      </tr>
                    );
                  })}
                </tbody>
              ) : (
                <p>No Data..</p>
              )}
            </table>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default PortfolioCompany;
