import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, ComposedChart, Line } from 'recharts';
import ChartCard from '../../components/dashboard/ChartCard';
import { convertNotation, convertToUsd } from '../../utils/StringUtil';
import CustomAmountTooltip from './components/CustomTooltip';

const Revenue = ({ currency: parentCurrency, revenueList, inrAgainstUsd }) => {

  // Map Revenue data depending on the USD conversion
  const formatList = (list) => {
    if (parentCurrency === 'USD') {
      return list.map((item) => ({
        ...item,
        totalRevenue: convertToUsd(item.totalRevenue, inrAgainstUsd),
        cumulativeTotalRevenue: convertToUsd(item.cumulativeTotalRevenue, inrAgainstUsd),
        netTotalRevenue: convertToUsd(item.netTotalRevenue, inrAgainstUsd),
        cumulativeNetRevenue: convertToUsd(item.netTotalRevenue, inrAgainstUsd),
      }));
    }
    return list;
  };

  const formattedList = formatList(revenueList);

  return (
    <div className="container-fluid py-4">
      <div className='row'>
        <div className="col-md-8 px-5" style={{ width: "80%" }}>
          <ChartCard
            title="Total Revenue Generated"
            chartInfo={[
              { name: "Cumulative Total Revenue", color: "#33006F" },
              { name: "MOM Total Revenue", color: "#dda0dd" },
            ]}
          >
            <ResponsiveContainer width="100%" height={300}>
              <ComposedChart data={formattedList}>
                <XAxis dataKey="month" tick={{ fontSize: 10, fontWeight: 'bold' }} />
                <YAxis yAxisId="left"
                  label={{ 
                    value: 'Total Revenue', 
                    angle: -90, 
                    fontWeight: 'bold', 
                    position: 'outsideCenter', 
                    fill: "#dda0dd", 
                    dx: -25 ,
                    fontSize: 15,
                  }}
                  stroke="#dda0dd"
                  tickFormatter={(value) => convertNotation(value, inrAgainstUsd, parentCurrency)}
                  fontSize={13}
                />
                <YAxis 
                  yAxisId="right"
                  orientation="right"
                  label={{ 
                    value: 'Cumulative Total Revenue', 
                    angle: -90, 
                    fontWeight: 'bold', 
                    position: 'outsideRight', 
                    fill: "#33006F", 
                    dx: 29,
                    fontSize: 15,
                  }}
                  stroke="#33006F"
                  tickFormatter={(value) => convertNotation(value, inrAgainstUsd, parentCurrency)}
                  fontSize={13}
                  />
                <Tooltip
                  content={
                    <CustomAmountTooltip
                      parentCurrency={parentCurrency}
                    />
                  }
                />
                <Bar yAxisId="left" dataKey="totalRevenue" barSize={40} fill="#dda0dd" name="MOM Total Revenue" />
                <Line yAxisId="right" type="monotone" dataKey="cumulativeTotalRevenue" fill="#33006F" stroke="#33006F" name='Cumulative Total Revenue' strokeWidth={1} dot={{ r: 2 }} />
              </ComposedChart>
            </ResponsiveContainer>
          </ChartCard>
        </div>
      </div>

      <div className='row'>
        <div className="col-md-8 px-5" style={{ width: "80%" }}>
          <ChartCard
            title="Net Revenue Generated"
            chartInfo={[
              { name: "Cumulative Net Revenue", color: "#33006F" },
              { name: "MOM Net Revenue", color: "#b19cd9" },
            ]}
          >
            <ResponsiveContainer width="100%" height={300}>
              <ComposedChart data={formattedList}>
                <XAxis dataKey="month" tick={{ fontSize: 10, fontWeight: 'bold' }} />
                <YAxis 
                  yAxisId="left"
                  label={{ 
                    value: 'Net Revenue', 
                    angle: -90, 
                    fontWeight: 'bold', 
                    position: 'outsideCenter', 
                    fill: "#b19cd9", 
                    dx: -25 ,
                    fontSize: 15,
                  }}
                  stroke="#b19cd9"
                  tickFormatter={(value) => convertNotation(value, inrAgainstUsd, parentCurrency)}
                  fontSize={13}
                />
                <YAxis 
                  yAxisId="right"
                  orientation="right"
                  label={{ 
                    value: 'Cumulative Net Revenue', 
                    angle: -90, 
                    fontWeight: 'bold', 
                    position: 'outsideRight', 
                    fill: "#33006F", 
                    dx: 29,
                    fontSize: 15,
                  }}
                  stroke="#33006F"
                  tickFormatter={(value) => convertNotation(value, inrAgainstUsd, parentCurrency)}
                  fontSize={13}
                />
                <Tooltip
                  content={
                    <CustomAmountTooltip
                      parentCurrency={parentCurrency}
                    />
                  }
                />
                <Bar 
                    yAxisId="left" 
                    dataKey="netTotalRevenue" 
                    barSize={40} 
                    fill="#b19cd9" 
                    name="MOM Net Revenue" 
                />
                <Line 
                    yAxisId="right" 
                    type="monotone" 
                    dataKey="cumulativeTotalRevenue" 
                    fill="#33006F" 
                    stroke="#33006F" 
                    name='Cumulative Net Revenue' 
                    strokeWidth={1} 
                    dot={{ r: 2 }} 
                />
              </ComposedChart>
            </ResponsiveContainer>
          </ChartCard>
        </div>
      </div>
    </div>
  );
};

export default Revenue;
