import "../../scss/custom.scss";
import { createTheme } from "@mui/material";
import { ThemeProvider } from "@emotion/react";

const AdminLayout = ({ children }) => {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#772396",
        // main: '#FF0000'
      },
    },
  });
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default AdminLayout;
