import React, { useState } from "react";
import DialogLayout from "../../components/layout/DialogLayout";
import { Box, CircularProgress, Switch } from "@mui/material";
import { apiEnablePartnerLogin } from "../../utils/api/api";
import { SNACKBAR_ERROR, SNACKBAR_SUCCESS } from "../../utils/constants/constants";
import { useSnackbar } from "notistack";
import { formatDateTime } from "../../utils/StringUtil";

const PartnerLogin = ({ open, toggleDialog, partner, handleSave }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handlePartnerEnabled = (value) => {
    setIsLoading(true);
    apiEnablePartnerLogin({
      partnerRid: partner?.partnerRid,
      enable: value ? 1 : 0,
    })
      .then((data) => {
        setIsLoading(false);
        enqueueSnackbar(data, SNACKBAR_SUCCESS);
        handleSave();
        toggleDialog();
      })
      .catch((error) => {
        setIsLoading(false);
        enqueueSnackbar(error, SNACKBAR_ERROR);
      });
  };

  return (
    <DialogLayout
      open={open}
      title="Partner Login"
      subtitle={partner?.fullName}
      maxWidth="xs"
      closeAction={toggleDialog}
      positiveAction={{ label: "Cancel", onClick: toggleDialog }}
    >
      {isLoading ? (
        <CircularProgress />
      ) : (
        <div className="d-flex gap-3 flex-column">
          <Box sx={{ flex: 1 }}>
            <p className="section-label mb-1" style={{ fontSize: "12px" }}>
              Login Enabled
            </p>
            <p className="fs-med-medium p-0 m-0">
              <Switch
                name="mcaStatus"
                color="secondary"
                checked={partner?.loginEnabled}
                onChange={(e) => handlePartnerEnabled(e.target.checked)}
              />
            </p>
          </Box>
          <Box sx={{ flex: 1 }}>
            <p className="section-label mb-1" style={{ fontSize: "12px" }}>
              Enabled On
            </p>
            <p className="fs-med-medium p-0 m-0">
              {partner?.loginEnabledAt
                ? formatDateTime(partner?.loginEnabledAt, true)
                : "---"}
            </p>
          </Box>
        </div>
      )}
    </DialogLayout>
  );
};

export default PartnerLogin;
