import React, { useEffect, useState } from "react";
import DrawerLayout, { ScoreBoard } from '../../components/layout/DrawerLayout';
import { Autocomplete, Badge, Box, Button, Checkbox, Chip, CircularProgress, Divider, FormControlLabel, IconButton, Radio, RadioGroup, Stack, Switch, TextField, Tooltip, tooltipClasses } from "@mui/material";
import {  apiDeleteNomination, apiGetSchemeLaunch, apiNomination, apiSaveFunds, apiSaveNomination, apiSchemeLaunch, getDataDictionaryValues, getInvestors, uploadFile } from "../../utils/api/api";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { BASE_URL, DD_TYPE_MCA_DEL_REASON, DD_TYPE_NOMINATION_DEL_REASON, DD_TYPE_SOURCE_TYPE, SNACKBAR_ERROR, SNACKBAR_SUCCESS, SNACKBAR_WARNING } from "../../utils/constants/constants";
import { useSnackbar } from 'notistack';
import { formatDateTime, toLocalePrice, toLocalePriceNoDecimal } from "../../utils/StringUtil";
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { RemoveCircle } from "@mui/icons-material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import { styled } from '@mui/material/styles';
import DialogLayout from "../../components/layout/DialogLayout";


const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    backgroundColor: theme.palette.common.white,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    border: '1px solid #000',
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(1),
  },
}));

const NominationDrawer = ({ open, toggleDrawer, investor }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [nominationList, setNominationList] = useState([]);
  const nominationObject={
    "isEdit":true,
    "inRid": null,
    "invRid": investor?.invRid,
    "signStatus": 0,
    "triggerEmail": false,
    "showSignStatus":false,
    "nominationCompleted": false,
    "isFileUploading": false,
    "nominationFiles":[],
    "nominationFilesObj":[],
    "emailSent":0,

  }

  const [editNomination,setEditNomination]=useState(null);
  const [reasonsList, setReasonsList] = useState([]);
  const [selReason, setSelReason] = useState(null);
  const [openNominationDelDialog,setOpenNominationDelDialog]=useState(false);
  const [actionLoader, setActionLoader] = useState(false);
  const userType = process.env.REACT_APP_USER_TYPE;

  const handleReasonChange = (event) => {
    const selectedIndex = parseInt(event.target.value);
    const selectedReason = reasonsList.find((item) => item.ddIndex === selectedIndex);
    setSelReason(selectedReason);
  };

  const handleRemoveNomination=(index)=>{
    const newList=[...nominationList];
    newList.splice(index,1);
    setNominationList(newList);
  }

  const handleAddNomination=()=>{
    setNominationList([...nominationList,nominationObject]);
  }

  const handleDeleteNomination = (nomination) => {
    setActionLoader(true);
    apiDeleteNomination({
      "inRid": editNomination,
      "deleteReason": selReason?.ddValue,
      "deleteDdIndex": selReason?.ddIndex
    })
      .then((data) => {
        setActionLoader(false);
        enqueueSnackbar(data, SNACKBAR_SUCCESS);
        toggleNominationDelDialog();
        getNominationDetails();
      })
      .catch((error) => {
        setActionLoader(false);
        enqueueSnackbar(error, SNACKBAR_ERROR);
      });
  }

  

  const toggleNominationDelDialog = (inRid) => {
    if (openNominationDelDialog) {
      setEditNomination(null);
      setOpenNominationDelDialog(false);
    } else {
      setEditNomination(inRid);
      setOpenNominationDelDialog(true);
    }
  }

  const handleIsEdit = (index) => {
    const newList = [...nominationList];
    newList[index].isEdit = true;
    setNominationList(newList);
  }

  const handleNominationData = (event, index) => {
    const { name, value } = event.target;
    const newList = [...nominationList];
    if (name === 'triggerEmail') {
      newList[index].triggerEmail = !newList[index].triggerEmail;
    } else if (name === 'signStatus') {
      newList[index].signStatus = newList[index].signStatus ? 0 : 1;
      newList[index].triggerEmail = newList[index].signStatus ? false : newList[index].triggerEmail;
    } else {
      newList[index][name] = value;
    }
    setNominationList(newList);
  }
  const uploadNominationDocument = (e, index) => {
    const newList = [...nominationList];
    newList[index].isFileLoading = true;
    setNominationList(newList);
    uploadFile(e.target.files[0])
      .then((res) => {
        const newList = [...nominationList];
        newList[index].isFileLoading = false;
        newList[index].nominationFiles = [...newList[index].nominationFiles, res.data];
        setNominationList(newList);
      })
      .catch((e) => {
        const newList = [...nominationList];
        newList[index].isFileLoading = false;
        setNominationList(newList);

        enqueueSnackbar(e, SNACKBAR_ERROR);
      });
  }
  
const deleteNominationDocument=(index,fileIndex)=>{
  const newList=[...nominationList];
  const updatedNominationFiles=[...newList[index].nominationFiles];
  updatedNominationFiles.splice(fileIndex,1);
  newList[index].nominationFiles=updatedNominationFiles;
  setNominationList(newList);
}

  const getNominationDetails=()=>{
   
    apiNomination({invRid:investor?.invRid})
    .then((data)=>{
      setIsLoading(false);
      const NominationList =data.nominations;
      const updatedNominationList=NominationList.map((item)=>({
        ...item,
        isEdit:false,
        isFileUploading: false,
        inRid: item?.inRid,
          invRid: item?.invRid,
          signStatus: item?.signStatus,
          SignDatetime: item?.SignDatetime,
          emailSent: item?.emailSent,
          showSignStatus:false,
         
          nominationCompleted: item?.signStatus === 1 ? true : false,
        
          nominationFiles:item?.nominationFiles?JSON.parse(item.nominationFiles):[],
      }));
      setNominationList(updatedNominationList);
    })

  }

  const getDataDictionary = () => {
    getDataDictionaryValues()
      .then((data) => {
        const reasons = data.filter((item) =>
          item.ddType === DD_TYPE_MCA_DEL_REASON
        );
        setSelReason(reasons.length > 0 ? reasons[0] : null);
        setReasonsList(reasons);
      })
      .catch((e) => {
        console.log(e);
      })
  }

  useEffect(()=>{
    if(open){
      setNominationList([]);
      setSelReason(null);
      getDataDictionary();
      if(investor?.invPref !==0){
        getNominationDetails();
      }
    }
  },[open])

  const handleSaveNomination = (index) => {

    const newList = [...nominationList];
    newList[index].isLoading = true;
    setNominationList(newList);

    const nomination = nominationList[index];
    apiSaveNomination({
      ...nomination,
      nominationFilesObj: nomination.nominationFiles,
      nominationFiles:null
    })
      .then((data) => {
        const newList = [...nominationList];
        newList[index].isLoading = false;
        setNominationList(newList);
        enqueueSnackbar(data, SNACKBAR_SUCCESS);
        getNominationDetails();
      })
      .catch((error) => {
        const newList = [...nominationList];
        newList[index].isLoading = false;
        setNominationList(newList);
        enqueueSnackbar(error, SNACKBAR_ERROR);
      });
  }


  return (
    <DrawerLayout
      open={open}
      title="Nomination"
      subtitle={investor?.fullName}
      drawerWidth="50%"
      closeAction={toggleDrawer}
      positiveAction={
        { label: 'Close', onClick: toggleDrawer }
      }
    >
      <DialogLayout
        open={openNominationDelDialog}
        title="Reason for Deletion"
        maxWidth="sm"
        actionLoader={actionLoader}
        closeAction={toggleNominationDelDialog}
        negativeAction={
          { label: 'Cancel', onClick: toggleNominationDelDialog }
        }
        positiveAction={{ label: 'Delete', onClick: handleDeleteNomination }}
      >
        <Stack sx={{ flex: 1 }} direction="column" spacing={1}>
  <h3 className="fs-large-semibold text-danger">Select Reason for Nomination Deletion</h3>
  <RadioGroup
    aria-labelledby="demo-radio-buttons-group-label"
    name="radio-buttons-group"
    value={selReason?.ddIndex || ''}
    onChange={handleReasonChange}
  >
    {/* the voided contract option is not needed in nomination
    in future in case much difference is there in mca and nomination  delete reasons
    you can create independent list for each in backend */}
    
    {
      reasonsList
        .filter(item => item.ddValue !== 'Voided Contract') 
        .map((item, key) => (
          <FormControlLabel value={item.ddIndex} control={<Radio />} label={item.ddValue} key={key} />
        ))
    }
  </RadioGroup>
</Stack>

      </DialogLayout>

      { (isLoading ? <CircularProgress /> :
          <div className="d-flex gap-3 flex-column">
            {
              nominationList.map((data, key) => (
                <div className="d-flex gap-1" key={key}>
                  <div className="d-flex flex-grow-1 card p-3">
                    <Stack direction="column" spacing={2}>
                      {data.signStatus !== 1 && (data.isEdit) && (
                        <div className="d-flex gap-4 align-items-center">
                          {
                            data.emailSent
                              ? <span className="fs-med-medium text-success">Mail Sent</span>
                              : <FormControlLabel
                                control={<Checkbox name="triggerEmail" size="small" checked={data.triggerEmail} onChange={(e) => handleNominationData(e, key)} disabled={data.nominationCompleted} />}
                                label={<span className="fs-med-medium">Send Nomination Email</span>}
                              />
                          }
                          {
                            data.emailSent
                              ? <FormControlLabel
                                control={<Checkbox name="triggerEmail" size="small" checked={data.triggerEmail} onChange={(e) => handleNominationData(e, key)} disabled={data.nomintionCompleted} />}
                                label={<span className="fs-med-medium">Resend</span>}
                              />
                              : ''
                          }
                        </div>
                      )}
                      <Stack direction="row" spacing={2}>
                        <Box sx={{ flex: 1 }}>
                          <div className="d-flex gap-2 align-items-center">
                            <p className="section-label mb-0" style={{ fontSize: '12px' }}>Nomination SIGNED?</p>
                            {data.envelopeId ?
                              <CustomTooltip title={
                                <div className="d-flex gap-0 flex-column w-100">
                                  <p className="fs-small-medium text-primary p-0 m-0">Nomination Envelop ID:</p>
                                  <p className="fs-normal-medium text-dark p-0 m-0">{data.envelopeId}</p>
                                </div>
                              } placement="right" arrow>
                                <InfoOutlinedIcon sx={{ fontSize: '16px' }} />
                              </CustomTooltip> : ''}
                          </div>
                          {
                            
                                data.isEdit && !data.blockUserUpdate
                                  ?
                                  <Switch name="signStatus" color="secondary" checked={data.signStatus} onChange={(e) => handleNominationData(e, key)} disabled={data.nominationCompleted || data.blockUserUpdate} />
                                  : (
                                    data.nominationCompleted
                                  ? <div className="d-flex gap-1 align-items-center">
                                      <CheckCircleIcon fontSize="small" color="success" />
                                      <div className="d-flex flex-column flex-grow-1">
                                        <p className="fs-med-medium p-0 m-0">Signed</p>
                                        {data.signDatetime ?
                                          <p className="fs-small-regular p-0 m-0">Signed on {formatDateTime(data.signDatetime, false)}</p> : ''}
                                      </div>
                                    </div>
                                      : <p className="fs-med-medium p-0 m-0">
                                        <CancelIcon fontSize="small" color="error" /> Not Signed</p>
                                  )
                              
                          }
                        </Box>
                        <Box sx={{ flex: 1 }}>
                          <Stack sx={{ flexWrap: 'wrap' }} direction="row">
                            <input
                              className="d-none"
                              id={`input-file-${key}`}
                              type="file"
                              accept=".pdf"
                              onClick={(e) => {
                                e.target.value = null;
                              }}
                              onChange={(e) => {
                                uploadNominationDocument(e, key);
                              }}
                            />
                          </Stack>

                          {data.signStatus ?
                            <Stack direction="column" className={(!data?.isEdit && data?.nominationFiles?.length === 0) ? 'd-none' : ''}>
                              <p className="section-label mb-1" style={{ fontSize: '12px' }}>
                                {
                                  data.isEdit
                                    ? 'UPLOAD Nomination DOCUMENT ( optional )'
                                    : 'Nomination DOCUMENTS'
                                }
                              </p>
                              <Stack sx={{ alignItems: 'center' }} direction="row" spacing={2}>
                                {data.nominationFiles?.map((fileData, fileIndex) => {
                                  return (
                                    <Tooltip title={fileData.actualFileName} key={fileIndex}>
                                      {(
                                        data.isEdit
                                          ? <Badge
                                            badgeContent={<RemoveCircle fontSize="10" onClick={() =>deleteNominationDocument(key, fileIndex)} />}
                                            color="error">
                                            <AttachFileIcon color="secondary" onClick={() => window.open(`${BASE_URL}/${fileData.path}`, '_blank')} />
                                          </Badge>
                                          : <AttachFileIcon color="secondary" onClick={() => window.open(`${BASE_URL}/${fileData.path}`, '_blank')} />
                                      )}
                                    </Tooltip>
                                  );
                                })}
                                {data.isFileLoading ? <CircularProgress size={20} color="secondary" /> :
                                  (
                                    data.isEdit
                                      ? <IconButton color="secondary" onClick={() =>
                                        document.getElementById(`input-file-${key}`).click()}>
                                        <AddIcon />
                                      </IconButton>
                                      : ''
                                  )}
                              </Stack></Stack> : ''}
                        </Box>
                      </Stack>
                    </Stack>
                  </div>
                  {userType === 'ADMIN' &&
                    <div className="d-flex flex-column">
                      {
                        data.isEdit
                          ? <><IconButton color="success" onClick={() => handleSaveNomination(key)} disabled={data.isLoading || data.isFileLoading}>
                            <DoneIcon />
                          
                            </IconButton></>
                          : <IconButton color="primary" onClick={() => handleIsEdit(key)}>
                            <EditIcon />
                          </IconButton>
                      }

                      {
                        data.inRid
                          ? (
                            data.nominationCompleted ? '' :
                              <IconButton color="error" onClick={() => toggleNominationDelDialog(data.inRid)}>
                                <DeleteIcon />
                              </IconButton>
                          )
                          : <IconButton color="error" onClick={() => handleRemoveNomination(key)}>
                            <CloseIcon />
                          </IconButton>
                      }
                     
                    </div>
                  }
                </div>
              ))
            }
            {userType === 'ADMIN' &&
              <div className="d-flex justify-content-start">
                <Button variant="outlined" startIcon={<AddIcon />} onClick={handleAddNomination}>Add</Button>
              </div>
            }
          </div>)}
    </DrawerLayout>
  );
}

export default NominationDrawer;